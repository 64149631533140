import request from '@/utils/request'

//数值查询
export function getPipeInfoData() {
  return request({
    url: '/survey/monitor/pipe/getPipeData',
    method: 'get',
  })
}

//管网报警内容统计图
export function getAlarmStatics() {
  return request({
    url: '/survey/monitor/pipe/alarmStatics',
    method: 'get',
  })
}

//故障数据查询
export function getPipeFault() {
  return request({
    url: '/survey/monitor/pipe/getPipeFault',
    method: 'get',
  })
}

//离线数据查询
export function getPipeOff() {
  return request({
    url: '/survey/monitor/pipe/getPipeOff',
    method: 'get',
  })
}

//处理情况查询
export function getAlarmHandleStatics() {
  return request({
    url: '/survey/monitor/pipe/alarmHandleStatics',
    method: 'get',
  })
}

//查询故障总计，离线总计
export function getPipeAlarmTotal() {
  return request({
    url: '/survey/monitor/pipe/getPipeAlarmTotal',
    method: 'get',
  })
}

//获取实时报警数据表格
export function getPipeAlarmList(params) {
  return request({
    url: '/survey/monitor/pipe/alarmList',
    method: 'get',
    query: params
  })
}

//获取管网健康数据表格
export function getWpTownList(params) {
  return request({
    url: '/survey/monitor/pipe/wpTownList',
    method: 'get',
    query: params
  })
}

//获取标签云报警数据
export function getTagCloudPipeAlarm() {
  return request({
    url: '/survey/monitor/pipe/getPipeAlarmToTtal',
    method: 'get',
  })
}

//查询管网点位
export function wpTownPointList() {
  return request({
    url: "/survey/monitor/mapPoint/wpTownPointList",
    method: "get",
  });
}

//管网点位趋势统计
export function wpTownPointStatistic(monitorId) {
  return request({
    url: `/survey/monitor/mapPoint/wpTownPointStatistic/${monitorId}`,
    method: "get",
  });
}

//阀门状态
export function valveCode() {
  return request({
    url: `/survey/dict/monitor/valveCode`,
    method: "get",
  });
}



