import { mergeRecursive } from '@/utils/ruoyi'
import dictWaterConverter from './DictWaterConverter'

export const options = {
  metas: {
    '*': {
      /**
       * 字典请求，方法签名为function(dictMeta: DictMeta): Promise
       */
      request: (dictWaterMeta) => {
        console.log(`load dictWater ${dictWaterMeta.type}`)
        return Promise.resolve([])
      },
      /**
       * 字典响应数据转换器，方法签名为function(response: Object, dictMeta: DictMeta): DictData
       */
      responseConverter,
      labelField: 'label',
      valueField: 'value'
    }
  },
  /**
   * 默认标签字段
   */
  DEFAULT_LABEL_FIELDS: ['label', 'name', 'title'],
  /**
   * 默认值字段
   */
  DEFAULT_VALUE_FIELDS: ['value', 'id', 'uid', 'key']
}

/**
 * 映射字典
 * @param {Object} response 字典数据
 * @param {DictWaterMeta} dictWaterMeta 字典元数据
 * @returns {DictWaterData}
 */
function responseConverter(response, dictWaterMeta) {
  const dictWaters = response
  if (dictWaters === undefined) {
    console.warn(`no dict data of "${dictWaterMeta.type}" found in the response`)
    return []
  }
  return dictWaters.map(d => dictWaterConverter(d, dictWaterMeta))
}

export function mergeOptions(src) {
  mergeRecursive(options, src)
}

export default options
