<template>
  <div class="container">
    <div id="tag-cloud"></div>
  </div>
</template>

<script>
import {getTagCloudAlarmData} from '@/api/survey/monitor/homePage'

const TagCloud = require('TagCloud');

export default {
  created() {
    this.getTagCloudAlarmData()
  },
  data() {
    return {
      alarmData: []
    }
  },
  methods: {
    getTagCloudAlarmData() {
      getTagCloudAlarmData().then(res => {
        this.alarmData = res.rows.map(item => `${item['name']}：${item['num']}`)
      })
    },
    draw() {
      TagCloud('#tag-cloud', this.alarmData, {
        useContainerInlineStyles: false,
        containerClass: 'tag-cloud-container',
        itemClass: 'tag-cloud',
        maxSpeed: 'slow'
      });
    }
  },
  watch: {
    alarmData(e) {
      this.$nextTick(() => {
        this.alarmData = e
        this.draw()
      })
    }
  },
  mounted() {

  }

};
</script>

<style scoped lang="scss">
@import "~@/assets/survey/style.scss";

::v-deep .tag-cloud {
  @include metal-font-color;
}

::v-deep .tag-cloud-container {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 135px;
  padding-bottom: 25px;
  @include border-color2;

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
