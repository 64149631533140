var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'has-logo': _vm.showLogo, V5: _vm.isV5 },style:({
		background:
			_vm.settings.sideTheme === 'theme-dark'
				? _vm.variables.menuBackground
				: _vm.variables.menuLightBackground
	})},[(_vm.showLogo)?_c('logo',{attrs:{"collapse":_vm.isCollapse}}):_vm._e(),_c('el-scrollbar',{class:_vm.settings.sideTheme,attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('el-menu',{attrs:{"active-text-color":_vm.isV5 ? _vm.variables.menuColor : _vm.settings.theme,"background-color":_vm.settings.sideTheme === 'theme-dark'
					? _vm.isV5
						? _vm.variables.menuTransparentBackground
						: _vm.variables.menuBackground
					: _vm.variables.menuLightBackground,"collapse":_vm.isCollapse,"collapse-transition":false,"default-active":_vm.activeMenu,"text-color":_vm.settings.sideTheme === 'theme-dark'
					? _vm.variables.menuColor
					: _vm.variables.menuLightColor,"unique-opened":true,"mode":"vertical"}},_vm._l((_vm.sidebarRoutersNew),function(route,index){return _c('sidebar-item',{key:route.path + index,attrs:{"base-path":route.path,"item":route}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }