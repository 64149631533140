// 南安独居老人大屏项目
import request from '@/utils/request'

// 查询组织部门
export function deptList() {
  return request({
    url: '/survey/elderly/dept/list',
    method: 'get'
  });
}

// 查询用户列表
export function guestList({deptId}) {
  return request({
    url: '/survey/elderly/dept/',
    method: 'get',
    params: {deptId}
  })
}

// 查询用户数
export function guestNum() {
  return request({
    url: '/survey/elderly/dept/guestNum',
    method: 'get'
  })
}

// 查询今日低水量
export function lowUseList() {
  return request({
    url: '/survey/elderly/dept/lowUseList',
    method: 'get'
  });
}

// 查询异常处理统计 - 处理中
export function ingList() {
  return request({
    url: '/survey/elderly/dept/ingList',
    method: 'get'
  });
}

// 查询异常处理统计 - 已完成
export function doneList() {
  return request({
    url: '/survey/elderly/dept/doneList',
    method: 'get'
  });
}

// 查询异常处理统计 - 未处理
export function willList() {
  return request({
    url: '/survey/elderly/dept/willList',
    method: 'get'
  });
}

// 查询用水异常统计 - 低用水
export function lowUseNum() {
  return request({
    url: '/survey/elderly/dept/lowUseNum',
    method: 'get'
  });
}

// 查询今日上数
export function nbTakeList() {
  return request({
    url: '/survey/elderly/dept/nbTakeList',
    method: 'get'
  });
}
