<template>
  <div class="gwzt">
    <GwztLeft id="left"/>
    <div class="map">
      <Map
        :infoWindowImage="infoWindowImage"
        :infoWindowRect="{width: 420, height: 415}"
        :lines="lines"
        :markers="points"
        @clickMarker="clickMarker"
      >
        <template v-slot:infoWindow>
          <div class="info-window">
            <SubTitle text="良乡监测点" light></SubTitle>
            <div class="info-table">
              <table>
                <tr>
                  <td>
                    设备编号：2251021212
                  </td>
                  <td>
                    安装时间：{{ (infoWindowData.createTime || '').slice(0, 11) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    IMEI：{{ infoWindowData.imei }}
                  </td>
                  <td>
                    报警状态：{{ infoWindowData.alarmType }}
                  </td>
                </tr>
                <tr>
                  <td>
                    累计流量：{{ infoWindowData.waterFlow }}
                  </td>
                  <td>
                    阀门状态：{{ infoWindowData.valveStatus }}
                  </td>
                </tr>
                <tr>
                  <td>
                    电压：{{ infoWindowData.voltage }}
                  </td>
                  <td>
                    信号：{{ infoWindowData.csq }}
                  </td>
                </tr>
              </table>
              <LineChart2 style="margin: 20px" :data="lineData"/>
            </div>
          </div>
        </template>
      </Map>
    </div>
    <GwztRight id="right"/>
  </div>
</template>
<script>
import Map from "@/components/survey/BMap.vue";
import GwztLeft from './GwztLeft.vue'
import GwztRight from './GwztRight.vue'
import infoWindowImage from '@/assets/survey/image/page-image/gwzt/window.png'
import * as api from "@/api/survey/monitor/gwztPage.js";
import {alarmTypeDict} from '@/api/survey/monitor/xdsb.js'
import SubTitle from "@/components/survey/SubTitle.vue";
import LineChart2 from "@/components/survey/LineChart2.vue";
import {gwData, points} from './gw.js';

var pointsPos = points.flat().map(v => {
  return {
    location: v
  }
})
var lineData = [
  {
    field: 'pressure',
    name: '压力趋势',
    xAxis: [],
    data: []
  },
  {
    field: 'waterFlow',
    name: '流量趋势',
    xAxis: [],
    data: []
  },
];
export default {
  data() {
    return {
      infoWindowImage,
      lines: [],
      points: pointsPos,
      infoWindowData: {
        createTime: '2022.03.15',
        imei: ' 2251021212',
        alarmType: '正常',
        waterFlow: '12566',
        valveStatus: '正常',
        voltage: '3.6',
        csq: '22.5'
      },
      lineData,
      alarmTypeDict: {},
      valveCode: {}
    }
  },
  mounted() {
    // 报警状态
    alarmTypeDict().then(res => {
      var dict = {};
      res.data.forEach(item => {
        dict[item.dictValue] = item.dictLabel
      })
      this.alarmTypeDict = dict;
    });
    // 阀门状态
    api.valveCode().then(res => {
      var dict = {};
      res.data.forEach(item => {
        dict[item.dictValue] = item.dictLabel
      })
      this.valveCode = dict;
    })

    /*     api.wpTownPointList().then((res) => {
          res.rows.forEach(item => {
            item.location = item.locationJson != null ? item.locationJson.split('-') : []
          })
          this.lines = res.rows;
        }); */

    // 绘制管网
    this.lines = gwData.map(item => {
      let origin = [115.429900, 39.445644]; // 参照点
      let location = item.location.map(point => {
        let [lng, lat] = point.split(',')
        lng = Number(lng) + ((lng - origin[0]) * 2);
        lat = Number(lat) + ((lat - origin[1]) * 2);
        return [lng - 1.84, lat - 1.63].join()
      });

      return {
        location
      }
    })
  },
  components: {Map, GwztLeft, GwztRight, SubTitle, LineChart2},
  methods: {
    clickMarker(e) {
      // 获取折线图数据
      const demoId = 19;
      api.wpTownPointStatistic(demoId).then(res => {
        this.lineData.forEach((line, index) => {
          let xAxis = res.data.map(v => v.takeTime)
          let data = res.data.map(v => v[line.field])
          this.$set(this.lineData[index], 'xAxis', xAxis)
          this.$set(this.lineData[index], 'data', data)
        })
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/info_window.scss";

.map {
  z-index: 0;
  width: 100vw;
  height: 100vh;
}

#left {
  z-index: 11;
  position: fixed;
  left: 20px;
  top: 25px;
}

#right {
  z-index: 11;
  position: fixed;
  right: 20px;
  top: 0px;
}
</style>
