<template>
  <div class="app-container">
    <div class="app-container-right">
      <div class="search-box" v-show="showSearch">
        <el-form
          v-show="showSearch"
          ref="queryForm"
          :inline="true"
          :model="queryParams"
          :rules="rules"
          style="display: flex"
        >
          <el-form-item label="模板名称" prop="templateTitle" style="white-space: nowrap">
            <el-input
              v-model.trim="queryParams['templateTitle']"
              clearable
              placeholder="请输入模板名称"
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="模板状态" prop="templateStatus" style="margin-left: 20px; white-space: nowrap">
            <el-select
              v-model="queryParams.templateStatus"
              clearable
              placeholder="请选择模板状态"
              size="small"
            >
              <el-option
                v-for="dictWater in getContractsDictTempStatusList"
                :key="dictWater.key"
                :label="dictWater.value"
                :value="dictWater.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="模板类型" prop="templateType" style="margin-left: 20px; white-space: nowrap">
            <el-select
              v-model="queryParams.templateType"
              clearable
              placeholder="请选择模板类型"
              size="small"
            >
              <el-option
                v-for="dictWater in getContractsDictTypeList"
                :key="dictWater.key"
                :label="dictWater.value"
                :value="dictWater.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 20px;white-space: nowrap">
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="handleQuery"
            >搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-row class="custom-table-show-search normal-card">
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="openUpdateDialogFrom('')"
            >新增模板
            </el-button>
          </el-col>
          <el-col :span="1.5">

            <el-dropdown @command="dropdownCommand($event, templateIdsList[0])" :disabled="templateIdsList.length!== 1">
              <el-button
                :disabled="templateIdsList.length!== 1"
                icon="el-icon-edit"
                class="pay-btn green-type"
                size="mini"
                type="warning"
              >配置模板
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update">修改模板信息</el-dropdown-item>
                <el-dropdown-item command="add">添加模板参数</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </el-col>
          <el-col :span="1.5">
            <el-button
              :disabled="deleteDisabled"
              icon="el-icon-delete"
              class="pay-btn red-type"
              size="mini"
              type="danger"
              @click="handleDelete"
            >删除模板
            </el-button>
          </el-col>
          <right-toolbar ref="rightToolbar" :columnsList="columnsList" :showSearch.sync="showSearch"
                         @queryTable="getList"/>
        </el-row>
        <div ref="tableBox" class="table-container">
          <!-- 结果列表 -->
          <el-table
            class="blue-theme"
            ref="table"
            v-loading="loading"
            :data="contractsList"
            :max-height="tableMaxHeight"
            border
            row-key="templateId"
            stripe
            :default-sort="defaultSort"
            @sort-change="handleSortChange"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            >
            <el-table-column
              align="center"
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in columnsList"
              v-if="item.show"
              :key="`col_${index}`"
              :autoFit='true'
              :fitHeader="true"
              :fixed="item.fixed"
              :label="item.name"
              :min-width="item.minWidth"
              :prop="item.prop"
              :reserve-selection="item.reserveSelection"
              :resizable="item.resizable"
              :sort-orders="item.sortable ? ['descending', 'ascending']: null"
              :sortable="item.sortable ? 'custom': null"
              :type="item.type"
              :width="item.width"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="item.type === 'cooperation'" class="table-scope-operator">
                  <div class="link-container">
                    <el-dropdown @command="dropdownCommand($event, scope.row.templateId)">
                      <span class="el-dropdown-link"><i class="el-icon-edit el-icon--left" style="font-size:16px"></i>配置</span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="update">修改模板信息</el-dropdown-item>
                        <el-dropdown-item command="add">添加模板参数</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="link-container">
                    <el-link :underline="false" icon="el-icon-delete" type="danger"
                             @click="handleDelete(scope.row)">删除
                    </el-link>
                  </div>
                </div>
                <div v-else-if="['templateType', 'templateStatus'].includes(item.prop)">
                <span v-if="item.dictProp === 'getContractsDictTypeList'">{{
                    getDictLabel(item.dictProp, scope.row[item.prop])
                  }}</span>
                  <el-tag
                    v-else
                    :type="scope.row[item.prop] | dictColorFilter"
                  >
                    {{ getDictLabel(item.dictProp, scope.row[item.prop]) }}
                  </el-tag>
                </div>
                <div v-else>{{ scope.row[item.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination-box">
          <!-- 分页器 -->
          <pagination
            v-show="total > 0"
            :limit.sync="queryParams.pageSize"
            :page.sync="queryParams.pageNum"
            :total="total"
            @pagination="getList"
          />
        </div>
      </el-row>
    </div>

    <!--  新增或修改修改模板基础信息dialog  -->
    <div class="update-dialog">
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="updateDialogTitle"
        :visible.sync="updateVisible"
        width="600px"
      >
        <div class="update-content">
          <el-form ref="updateForm" :model="updateForm" :rules="updateRules" class="updateForm" label-width="110px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="模板类型：" prop="templateType">
                  <el-select
                    v-model="updateForm['templateType']"
                    clearable
                    placeholder="请选择模板类型"
                    size="small"
                  >
                    <el-option
                      v-for="dictWater in getContractsDictTypeListNotAll"
                      :key="dictWater.key"
                      :label="dictWater.value"
                      :value="dictWater.key"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="模板状态：" prop="templateStatus">
                  <el-select
                    v-model="updateForm['templateStatus']"
                    clearable
                    placeholder="请选择模板状态"
                    size="small"
                  >
                    <el-option
                      v-for="dictWater in getContractsDictTempStatusListNotAll"
                      :key="dictWater.key"
                      :label="dictWater.value"
                      :value="dictWater.key"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="模板序号：" prop="templateSort">
                  <el-input-number v-model="updateForm['templateSort']" :max="100" :min="1" :step="1" step-strictly/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="模板名称：" prop="templateTitle">
                  <el-input v-model.trim="updateForm['templateTitle']" clearable placeholder="请输入模板名称"
                            size="small"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="模板描述信息：" prop="templateDesc">
                  <el-input v-model.trim="updateForm['templateDesc']" :autosize="{minRows: 2, maxRows: 6}" clearable
                            maxlength="200"
                            placeholder="请输入模板描述信息" size="small" type="textarea"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="formCancel('update')">取 消</el-button>
          <el-button size="small" type="primary" @click="formSubmit('update')">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!--  编辑模板文件参数信息dialog  -->
    <div class="add-dialog">
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="addVisible"
        title="编辑模板文件参数信息"
        width="1800px"
      >
        <div class="update-content">
          <el-form ref="addForm" :model="addForm" :rules="addRules" class="addForm" inline>
            <div>
              <p style="font-size: 17px;font-weight: 600; padding: 0 0 10px">文字属性</p>
              <el-row v-for="(item, index) in addForm.templateParam" :key="index">
                <el-col :span="4">
                  <el-form-item :prop="'templateParam.' + index + '.propertyLabel'" :rules="{
            required: true, message: '属性label不能为空', trigger: 'blur'
        }" label="属性label：" label-width="auto">
                    <el-input v-model.trim="item.propertyLabel" placeholder="请输入属性名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item :prop="'templateParam.' + index + '.propertyValue'" :rules="{
            required: true, message: '属性name不能为空', trigger: 'blur'
        }" label="属性name：" label-width="auto">

                    <el-input v-model.trim="item.propertyValue" placeholder="请输入属性参数值"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item :prop="'templateParam.' + index + '.defaultValue'" :rules="{
            required: false, message: '属性value不能为空', trigger: 'blur'
        }" label="属性value：" label-width="auto">

                    <el-input v-model.trim="item.defaultValue" placeholder="请输入属性默认值"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item :prop="'templateParam.' + index + '.sort'" :rules="{
            required: true, message: '序号不能为空', trigger: 'blur'
        }" label="序号：" label-width="auto">
                    <el-input-number v-model="item.sort" :max="100" :min="1" :step="1" step-strictly
                                     style="width: 100%"/>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item :prop="'templateParam.' + index + '.propertyFormat'" label="属性类型">
                    <el-select
                      v-model="item.propertyFormat"
                      placeholder="请选择属性类型"
                      size="small"
                    >
                      <el-option
                        v-for="item in addParamsDictsList"
                        :key="item.key"
                        :label="item.value"
                        :value="item.key"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <div class="add-dialog-switch">
                    <el-form-item :prop="'templateParam.' + index + '.propertyType'">
                      <el-switch
                        v-model="item['propertyType']"
                        active-color="#13ce66"
                        active-text="显示"
                        active-value="2"
                        inactive-color="#ff4949"
                        inactive-text="隐藏"
                        inactive-value="1">
                      </el-switch>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="addForm-cooperation">
                    <div class="link-container">
                      <el-link :underline="false" icon="el-icon-plus" type="primary"
                               @click="addItem('templateParam')">新 增
                      </el-link>
                    </div>
                    <div v-if="index !== 0" class="link-container">
                      <el-link :underline="false" icon="el-icon-delete" type="danger"
                               @click="deleteItem(item, index, 'templateParam')">删 除
                      </el-link>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="formCancel('add')">取 消</el-button>
          <el-button size="small" type="primary" @click="formSubmit('add')">确 定</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {
  addParamsDicts,
} from '@/api/revenue/contract/contractController'
import {
  delSmsTemplate,
  getSmsTemplateList,
  getContractsDictTempStatus,
  getContractsDictType,
  addOrUpdateTemplateContractsInfo,
  getTemplateContractsInfoByTemplateId,
  updateTemplateContractsParams
} from '@/api/social/config/sms/msgTemplate'

export default {
  name: 'Template',

  watch: {
    showSearch(newVal, oldVal) {
      this.getDynamicHeight()
    },
  },

  filters: {
    dictColorFilter(val) {
      switch (val) {
        case '0':
          return 'primary'

        case '1':
          return 'danger'

        case '2':
          return 'success'

        case '3':
          return 'warning'
      }
    },
  },

  data() {
    return {
      tableMaxHeight: undefined,
      updateRules: {},
      addRules: {},
      updateDialogTitle: '',
      addVisible: false,
      updateVisible: false,
      columnsList: [
        {
          prop: 'templateTitle',
          name: '模板名称',
          label: '模板名称',
          resizable: true,
          fixed: false,
          show: true,
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'templateDesc',
          name: '模板描述信息',
          label: '模板描述信息',
          resizable: true,
          fixed: false,
          show: true,
          sortable: false,
          minWidth: 200
        },
        {
          prop: 'templateType',
          name: '模板类型',
          label: '模板类型',
          fixed: false,
          dictProp: 'getContractsDictTypeList',
          resizable: true,
          show: true,
          sortable: false,
          minWidth: 80
        },
        {
          prop: 'templateStatus',
          name: '模板状态',
          label: '模板状态',
          dictProp: 'getContractsDictTempStatusList',
          resizable: true,
          fixed: false,
          show: true,
          sortable: false,
          minWidth: 80
        },
        {
          prop: 'templateSort',
          name: '模板序号',
          label: '模板序号',
          fixed: false,
          resizable: true,
          show: true,
          sortable: true,
          minWidth: 80
        },
        {
          type: 'cooperation',
          name: '操作',
          label: '操作列',
          resizable: true,
          show: true,
          minWidth: 100,
          sortable: false,
          fixed: 'right',
        },
      ],
      // 显示搜索条件
      showSearch: true,
      rules: {},
      loading: true,
      // 总条数
      total: 0,
      // 合同表格数据
      contractsList: [],
      multipleSelection: [],
      // 默认排序
      defaultSort: {prop: 'templateSort', order: 'descending'},
      // 初始化查询参数
      queryParams: {
        templateTitle: '',
        templateStatus: '',
        templateType: '',
        pageSize: 10,
        pageNum: 1,
      },
      templateId: '',

      dictMethodsList: ['getContractsDictType', 'getContractsDictTempStatus'],
      getContractsDictTypeList: [],
      getContractsDictTempStatusList: [],
      /*不带全部的字典*/
      getContractsDictTypeListNotAll: [],
      getContractsDictTempStatusListNotAll: [],
      updateForm: {
        templateSort: 1,
        templateId: '',
        templateTitle: '',
        templateStatus: '0',
        templateType: '',
        templateDesc: ''
      },
      addForm: {
        templateId: '',
        templateParam: [
          {
            propertyLabel: '',
            propertyValue: '',
            defaultValue: '',
            sort: 1,
            propertyType: '1', //switch开关
            propertyFormat: '1',
          },
        ],

        templateExtend: {},
      },
      addParamsDictsList: [],
    }
  },

  computed: {
    templateIdsList() {
      return this.multipleSelection.map(v => v.templateId)
    },

    deleteDisabled() {
      return this.templateIdsList.length === 0
    },

  },

  async created() {
    this.getList()
    await this.getDicts()
  },

  methods: {
    getContractsDictType,
    getContractsDictTempStatus,
    getDynamicHeight() {
      this.tableMaxHeight = 1500
      this.$nextTick(() => {
        this.tableMaxHeight = this.$refs.tableBox.offsetHeight - 0.5
      })
    },

    /** 排序触发事件 */
    handleSortChange(column, prop, order) {
      this.queryParams.orderByColumn = column.prop
      this.queryParams.isAsc = column.order
      this.getList()
    },

    addParamsDicts() {
      addParamsDicts().then(res => {
        this.addParamsDictsList = res.data || {}
      }).catch(e => {
        this.$elMessage.error('获取模板参数字典失败！')
      })
    },

    addItem(prop) {
      this.addForm[prop].push(
        {
          label: '',
          value: '',
          sort: 1,
          propertyType: '1',
          propertyFormat: '1',
        },
      )
    },

    deleteItem(item, index, prop) {
      this.addForm[prop].splice(index, 1)
    },

    formCancel(prop) {
      this[`${prop}Visible`] = false
      this.$refs[`${prop}Form`][`resetFields`]()
    },

    formSubmit(prop) {
      switch (prop) {
        case 'update':
          this.addOrUpdateTemplateContractsInfo()
          break

        case 'add':
          this.updateTemplateContractsParams()
          break
      }
    },

    /*打开新增或修改模板信息弹窗*/
    openUpdateDialogFrom(templateId = '') {
      this.updateVisible = true
      this.updateDialogTitle = templateId ? '修改模板基础信息' : '新增模板基础信息'

      if (templateId) {
        this.getTemplateContractsInfoByTemplateId(templateId)
      }
    },

    /*新增或修改模板信息*/
    addOrUpdateTemplateContractsInfo() {
      this.$refs['updateForm'].validate(valid => {
        if (valid) {
          addOrUpdateTemplateContractsInfo(this.updateForm).then(() => {
            this.formCancel('update')
            this.$elMessage.success(`${this.updateForm.templateId ? '修改成功！' : '新增成功！'}`)
            this.getList()
          })
        }
      })
    },

    /*添加模板参数参数处理*/
    addTemplateParams(templateId) {
      this.addForm.templateId = templateId

      /*获取模板参数字典*/
      this.addParamsDicts()
      this.addForm.templateParam = [
        {
          propertyLabel: '',
          propertyValue: '',
          defaultValue: '',
          sort: 1,
          propertyType: '1',  //switch开关 ‘1’关， ‘2’开
          propertyFormat: '1',
        },
      ]

      this.addForm.templateExtend = {}

      getTemplateContractsInfoByTemplateId(templateId).then(res => {

        this.addForm.templateParam = res.data.templateParam && res.data.templateParam.length !== 0
          ? res.data.templateParam
          : this.addForm.templateParam

        this.addForm.templateExtend = res.data.templateExtend && res.data.templateExtend.length !== 0
          ? res.data.templateExtend
          : this.addForm.templateExtend

        this.addVisible = true
      }).catch(e => {
        console.log(e)
        this.$elMessage.error('获取模板基础信息失败！')
      })
    },

    /*提交编辑模板参数接口*/
    updateTemplateContractsParams() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          updateTemplateContractsParams(this.addForm).then(() => {
            this.formCancel('add')
            this.$elMessage.success(`编辑模板参数成功！`)
            this.getList()
          })
        }
      })
    },

    /*根据模板id获取模板基础信息*/
    getTemplateContractsInfoByTemplateId(templateId = '') {
      getTemplateContractsInfoByTemplateId(templateId).then(res => {
        this.updateForm = {...this.updateForm, ...res.data || {}}
      }).catch(e => {
        console.log(e)
        this.$elMessage.error('获取模板基础信息失败！')
      })
    },

    /*根据templateId批量删除模板*/
    handleDelete(row) {
      let ids = row.templateId || this.templateIdsList

      this.$confirm('是否确定删除所选的模板数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',

        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            delSmsTemplate(ids).then(res => {
              instance.confirmButtonLoading = false
            }).catch(e => {
              console.log(e)
              this.$elMessage.error('删除失败，请联系管理员！')
            }).finally(() => {
              done()
            })
          } else {
            done()
          }
        }

      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })

        setTimeout(() => {
          this.getList()
        }, 300)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    /*下拉菜单*/
    dropdownCommand(command, templateId) {
      this.templateId = templateId

      switch (command) {
        case 'update':
          this.openUpdateDialogFrom(templateId)
          break

        case 'add':
          this.addTemplateParams(templateId)
          break
      }
    },

    getDictLabel(dictProp, key) {
      return this[dictProp].find(item => item.key === key)?.value || '未知'
    },

    getDicts() {
      this.dictMethodsList.forEach(item => {
        this[`${item}List`] = []
        this[`${item}ListNotAll`] = []

        this[item](true).then(res => {
          this[`${item}List`] = res.data || []
        }).catch(err => {
          console.log(err)
        })

        this[item](false).then(res => {
          this[`${item}ListNotAll`] = res.data || []
        }).catch(err => {
          console.log(err)
        })
      })
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.$refs['queryForm'].validate((valid) => {
        if (valid) {
          this.getList()
        }
      })
    },

    /** 重置按钮操作 */
    resetQuery() {
      this.reset('queryForm')
      this.getList()
    },

    //表单重置
    reset(name) {
      this.resetForm(name)
    },

    /** 查询合同模板列表 */
    getList() {
      this.loading = true
      getSmsTemplateList(this.queryParams).then(response => {
        this.contractsList = response['rows']
        this.total = response['total']
        this.getDynamicHeight()
      }).finally(() => {
        this.loading = false
      })
    },
  },

  mounted() {
    window.addEventListener('resize', this.getDynamicHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getDynamicHeight)
  },

}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding: 30px 60px;
}

::v-deep .el-dialog__footer {
  padding: 0 60px 30px;
}

::v-deep .el-table .cell {
  white-space: nowrap;
  width: fit-content;
  align-items: center;
  margin: auto;
}

::v-deep .el-table .el-table__cell {
  text-overflow: ellipsis;
  overflow: hidden;

  &:first-child .cell {
    padding: 0;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 14px;
  }
}

.addForm-cooperation {
  display: flex;
  height: 36px;
  align-items: center;

  .link-container {
    &:first-child {
      margin-right: 25px;
    }
  }

}

.description-slot-main {
  display: flex;
  justify-content: space-between;

  .more-button {
    width: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1890ff;
    cursor: pointer;
    white-space: nowrap;
  }
}

.noMore {
  white-space: break-spaces;
  max-height: 400px;
  overflow: auto !important;
}

.add-dialog {

  ::v-deep .el-form-item {
    display: flex;
  }

  &-switch {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .el-switch {

      .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
      }

      /*打开时文字位置设置*/
      .el-switch__label--right {
        z-index: 1;
      }

      /* 调整打开时文字的显示位子 */
      .el-switch__label--right span {
        margin-left: 8px;
      }

      /*关闭时文字位置设置*/
      .el-switch__label--left {
        z-index: 1;
      }

      /* 调整关闭时文字的显示位子 */
      .el-switch__label--left span {
        margin-left: 23px;
      }

      /*显示文字*/
      .el-switch__label.is-active {
        display: flex;
        height: 100%;
        align-items: center;
      }

      /* 调整按钮的宽度 */
      .el-switch__core,
      .el-switch__label {
        width: 55px !important;
        margin: 0;

        > span {
          font-size: 12px !important;
        }
      }

      /* 调整圆环的高度 */
      .el-switch__core:after {
        top: 1.5px !important;
      }
    }
  }
}

.table-scope-operator {
  display: flex;
  justify-content: center;
  align-items: center;

  .link-container {
    padding: 0 5px;
  }
}


</style>
