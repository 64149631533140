import request from '@/utils/request'
import {globalBaseUrl} from '@/settings'

// 获取通知列表
export function getNoticeMessagesList(query) {
  return request({
    url: `/system/remind/list`,
    method: 'get',
    params: query
  })
}

//获取通知类型按钮列表
export function getNoticeMessageTypes(query) {
  query.listType = 0

  return request({
    url: `system/remind/type`,
    method: 'get',
    params: query
  })
}

// 获取通知消息详细信息
export function getNoticeMessageDetails(id) {
  return request({
    url: `/system/remind/${id}`,
    method: 'get'
  })
}

// 状态 未读 —> 已读 变更 (可多选)
export function changeNoticeStatus(ids,deptId,findInSet) {
  return request({
    url: `/system/remind/read?ids=${ids}&deptId=${deptId}&findInSet=${findInSet}`,
    method: 'post'
  })
}

// 删除
export function noticeMessagesDelete(flag = true, ids, readStatus,deptId,findInSet) {
 const baseUrl = `/system/remind/delete?readStatus=${readStatus}&deptId=${deptId}&findInSet=${findInSet}`
  const confirmUrl = flag ? `${baseUrl}&ids=${ids}` : baseUrl
  return request({
    url: confirmUrl,
    method: 'delete'
  })
}

// 是否有新消息
export function isHaveNewNoticeMessages() {
  return request({
    url: `/${globalBaseUrl.SYSTEM}/remind/unread/count`,
    method: 'get'
  })
}

/*获取部门树未读消息*/
export function noticeTreeSelectMessages() {
  return request({
    url: `/${globalBaseUrl.NOTICE}/notice/noticeTreeSelect`,
    method: 'get'
  })
}

/*根据部门获取未读已读以及全部通知数量*/
export function getMessagesByDeptId(query) {
  return request({
    url: `/system/remind/count`,
    method: 'get',
    params: query
  })
}
