import request from '@/utils/request'

// 总供水量趋势分析
export function getSupplyStatics(params) {
  return request({
    url: '/survey/monitor/supply/getSupplyStatics',
    params,
    method: 'get',
  })
}

// 总售水量趋势分析
export function getSellStatics(params) {
  return request({
    url: '/survey/monitor/supply/getSellStatics',
    params,
    method: 'get',
  })
}

// 供水类型统计图
export function supplyTypeStatics(params) {
  return request({
    url: '/survey/monitor/supply/supplyTypeStatics',
    params,
    method: 'get',
  })
}

// 水源类型统计图
export function sourceTypeStatics(params) {
  return request({
    url: '/survey/monitor/supply/sourceTypeStatics',
    params,
    method: 'get',
  })
}
