<template>
  <div class="chart" ref="chart"></div>
</template>
<script>
export default {
  mounted() {
    this.initChart()
  },

  methods: {
    initChart() {
      let dom = this.$refs.chart;
      let myChart = this.$echarts.init(dom);
      let option = {
        series: [
          {
            type: "gauge",
            radius: '90%',
            progress: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: "#CE0000"},
                  {offset: 1, color: "#FF4C4C"},
                ]),
              },
              roundCap: true,
              show: true,
              width: 10,
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 10,
                color: [[1, "#204781"]],
                opacity: 0.5,
              },
            },
            pointer: {
              width: 12,
              length: "80%",
              icon: "triangle",
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 1, color: "#565551"},
                  {offset: 0, color: "#101731"},
                ]),
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                width: 2,
                shadowColor: "#fff",
                shadowBlur: 10,
              },
              distance: -15,
              length: 1,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 25,
              color: "#999",
              fontSize: 20,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 10,
              itemStyle: {
                // borderWidth: 10
                color: "#9F9F9F",
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 65,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 140px;
  height: 130px;
}
</style>
