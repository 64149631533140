<template>
  <div class="gwzt-right">
    <SubTitle text="管网指标" style="padding: 10px 0;" light/>
    <div class="container">
      <div class="circle">
        <div class="top">
          <p>{{ this.pipeInfoData.data7 || 0 }}</p>
          <p>修漏及时率</p>
        </div>
        <div class="right">
          <p>{{ this.pipeInfoData.data9 || 0 }}</p>
          <p>管网漏损率</p>
        </div>
        <div class="bottom">
          <p>{{ this.pipeInfoData.data10 || 0 }}</p>
          <p>压力合格率</p>
        </div>
        <div class="left">
          <p>{{ this.pipeInfoData.data8 || 0 }}</p>
          <p>水质达标率</p>
        </div>
      </div>
    </div>
    <TableData style="height: 360px; margin-top: 24px;" title="管网健康管理" search>
      <template v-slot:table>
        <el-table
          :data="pipeHealthList"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column prop="townName" label="行政区" width="70"></el-table-column>
          <el-table-column prop="pressureAverage" label="平均压力" width="60"></el-table-column>
          <el-table-column prop="pressureMax" label="最大压力" width="60"></el-table-column>
          <el-table-column prop="pressureMin" label="最小压力" width="70"></el-table-column>
          <el-table-column prop="burstNum" label="爆管" width="40"></el-table-column>
          <el-table-column prop="leakageRate" label="漏损率(%)" width="70"></el-table-column>
        </el-table>
      </template>
    </TableData>
    <TableData style="height: 360px; margin-top: 24px;" title="实时报警数据" search>
      <template v-slot:table>
        <el-table
          ref="autoScrollTable"
          :data="pipeAlarmList"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column prop="devNo" label="设备编号" width="60"></el-table-column>
          <el-table-column prop="warnType" label="报警内容" width="60" :formatter="warnTypeDict"></el-table-column>
          <el-table-column prop="alarmType" label="类别" width="60" :formatter="alarmTypeDict"></el-table-column>
          <el-table-column prop="importType" label="级别" width="60" :formatter="importTypeDict"></el-table-column>
          <el-table-column prop="alarmTime" label="报警时间" width="60" :formatter="alarmTimeFormat"></el-table-column>
          <el-table-column prop="handleStatus" label="处理状态" width="60"
                           :formatter="statusTypeDict"></el-table-column>
        </el-table>
      </template>
    </TableData>
  </div>
</template>
<script>
import AlarmTotal from '@/components/survey/AlarmTotal.vue';
import TableData from '@/components/survey/TableData.vue';
import SubTitle from '@/components/survey/SubTitle.vue';
import {getPipeInfoData, getPipeAlarmList, getWpTownList} from '@/api/survey/monitor/gwztPage'
import {getImportTypeDict, getWarnTypeDict, getAlarmTypeDict, getHandleStatusDict} from '@/api/survey/monitor/homePage'
import autoScrollTable from '@/assets/survey/table_scroll.mixin.js'

export default {
  mixins: [autoScrollTable],
  components: {AlarmTotal, TableData, SubTitle},
  data() {
    return {
      //数值信息
      pipeInfoData: {},
      pipeHealthList: [],
      pipeAlarmList: [],
      importTypeOptions: [],
      warnTypeOptions: [],
      alarmTypeOptions: [],
      statusTypeOptions: []
    }
  },
  methods: {
    /*获取数值信息*/
    getPipeInfoData() {
      getPipeInfoData().then(res => {
        this.pipeInfoData = res.data
      })
    },
    /*获取管网健康数据*/
    getPipeHealthList() {
      getWpTownList().then(res => {
        this.pipeHealthList = res.rows;
      })
    },
    /*获取实时报警数据*/
    getRealDataList() {
      getPipeAlarmList().then(res => {
        this.pipeAlarmList = res.rows;
      })
    },
    /*转换时间显示分和秒*/
    alarmTimeFormat(row) {
      return row.alarmTime.substring(11, 16)
    },
    getDict() {
      getWarnTypeDict().then(res => {
        this.warnTypeOptions = res.data
      })
      getImportTypeDict().then(res => {
        this.importTypeOptions = res.data
      })
      getAlarmTypeDict().then(res => {
        this.alarmTypeOptions = res.data
      })
      getHandleStatusDict().then(res => {
        this.statusTypeOptions = res.data
      })

    },
    /*报警级别字典翻译*/
    importTypeDict(row) {
      let value = "";
      this.importTypeOptions.forEach(item => {
        if (row.importType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*报警内容字典翻译*/
    warnTypeDict(row) {
      let value = "";
      this.warnTypeOptions.forEach(item => {
        if (row.warnType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*报警类型字典翻译*/
    alarmTypeDict(row) {
      let value = "";
      this.alarmTypeOptions.forEach(item => {
        if (row.alarmType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*处理状态字典翻译*/
    statusTypeDict(row) {
      let value = "";
      this.statusTypeOptions.forEach(item => {
        if (row.handleStatus == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    }
  },
  created() {
    this.getPipeInfoData()
    this.getPipeHealthList()
    this.getRealDataList()
    this.getDict()
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.gwzt-right {
  z-index: 11;
  backdrop-filter: blur(5px);
  background-color: #000c35a6;
  padding: 12px 25px 35px;
  width: 450px;
  margin-top: 108px !important;
  position: fixed;
  @include side-bar-top-border-small;
  @include side-bar-bottom-border-small;
}

// 优设字体
@mixin ys-text() {
  font-size: 20px;
  font-family: YouSheBiaoTiHei;
}

$blue-font-color: linear-gradient(#0083e4, #5ba6ff);

// 优设蓝色渐变字体
@mixin blue-font {
  @include transform-color($blue-font-color);
  @include ys-text;
}

.container {
  justify-content: space-between;
  @include border-color2;
  padding-bottom: 25px;

  > div {
    .icon {
      margin-right: 10px;
    }

    > span {
      color: #fff;
      font-size: 18px;

      &.well-count {
        @include blue-font;
      }
    }
  }
}

.circle {
  position: relative;
  margin: 0 auto;
  width: 288px;
  height: 117px;
  background-image: url(~@/assets/survey/image/well-rate.svg);
}

.circle {
  margin: 20px auto 10px;

  > div /* :not(:last-child)  */
  {
    text-align: center;
    position: absolute;

    p {
      color: #fff;
      margin: 0;
    }

    p:last-child {
      font-size: 14px;
      color: #FFFFFF;
    }

    p:first-child {
      font-family: YouSheBiaoTiHei;
      font-size: 20px;
      color: #efae6a;
    }

    &.top {
      top: -14px;
      left: 41%;
    }

    &.right {
      right: -29px;
      top: 19%;
    }

    &.bottom {
      bottom: -20px;
      left: 41%;

      p:first-child {
        font-size: 50px;
      }
    }

    &.left {
      left: -27px;
      top: 17%;
    }
  }
}
</style>
