<template>
  <div class="left">
    <div class="dev-info">
      <SubTitle text="消毒设备"></SubTitle>
      <AlarmTotal
        icon="warn"
        title="消毒设备总量:"
        :total="disinfectionTotal"
      />
      <Percent style="margin-top: 22px" :data="devStatus"/>
      <HalfPieChart :data="chartData" title="报警总量" count="10"/>
      <div class="fault-count flex align-end">
        <div class="">
          <p class="bold">
            故障<span>{{ faultTotal }}</span>
          </p>
          <p v-for="(item, index) in faultStatics.slice(0, 3)" :key="index">
            {{ item.name }}：{{ item.value }}
          </p>
        </div>
        <FaultPercentChart/>
        <div>
          <p v-for="(item, index) in faultStatics.slice(3)" :key="index">
            {{ item.name }}：{{ item.value }}
          </p>
        </div>
      </div>
    </div>
    <div class="point-info info-window">
      <SubTitle text="良乡监测点" light></SubTitle>
      <div class="info-table">
        <table>
          <tr>
            <td>
              设备编号：
              <p>2251021212</p>
            </td>
            <td>
              安装时间：
              <p>{{ infoWindowData.createTime }}</p>
            </td>
          </tr>
          <tr>
            <td>
              设备名称：
              <p>{{ infoWindowData.name }}</p>
            </td>
            <td>
              设备状态：
              <p>{{ disinfectStatusDict[infoWindowData.securityStatus] }}</p>
            </td>
          </tr>
        </table>
        <LineChart2 style="margin: 20px" :data="lineData"/>
      </div>
    </div>
  </div>
</template>
<script>
import SubTitle from "@/components/survey/SubTitle.vue";
import AlarmTotal from "@/components/survey/AlarmTotal.vue";
import Percent from "@/components/survey/Percent.vue";
import HalfPieChart from "@/components/survey/HalfPieChart.vue";
import FaultPercentChart from "@/components/survey/FaultPercentChart.vue";
import LineChart2 from "@/components/survey/LineChart2.vue";
import * as api from "@/api/survey/monitor/xdsb.js";

export default {
  props: {
    infoWindowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    infoWindowData(val) {
      api.disinfectionPointStatistic(val.id).then(res => {
        this.lineData.forEach((line, index) => {
          let xAxis = res.data.map(v => v.takeTime)
          let data = res.data.map(v => v[line.field])
          this.$set(this.lineData[index], 'xAxis', xAxis)
          this.$set(this.lineData[index], 'data', data)
        })
      })
    }
  },
  components: {
    SubTitle,
    AlarmTotal,
    Percent,
    HalfPieChart,
    FaultPercentChart,
    LineChart2,
  },
  data() {
    return {
      lineData: [
        {
          field: 'chlorineSurplus',
          name: '余氯',
          xAxis: [],
          data: []
        },
        {
          field: 'hydrogen',
          name: '氢浓度',
          xAxis: [],
          data: []
        }
      ],
      disinfectionTotal: 0,
      faultTotal: 0,
      disinfectStatusDict: {},
      faultStatics: [
        {name: "次氯酸钠", value: "0"},
        {name: "拍氢风扇", value: "0"},
        {name: "投加泵", value: "0"},
        {name: "进水泵", value: "0"},
        {name: "进水阀门", value: "0"},
        {name: "出水阀门", value: "0"},
      ],
      chartData: [
        {name: "水位", value: 2, color: "green"},
        {name: "氢气浓度", value: 4, color: "orange"},
        {name: "余氯", value: 3, color: "yellow"},
      ],
      devStatus: [
        {label: "正常运行", value: 30, status: "normal"},
        {label: "调试", value: 10, status: "normal"},
        {label: "故障", value: 1, status: "normal"},
        {label: "待处理", value: 1, status: "danger"},
        {label: "完成", value: 0, status: "info"},
      ],
    };
  },

  methods: {
    getLineData() {

    }
  },

  mounted() {
    api.disinfectStatusDict().then((res) => {
      res.data.forEach((item) => {
        this.disinfectStatusDict[item.dictValue] = item.dictLabel;
      });
    });

    api.getAlarmTotal().then((res) => {
      this.disinfectionTotal = res.data.disinfectionTotal;
      this.faultTotal = res.data.faultTotal;
    });

    api.disinfectCount().then((res) => {
      this.devStatus = res.data
        .filter((item) => item.name != "正在处理")
        .map((item) => {
          item.label = item.name;
          item.status = "normal";
          return item;
        });
    });

    api.alarmStatics().then((res) => {
      let data = [];
      res.data.forEach((item) => {
        data[item.name] = item.value;
      });
      this.chartData = this.chartData.map((item, idx) => {
        item.value = data[item.name];
        return item;
      });
    });

    api.faultStatics().then((res) => {
      this.faultStatics = res.data;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";
@import "~@/assets/survey/info_window.scss";

.info-window {
  margin: 20px 0;
}

.left {
  width: 400px;
  position: fixed;
  top: 9vh;
  left: 30px;
  backdrop-filter: blur(10px);
  z-index: 5;
  background-color: #000c35a6;
}

.dev-info {
  // display: none;
  padding: 21px 24px;
  @include side-bar-top-border-small;
  @include side-bar-bottom-border-small;
}

.fault-count {
  justify-content: space-around;

  div {
    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }

  p {
    color: #fff;
    margin: 14px 0 0;
    font-size: 14px;
    line-height: 1.2;

    &.bold {
      font-size: 18px;
      display: flex;
      align-items: center;
      line-height: 1;

      span {
        margin-left: 10px;
        font-size: 36px;
        color: #e29805;
        font-family: YouSheBiaoTiHei;
      }
    }
  }
}
</style>
