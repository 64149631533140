<template>
  <div>
    <div class="map">
      <Map/>
    </div>
    <SubTitle class="title" text="供水趋势分析"></SubTitle>
    <div class="chart-list flex" :style="style">
      <!--      <div class="query">-->
      <!--        <el-date-picker-->
      <!--          v-model="dateRange"-->
      <!--          type="daterange"-->
      <!--          range-separator="-"-->
      <!--          start-placeholder="开始日期"-->
      <!--          end-placeholder="结束日期"-->
      <!--          popper-class="cus-panel"-->
      <!--        >-->
      <!--        </el-date-picker>-->
      <!--        <el-button type="primary" icon="el-icon-search">搜索</el-button>-->
      <!--        <el-button type="" icon="el-icon-refresh-right">重置</el-button>-->
      <!--      </div>-->
      <div class="chart-wrapper flex-grow flex">
        <div class="chart0 flex flex-col">
          <p>总供水量趋势分析</p>
          <div class="flex-grow" ref="chart0"></div>
        </div>
        <div class="chart1 flex flex-col">
          <p>总售水量趋势分析</p>
          <div class="flex-grow" ref="chart1"></div>
        </div>
        <div class="chart2 flex flex-col">
          <p>分类水量趋势分析</p>
          <div class="flex-grow" ref="chart2"></div>
        </div>
        <div class="chart3 flex flex-col">
          <p>水源供水趋势分析</p>
          <div class="flex-grow" ref="chart3"></div>
        </div>
        <div class="chart3 flex flex-col">
          <p>用水分类占比</p>
          <div class="flex-grow" ref="chart4"></div>
        </div>
        <div class="chart3 flex flex-col">
          <p>水源供水分类占比</p>
          <div class="flex-grow" ref="chart5"></div>
        </div>
      </div>
      <div class="pages">
        <div v-for="(item , index) in ['start', 'end']" :key="index" @click="currPage = item"
             :class="{active: item === currPage}"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/api/survey/monitor/gsqs.js";
import SubTitle from "@/components/survey/SubTitle.vue";
import Map from "@/components/survey/BMap.vue";
import {getSupplyTypeDict, getSourceTypeDict} from '@/api/survey/monitor/homePage.js'

export default {
  components: {
    Map,
    SubTitle,
  },
  data() {
    return {
      currPage: 'start',
      dateRange: [],
      supplyTypeDict: {},
      sourceTypeDict: {},
    };
  },

  computed: {
    style() {
      return {
        'justify-content': `flex-${this.currPage}`
      }
    }
  },

  mounted() {
    let dataMod = [];
    for (let i = 0; i < 12; i++) {
      dataMod.push(null);
    }
    // 图1
    Promise.all([
      api.getSupplyStatics({supplyYear: 2022, staticsType: "month"}),
      api.getSupplyStatics({supplyYear: 2023, staticsType: "month"}),
    ]).then((results) => {
      let years = [dataMod.slice(), dataMod.slice()];
      results.forEach((year, idx) => {
        year.data.forEach((monthData) => {
          let month = Number(monthData.name.slice(5)) - 1;
          years[idx][month] = monthData.value;
        });
      });
      this.initAreaLine(this.$refs.chart0, years[0], years[1]);
    });

    // 图2
    Promise.all([
      api.getSellStatics({sellYear: 2022, beginMonth: 1, endMonth: 12}),
      api.getSellStatics({sellYear: 2023, beginMonth: 1, endMonth: 12}),
    ]).then((results) => {
      let years = [dataMod.slice(), dataMod.slice()];
      results.forEach((year, idx) => {
        year.data.forEach((monthData) => {
          let month = Number(monthData.name.slice(5)) - 1;
          years[idx][month] = monthData.value;
        });
      });
      this.initAreaLine(this.$refs.chart1, years[0], years[1]);
    });

    // 图3
    api
      .supplyTypeStatics({supplyYear: 2022, beginMonth: 1, endMonth: 12})
      .then((res) => {
        var datas = {};
        for (const key in res.data) {
          let type = res.data[key];
          let list = dataMod.slice();
          type.forEach((monthData) => {
            let month = Number(monthData.name.slice(5)) - 1;
            list[month] = monthData.value;
          });
          datas[key] = list;
        }
        let xData = [
          {type: "line", name: "生活", data: datas.domesticWater},
          {type: "line", name: "灌溉", data: datas.irrigationWater},
          {type: "line", name: "工业", data: datas.industrialWater},
          {type: "line", name: "其他", data: datas.otherWater},
        ];
        this.initLine(this.$refs.chart2, xData);
      });

    // 图4
    api
      .sourceTypeStatics({supplyYear: 2022, beginMonth: 1, endMonth: 12})
      .then((res) => {
        var datas = {};
        for (const key in res.data) {
          let type = res.data[key];
          let list = dataMod.slice();
          type.forEach((monthData) => {
            let month = Number(monthData.name.slice(5)) - 1;
            list[month] = monthData.value;
          });
          datas[key] = list;
        }
        let xData = [
          {type: "line", name: "水源井", data: datas.well},
          {type: "line", name: "水库", data: datas.reservoir},
          {type: "line", name: "外调水", data: datas.external},
        ];
        this.initLine(this.$refs.chart3, xData);
      });

    // 字典
    this.getDict().then(res => {
      // 图5
      api.getSupplyStatics({staticsType: "supplyType"}).then(res => {
        let data = res.data.map(item => {
          item.name = this.supplyTypeDict[item.name]
          return item;
        });
        this.initPie(this.$refs.chart4, data)
      })

      // 图6
      api.getSupplyStatics({staticsType: "sourceType"}).then(res => {
        let data = res.data.map(item => {
          item.name = this.supplyTypeDict[item.name]
          return item;
        });
        this.initPie(this.$refs.chart5, data)
      })
    })
  },

  methods: {
    getDict() {
      let p0 = getSupplyTypeDict().then(res => {
        res.data.forEach(item => {
          this.$set(this.supplyTypeDict, item.dictValue, item.dictLabel)
        })
      })

      let p1 = getSourceTypeDict().then(res => {
        res.data.forEach(item => {
          this.$set(this.sourceTypeDict, item.dictValue, item.dictLabel)
        })
      })

      return Promise.all([p0, p1])
    },

    initAreaLine(dom, lastYear, thisYear, controlWater) {
      let myChart = this.$echarts.init(dom);
      let option = {
        color: ["#147AD6", "#EC6666", "#C4B97A"],
        title: {
          // text: "Gradient Stacked Area Chart",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          icon: "circle",
          bottom: 0,
          padding: 0,
          textStyle: {
            color: "#7C828A",
          },
          borderWidth: 0,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            axisLabel: {
              color: "rgba(124, 130, 138, 1)",
              // interval: 5,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            type: "category",
            boundaryGap: false,
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 2,
            axisLabel: {
              color: "rgba(124, 130, 138, 1)",
              // interval: 5,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "去年",
            type: "line",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(20, 122, 214, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(20, 122, 214, 0.1)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: lastYear,
          },
          {
            name: "今年",
            type: "line",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(236, 102, 102, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(236, 102, 102, 0.1)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: thisYear,
          },
        ],
      };
      myChart.setOption(option);
    },

    initLine(dom, xData) {
      let myChart = this.$echarts.init(dom);
      let option = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
          type: "category",
          axisLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#316696",
            },
          },
          axisLabel: {
            color: "rgba(255, 255, 255, 0.35)",
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          max: "dataMax",
          splitNumber: 2,
          type: "value",
          splitLine: {
            // show: false
            lineStyle: {
              color: "rgba(255, 255, 255, 0.15)",
            },
          },
          axisLabel: {
            showMaxLabel: true,
            color: "rgba(255, 255, 255, 0.35)",
          },
        },
        series: xData,

        legend: {
          icon: "circle",
          bottom: 0,
          padding: 0,
          textStyle: {
            color: "#7C828A",
          },
          borderWidth: 0,
        },
      };
      myChart.setOption(option);
    },

    initPie(dom, data) {
      let myChart = this.$echarts.init(dom);
      let option = {
        color: ['#ffc000', '#70ad47', '#5b9bd5', '#147ad6'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "circle",
          bottom: 0,
          padding: 0,
          textStyle: {
            color: "#7C828A",
          },
          borderWidth: 0,
        },
        series: [
          {
            name: '分类占比',
            type: 'pie',
            radius: '50%',
            data: data,
            label: {
              color: '#7C828A'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.title {
  z-index: 6;
  width: 90vw;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.map {
  width: 100vw;
  height: 100vh;
}

.chart-list {
  @include side-bar-left-border-small;
  @include side-bar-right-border-small;
  z-index: 6;
  // flex-direction: column;
  justify-content: flex-start;
  width: 1642px;
  // height: 300px;
  background-color: #000c35;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  overflow: hidden;
  transition: justify-content .3s;

  .query {
    width: 100%;
    padding: 20px;

    ::v-deep .el-date-editor {
      margin-right: 20px;
      background-color: rgba(0, 0, 0, 0);
      border-color: #3f4e90;

      i {
        color: #889fce;
      }

      span {
        color: #b8b8b8;
      }

      input {
        color: #b8b8b8;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    ::v-deep .el-button {
      background-color: rgba(0, 0, 0, 0);
      color: #b8b8b8;
      border-color: #3f4e90;

      i {
        color: #7096d3;
      }

      &.el-button--primary {
        i {
          color: #fff;
        }

        margin-right: 8px;
        background-color: #37499b;
        border-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

.chart-wrapper {
  // width: 100%;
  padding: 50px 0 70px 0;
  justify-content: space-around;

  > div {
    width: 340px;
    height: 300px;
    // border: 1px solid #fff;
    margin: 0 35px;

    p {
      text-align: center;
      font-size: 18px;
      @include ys-title;
    }

    div {
      width: 100%;
    }
  }
}

.pages {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    cursor: pointer;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #D8D8D8;

    &.active {
      background-color: #FFCC3F;
    }
  }
}
</style>
