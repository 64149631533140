<template>
  <div v-if="showFavorites" class="favorites-main">
    <el-popover
      v-model="visible"
      placement="bottom"
      popper-class="favorites-popover"
      trigger="click"
      width="450"
    >
      <div slot="reference" class="slot-reference">
        <i class="el-icon-star-off"></i>
      </div>
      <div class="main-box">
        <!--   搜索     -->
        <div class="search-wrapper">
          <el-input v-model="searchValue" clearable placeholder="请输入内容">
            <div
              slot="prefix"
              style="height: 100%; display: flex; align-items: center"
            >
              <svg-icon fill-color="#666" icon-class="f-search"/>
            </div>

            <div slot="append" @click="search">
              <span>搜 索</span>
            </div>
          </el-input>
        </div>

        <!--   最近访问     -->
        <div class="visit-wrapper">
          <div class="favorites-title">
            <div>
              <svg-icon fill-color="#666" icon-class="rate"/>
            </div>
            <span>我的收藏</span>
          </div>
          <div class="main-content">
            <template v-if="visitDataList.length">
              <div
                v-for="(item, index) in visitDataList"
                :key="index"
                class="visit-item"
              >
                <app-link :to="resolvePath(item['menuPath'], item.query)">
                  <span style="color: #174592" @click="visible = false">
                    {{ item.menuName }}
                  </span>
                </app-link>
              </div>
            </template>

            <template v-else>
              <el-empty description="暂无收藏"/>
            </template>
          </div>
        </div>

        <!--   目录     -->
        <div class="menus-wrapper">
          <div class="favorites-title">
            <div>
              <svg-icon fill-color="#666" icon-class="component"/>
            </div>
            <span>全部功能</span>
          </div>
          <RecursiveComponent ref="recursive" :data="menuData"/>
        </div>

        <!--   按钮    -->
        <div class="btn-wrapper">
          <el-button class="green-type" @click="handleSubmit">保 存</el-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import AppLink from '@/layout/components/Sidebar/Link.vue'
import {isExternal} from '@/utils/validate'
import path from 'path'
import RecursiveComponent from '@/layout/components/Favorites/JSX.vue'
import Template from '@/views/social/config/sms/msgTemplate.vue'
import {
  batchOperationMenus,
  getCurrentUserCollectedMenus,
  getCurrentUserCollectedMenusByKeyWord,
  getMenuListByMenuId,
} from '@/api/layout/favorites'

export default {
  name: 'Favorites',
  components: {Template, AppLink, RecursiveComponent},

  props: {
    showFavorites: {
      type: Boolean,
      default: true,
    },

    currentMenuId: {
      type: [String, Number],
      default() {
        if (localStorage.getItem('TOPBAR_ROUTES_NEW')) {
          return JSON.parse(localStorage.getItem('TOPBAR_ROUTES_NEW'))[0]
            .menuId
        }
        return ''
      },
    },
  },

  data() {
    return {
      visible: false,
      searchValue: '',
      visitDataList: [],
      menuData: [],
    }
  },

  watch: {
    currentMenuId: {
      handler(menuId) {
        if (menuId) this.initData()
      },
      immediate: true,
    },
  },

  methods: {
    search() {
      getCurrentUserCollectedMenusByKeyWord({keyWord: this.searchValue}).then(
        (res) => {
          this.visitDataList = res.data || []
        }
      )
    },

    initData() {
      this.reset()
      this.getUserCollectedIdList()
      this.getMenuListByMenuId()
    },

    reset() {
      this.searchValue = ''
      this.$nextTick(() => {
        this.$refs.recursive.hasCollectedIdList = []
      })
    },

    getUserCollectedIdList() {
      getCurrentUserCollectedMenus().then((response) => {
        this.visitDataList = response.data || []
      })
    },

    getMenuListByMenuId() {
      getMenuListByMenuId(this.currentMenuId).then((res) => {
        this.menuData = res.data || []
      })
    },

    handleSubmit() {
      let list = this.$refs.recursive.hasCollectedIdList
      batchOperationMenus(list).then(async () => {
        await this.$elMessage.success('保存成功')
        this.initData()
      })
    },

    resolvePath(routePath, routeQuery) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (routeQuery) {
        let query = JSON.parse(routeQuery)
        return {path: path.resolve('', routePath), query: query}
      }
      return path.resolve('', routePath)
    },
  },
}
</script>

<style lang="scss" scoped>
$border-style: 1.5px solid #d8d8d8;

.favorites-main {
  padding: 0 15px;
}

.slot-reference {
  display: flex;
  height: 100%;
  padding-bottom: 4px;
  align-items: center;

  > i {
    color: #54c2db;
    font-size: 22px;
  }
}

.main-box {
  width: 100%;
  max-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  transition: all ease 0.3s;

  > div {
    width: 100%;
    padding-bottom: 16px;
  }

  .favorites-title {
    display: flex;
    align-items: center;

    > div {
      display: flex;

      .svg-icon {
        margin-left: 5px;
        width: 24px;
        height: 24px;
      }
    }

    > span {
      margin-top: 2px;
      font-size: 16px;
      color: #333;
      margin-left: 10px;
    }
  }

  .search-wrapper {
    .svg-icon {
      margin-left: 0;
      width: 24px;
      height: 24px;
    }

    ::v-deep .el-input {
      border-bottom: $border-style;

      > input {
        background: transparent;
        border: none;
        height: 50px;
        line-height: 50px;
        padding-left: 40px;
      }

      &-group__append {
        padding: 0;
        background-color: transparent;
        border: none;
        color: #2797ff;

        > div {
          height: 100%;
          display: flex;
          align-items: center;
          border: 1px solid;
          border-radius: 20px;
          padding: 5px 15px;
          cursor: pointer;
        }
      }
    }
  }

  .visit-wrapper {
    margin-bottom: 16px;
    border-bottom: $border-style;

    .main-content {
      display: flex;
      flex-wrap: wrap;

      ::v-deep .el-empty {
        width: 100%;
        height: 40px;

        &__image {
          width: 30px;
        }

        &__description {
          margin-top: 10px;

          > p {
            font-size: 12px;
          }
        }
      }

      .visit-item {
        width: 25%;
        display: flex;
        padding-top: 12px;
        overflow: hidden;
        font-size: 14px;
        color: #666;
      }
    }
  }

  .menus-wrapper {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-bottom: $border-style;
  }

  .btn-wrapper {
    padding-bottom: 0;
    padding-top: 20px;

    .el-button {
      color: #fff;
      padding: 6px 16px;

      &,
      &:active {
        background: linear-gradient(90deg, #1c9f9f 0%, #00c5a1 100%);
        border: 1px solid #cdd6e4;
      }

      &:not(:active):hover {
        background: linear-gradient(90deg, #0ab8b3 0%, #00d1ab 100%);
        border-color: #cdd6e4;
      }
    }
  }
}
</style>
