<template>
  <div class="wrapper">
    <template v-for="(item, index) in Object.values(pages)">
      <img
          v-if="page === item.value"
          :key="index"
          :src="`/page-image/${item.value}.png`"
          alt=""
      />
    </template>
    <!-- 单独添加入口 -->
    <template v-for="(item, index) in pageEnter[page]">
      <div
          :key="index"
          :class="page"
          class="page-enter"
          @click="to(item)"
      ></div>
    </template>
  </div>
</template>
<script>
import { pages, links } from './monitorPage.js';
export default {
  props: ['page'],
  data() {
    return {
      pages,
      // 页面链接
      pageEnter: {
        [pages.BJJC.value]: [pages.BJSJ],
        [pages.BJSJ.value]: [pages.BJJC],
        [pages.DJLR.value]: [pages.DJLRSJ],
        [pages.SZLS.value]: ['/index', links.YSGL, '/monitor', links.YWGL, links.XXFB],
        [pages.BJSJJC.value]: [pages.BJMLJC, pages.BJJCTJ, pages.SZLS, '/monitor', pages.BSDT],
        [pages.BJMLJC.value]: [pages.BJSJJC, pages.BJJCTJ, pages.SZLS, '/monitor', pages.BSDT],
        [pages.BJJCTJ.value]: [pages.BJSJJC, pages.BJMLJC, pages.SZLS, '/monitor', pages.BSDT],
        [pages.BSDT.value]: [pages.SZLS, '/monitor']
      }
    };
  },

  methods: {
    to(to) {
      if (typeof to === 'string') {
        location.href = to;
      } else {
        let path = `/${to.frame}/${to.value}`.replace(/^\/+/, '/');
        this.$router.push(path);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: #f5f5f5;

  img {
    height: 100%;
  }
}
.page-enter {
  // border: 1px dashed rgba(255, 255, 255, 0.7);
  cursor: pointer;
  position: fixed;
  z-index: 3;
  width: 50px;
  height: 30px;

  &.bjjc {
    right: 60px;
    top: 42%;
  }
  &.bjsj {
    left: 159px;
    top: 10%;
  }
  &.djlr {
    right: 322px;
    top: 46%;
    height: 90px;
    width: 80px;
  }
  &.szls {
    position: absolute;
    z-index: 10;
    &:nth-of-type(1) {
      top: 0;
      left: 57%;
      width: 450px;
      height: 100px;
    }
    // 顶部导航通用
    @mixin rect {
      width: 100px;
      height: 50px;
      top: 30px;
    }
    &:nth-of-type(2) {
      @include rect;
      left: 70px;
    }
    &:nth-of-type(3) {
      @include rect;
      left: 250px;
    }
    &:nth-of-type(4) {
      @include rect;
      left: 430px;
    }
    &:nth-of-type(5) {
      @include rect;
      right: -480px;
    }
  }

  &.bjsjjc {
    width: 100px;
    &:nth-of-type(1) {
      top: 22%;
      left: 3%;
    }
    &:nth-of-type(2) {
      top: 26%;
      left: 3%;
    }
  }

  &.bjmljc {
    width: 100px;
    &:nth-of-type(1) {
      top: 19%;
      left: 3%;
    }
    &:nth-of-type(2) {
      top: 26%;
      left: 3%;
    }
  }

  &.bjjctj {
    width: 100px;
    &:nth-of-type(1) {
      top: 19%;
      left: 3%;
    }
    &:nth-of-type(2) {
      top: 22%;
      left: 3%;
    }
  }

  &.bjsjjc,
  &.bjmljc,
  &.bjjctj {
    &:nth-of-type(3) {
      width: 200px;
      top: 2%;
      left: 20%;
    }
    &:nth-of-type(4) {
      width: 200px;
      top: 2%;
      left: 30%;
    }
    &:nth-of-type(5) {
      width: 200px;
      top: 2%;
      left: 38%;
    }
  }

  &.bsdt {
    &:nth-of-type(1) {
      width: 130px;
      top: 2%;
      left: 18%;
    }
    &:nth-of-type(2) {
      width: 130px;
      top: 2%;
      left: 25%;
    }
  }
}
</style>
