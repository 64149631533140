import { render, staticRenderFns } from "./AlarmTotal.vue?vue&type=template&id=a0fd3e82&scoped=true"
import script from "./AlarmTotal.vue?vue&type=script&lang=js"
export * from "./AlarmTotal.vue?vue&type=script&lang=js"
import style0 from "./AlarmTotal.vue?vue&type=style&index=0&id=a0fd3e82&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0fd3e82",
  null
  
)

export default component.exports