import { render, staticRenderFns } from "./Percent.vue?vue&type=template&id=ddf9bab4&scoped=true"
import script from "./Percent.vue?vue&type=script&lang=js"
export * from "./Percent.vue?vue&type=script&lang=js"
import style0 from "./Percent.vue?vue&type=style&index=0&id=ddf9bab4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddf9bab4",
  null
  
)

export default component.exports