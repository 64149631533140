<template>
  <div class="map_container">
    <div id="map_container"></div>
    <div ref="infoWindow">
      <slot name="infoWindow"></slot>
      <slot name="polygonInfoWindow"></slot>
    </div>
  </div>
</template>

<script>
var map = null;
var view = null;
var view2 = null;
var renderOrder = 0; //
var canvas = null;
var ctx = null;
import {area, YanCunRole, XingCheng, XinZhen} from '@/assets/survey/FangShanArea.js'
import bmap_func from '@/assets/survey/bmap_func.mixin.js'
import {FangShan} from '@/assets/survey/FangShan.js'
import locationIcon from '@/assets/survey/image/page-image/djlr/location-blue.png'

var windowTimer = 0;
var green = 50, red = 0;

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: false
    },
    initCenter: {
      type: Array,
      default: () => [115.876877, 39.695933]
    },
    infoWindowImage: {
      default: '/town-info.png',
      type: String
    },
    infoWindowRect: {
      default: function () {
        return {width: 420, height: 258}
      },
      type: Object
    },
    markers: {
      default: () => [],
      type: Array
    },
    lines: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    lines(list) {
      this.addLine(list, 'lawngreen')
    },
    markers(list) {
      this.addMarker(list)
    }
  },
  mixins: [bmap_func],
  methods: {
    showInfo(point) {
      var content = this.$refs.infoWindow;
      let opts = this.infoWindowRect;
      var infoWindow = new BMapGL.InfoWindow(content, opts);  // 创建信息窗口对象
      map.openInfoWindow(infoWindow, new BMapGL.Point(...point)); //开启信息窗口
    },

    // 转换坐标数据
    tranPoint(boundaries) {
      var count = boundaries.length; //行政区域的点有多少个
      var pointsList = [];
      for (var i = 0; i < count; i++) {
        var path = [];
        var str = boundaries[i].replace(' ', '');
        var points = str.split(';');
        for (var j = 0; j < points.length; j++) {
          path.push(points[j].split(','));
        }
        pointsList.push(path);
      }
      return pointsList;
    },

    // 掩膜
    addShade(points) {
      const full = [
        new BMapGL.Point(-180, 85),
        new BMapGL.Point(180, 85),
        new BMapGL.Point(180, -85),
        new BMapGL.Point(-180, -85)
      ];
      points.forEach(p => {
        p = p.map(p1 => new BMapGL.Point(...p1));
        var mapmask = new BMapGL.Polygon([full, p], {
          fillColor: '#000F2E',
          fillOpacity: 0.9
        });
        map.addOverlay(mapmask);
      });
    },

    // 添加带高度的地图范围
    addHighPolygon(points, properties) {
      let color = `rgba(${red += 2}, ${green += 2}, 255, 1)`;
      points.forEach(p => {
        var shapeLayer = new mapvgl.ShapeLayer({
          enablePicked: true, // 是否可以拾取
          selectedIndex: -1, // 选中项
          selectedColor: '#024099', // 选中项颜色
          autoSelect: true, // 根据鼠标位置来自动设置选
          renderOrder: renderOrder++,
          color,
          onClick: e => {
            if (e.dataIndex === -1) return;
            this.$emit('clickTown', e.dataItem.properties)
            if (this.$slots.polygonInfoWindow) {
              clearTimeout(windowTimer);
              windowTimer = setTimeout(() => {
                this.showInfo(e.dataItem.properties.govPoint)
              }, 300);
            }
          },
          data: [
            {
              geometry: {
                type: 'Polygon',
                coordinates: [p]
              },
              properties: {
                height: 5000,
                ...properties
              }
            }
          ]
        });
        view.addLayer(shapeLayer);
      });
    },

    // 添加边 实现渐变色
    addSide(points) {
      points.forEach(p => {
        var prism = new mapvgl.WallLayer({
          renderOrder: renderOrder++,
          gradient: {
            0: '#9BFFFF',
            1: '#0FCCE2'
          },
          height: 5000,
          data: [
            {
              geometry: {
                type: 'LineString',
                coordinates: p
              }
            }
          ]
        });
        view.addLayer(prism);
      });
    },

    // 添加平面
    addPolygon(points) {
      points.forEach(point => {
        var layer = new mapvgl.PolygonLayer({
          lineColor: '#2ab6b0',
          lineWidth: 4,
          fillColor: 'rgba(25, 25, 250, 0)',
          data: [{
            geometry: {
              type: 'Polygon',
              coordinates: [point]
            },
            properties: {
              height: 0, // 多边形高度
            }
          }]
        });
        view.addLayer(layer);
      })
    },

    // 添加文字标签
    addText(properties) {
      var textLayer = new mapvgl.TextLayer({
        color: '#fff',
        fontSize: 16,
        data: properties.map(p => {
          return {
            geometry: {
              type: 'Point',
              coordinates: p.gov.point
            },
            properties: {
              text: p.name
            }
          }
        })
      });
      view2.addLayer(textLayer);
    },

    // 添加mark
    addMarker(list, icon = locationIcon) {
      if (view2 == null) return;
      var layer = new mapvgl.IconLayer({
        renderOrder: renderOrder++,
        width: 150 / 6,
        height: 153 / 6,
        offset: [0, -153 / 12],
        opacity: 0.8,
        icon,
        enablePicked: true, // 是否可以拾取
        selectedIndex: -1, // 选中项
        selectedColor: '#ff0000', // 选中项颜色
        autoSelect: true, // 根据鼠标位置来自动设置选中项
        onClick: (e) => { // 点击事件
          if (e.dataIndex === -1) return;
          this.$emit('clickMarker', e.dataItem.properties)
          if (this.$slots.infoWindow) {
            clearTimeout(windowTimer);
            windowTimer = setTimeout(() => {
              this.showInfo(e.dataItem.properties.location.split(','))
            }, 300);
          }
        },
      });
      view2.addLayer(layer);
      layer.setData(list.map(item => {
        return {
          geometry: {
            type: 'Point',
            coordinates: item.location != null ? item.location.split(',') : []
          },
          properties: item
        }
      }));
    },

    addLine(data, color) {
      var layer = new mapvgl.LineLayer({
        renderOrder: renderOrder++,
        color,
        blend: 'lighter',
        width: 3,
        lineCap: 'round',
        lineJoin: 'round',
        data: data.map(item => {
          return {
            geometry: {
              type: 'LineString',
              coordinates: item.location.map(item => item.split(',').map(v => Number(v)))
            },
            properties: item
          }
        }),
        enablePicked: true, // 是否可以拾取
        onClick: e => {
          if (e.dataIndex === -1) return;
          this.$emit('clickMarker', e.dataItem.properties)
          if (this.$slots.infoWindow) {
            clearTimeout(windowTimer);
            windowTimer = setTimeout(() => {
              this.showInfo(e.dataItem.properties.location[0].split(','))
            }, 300);
          }
        }
      });
      view2.addLayer(layer);

      var marker = new mapvgl.PointLayer({
        renderOrder: renderOrder++,
        color,
        size: 5,
        data: data.map(item => item.location).flat().map(item => {
          return {
            geometry: {
              type: 'Point',
              coordinates: item.split(',')
            }
          }
        })
      });
      view2.addLayer(marker);
    }
  },

  mounted() {
    green = 50;
    red = 0;
    map = new BMapGL.Map('map_container', {
      // restrictCenter: false
    });
    map.enableScrollWheelZoom(true);
    let center = new BMapGL.Point(...this.initCenter);
    map.centerAndZoom(center, 11.9);
    map.setTilt(30);
    map.setMapStyleV2({
      styleId: '5a7807de79906d6aa1481c651c729170'
    });

    // VGL 图层
    view = new mapvgl.View({
      map
    });

    view2 = new mapvgl.View({map});

    // 绘制乡镇边界
    [YanCunRole, ...area, XingCheng, XinZhen].forEach(town => {
      this.addHighPolygon(town.points, {
        name: town.name,
        govPoint: town.gov && town.gov.point
      });
    });
    if (this.showLabel) {
      var towns = [YanCunRole, ...area, XingCheng, XinZhen];
      this.addText(towns);
    }
    if (this.markers.length != 0) {
      this.addMarker(this.markers);
    }
    // 用房山全境坐标点绘制边界
    this.addSide([FangShan]);
  }
};
</script>

<style scoped lang="scss">
.map_container, #map_container {
  width: 100%;
  height: 100%;
}

#canvas {
  width: 20px;
  height: 20px;
}

::v-deep .BMap_bubble_pop {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  backdrop-filter: blur(5px);

  .BMap_bubble_content {
    width: auto !important;
    height: auto !important;
  }

  .BMap_bubble_buttons {
    display: none;
  }

  > img:last-child {
    display: none;
  }
}

</style>
