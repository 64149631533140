import request from '@/utils/request'

//获取报警数据信息
export function getAlertData() {
  return request({
    url: '/survey/monitor/homePage/alarmHandleStatics',
    method: 'get',
  })
}

//获取标签云报警数据
export function getTagCloudAlarmData() {
  return request({
    url: '/survey/monitor/homePage/alarmTypeStatics',
    method: 'get',
  })
}

//获取年月日报警、故障量趋势数据折线图；报警、故障量分类占比圆环图
export function getFaultAndAlarm(staticsType) {
  return request({
    url: '/survey/monitor/homePage/getAlarmStatics/' + staticsType,
    method: 'get'
  })
}

//获取实时报警数据表格
export function getRealtimeAlarmData(params) {
  return request({
    url: '/survey/monitor/homePage/alarmList',
    method: 'get',
    query: params
  })
}

//获取报警分类占比
export function getAlarmClassifyProportion() {
  return request({
    url: '/survey/monitor/classify/proportion',
    method: 'get',
  })
}

//数据字典-报警内容
export function getWarnTypeDict() {
  return request({
    url: '/survey/dict/monitor/warnTypeDict',
    method: 'get',
  })
}

//数据字典-报警类别
export function getImportTypeDict() {
  return request({
    url: '/survey/dict/monitor/importTypeDict',
    method: 'get',
  })
}

//数据字典-报警类型
export function getAlarmTypeDict() {
  return request({
    url: '/survey/dict/monitor/alarmTypeDict',
    method: 'get',
  })
}

//数据字典-处理状态
export function getHandleStatusDict() {
  return request({
    url: '/survey/dict/monitor/handleStatusDict',
    method: 'get',
  })
}

//获取水源井数据信息
export function getWellData() {
  return request({
    url: '/survey/monitor/homePage/wellCountStatics',
    method: 'get',
  })
}

//获取供水数据信息
export function getWaterData() {
  return request({
    url: '/survey/monitor/homePage/getSupplyRate',
    method: 'get',
  })
}

//获取实时报警数据表格
export function getWellDeptList(params) {
  return request({
    url: '/survey/monitor/homePage/wellDeptList',
    method: 'get',
    query: params
  })
}

//数据字典-供水类型
export function getSupplyTypeDict() {
  return request({
    url: '/survey/dict/monitor/supplyTypeDict',
    method: 'get',
  })
}

//数据字典-水源类型
export function getSourceTypeDict() {
  return request({
    url: "/survey/dict/monitor/sourceTypeDict",
    method: "get",
  });
}

//总供水量趋势折线图、占比图
export function getSupplyWaterLine() {
  return request({
    url: '/survey/monitor/homePage/supplyLogsStatics',
    method: 'get',
  })
}

// 根据部门id查询乡镇信息
export function getTownById() {
  return request({
    url: "/survey/monitor/homePage/getTown",
    method: "get",
  });
}

