import locationPink from '@/assets/survey/image/page-image/djlr/location-pink.png';
import locationBlue from '@/assets/survey/image/page-image/djlr/location-blue.png';

export default {
  data() {
    return {
      map: null,
      clickIndex: 0,
      list: [
        {
          abnormal: true,
          point: {
            lng: 118.40225026513117,
            lat: 24.961236206854228
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.40266673690276,
            lat: 24.96154851649717
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39742687693766,
            lat: 24.959023064474827
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39299106786645,
            lat: 24.96261882147329
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.40429511129,
            lat: 24.963145149066715
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39879272710918,
            lat: 24.95949463237523
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39451302440166,
            lat: 24.961558819390355
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39415104815416,
            lat: 24.961346527283176
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.3937541821698,
            lat: 24.962025807212495
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39048024728382,
            lat: 24.955660720721003
          }
        },
        {
          abnormal: false,
          point: {
            lng: 118.39368394824335,
            lat: 24.955311185153388
          }
        }
      ]
    };
  },
  methods: {
    // 信息窗口
    showInfoWindow(data, event) {
      console.log(data);
      if (data.dataItem == null) return;
      const abnormal = data.dataItem.properties.abnormal; // 是否异常
      const {addr, guestName, takeDate} = data.dataItem.properties.params;
      const state = abnormal ? '异常，两天用水量为零' : '正常';
      var point = new BMapGL.Point(...data.dataItem.geometry.coordinates);
      var html = `
        <div class="head">${this.selectedDeptText}</div>
        <p>用户名：${guestName}</p>
        <p>用水状况：${state}</p>
        <p>最新上数：${takeDate}</p>
        <p>住址：${addr}</p>
      `;
      if (abnormal) {
        html += `
          <p>报警短信：已发送</p>
        `;
      }
      var infoWindow = new BMapGL.InfoWindow(html, {
        enableAutoPan: false,
        width: 300,
        height: 300,
        offset: new BMapGL.Size(100, 30)
      });
      // 此事件会被触发多次，后执行的会关掉弹窗，所以延迟展示弹窗
      setTimeout(() => {
        this.map.openInfoWindow(infoWindow, point);
        this.map.setCenter(point);
      }, 0);
    },

    initMap() {
      return new Promise((resolve, reject) => {
        this.map = initMap({
          tilt: 60,
          heading: 0,
          center: [118.39483631718849, 24.96413295319954],
          zoom: 16,
          style: style2
        });
        this.view = new mapvgl.View({
          map: this.map
        });
        this.map.setDisplayOptions({
          poi: !true //是否显示POI信息
        });

        this.map.addEventListener('tilesloaded', e => {
          resolve();
        });

        // this.map.addEventListener('click', function (e) {
        // alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
        // });
      });
    },

    createMarker(list) {
      this.list = list || [];
      this.view.removeAllLayers();
      const SELECT_COLOR = {
        normal: 'deepskyblue',
        warn: 'red'
      };
      const pointList = [
        // 异常
        {
          abnormal: true,
          icon: locationPink,
          select: SELECT_COLOR.warn
        },
        // 正常
        {
          abnormal: false,
          icon: locationBlue,
          select: SELECT_COLOR.normal
        }
      ];

      // 点位坐标
      pointList.forEach(prop => {
        var list = this.list.filter(item => item.abnormal === prop.abnormal);
        var data = list.map(item => {
          return {
            geometry: {
              type: 'Point',
              coordinates: [item.point.lng, item.point.lat]
            },
            properties: {
              abnormal: item.abnormal,
              params: item.params
            }
          };
        });

        // 点位
        var iconLayer = new mapvgl.IconLayer({
          icon: prop.icon,
          width: 30,
          height: 30,
          offset: [0, -12],
          data: data,
          enablePicked: true,
          autoSelect: true,
          selectedColor: prop.select,
          onClick: this.showInfoWindow
        });
        this.view.addLayer(iconLayer);

        // 扩散效果
        if (prop.abnormal) {
          var circleLayer = new mapvgl.CircleLayer({
            type: 'bubble',
            color: '#ff77a7',
            size: 0,
            radius: 50,
            data: data
          });
          this.view.addLayer(circleLayer);
        }
      });
    }
  }
};
