import Vue from 'vue'
import {mapGetters} from 'vuex'
import Cookies from 'js-cookie'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
// 全局引用
import Print from 'vue-print-nb'
import $ from 'jquery'
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import {download} from '@/utils/request'

import './assets/icons' // icon
import './permission' // permission control
import {getDicts} from '@/api/system/dict/data'
import {getConfigKey, updateConfigByKey} from '@/api/system/config'
import VCalendar from 'v-calendar' // 引入日历插件
import {
  addDateRange,
  handleTree,
  parseTime,
  poiDownload,
  resetForm,
  selectDictLabel,
  selectDictLabels
} from '@/utils/ruoyi'
import ExTableColumn from 'ex-table-column'
import * as echarts from 'echarts'
// 分页组件
import Pagination from '@/components/Pagination'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 富文本组件
import Editor from '@/components/Editor'

import cmsEditor from '@/components/cmsEditor'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 图片上传组件
import ImageUpload from '@/components/ImageUpload'
// 图片预览组件
import ImagePreview from '@/components/ImagePreview'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
//字典数据组件
import DictDataWater from '@/components/revenue/DictDataWater'

import './registerServiceWorker'

import EventBus from '@/utils/eventBus'

//打印插件
//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.component(ExTableColumn.name, ExTableColumn)

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

let moment = require('moment')
moment.locale('zh-cn')
Vue.prototype.moment = moment
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.updateConfigByKey = updateConfigByKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.poiDownload = poiDownload
Vue.prototype.handleTree = handleTree
Vue.prototype.eventBus = EventBus

// el-table-column 组件的 width 属性统一配置
Vue.prototype.cw = {
  /*guestName: 100, // 户名
  location: 150, // 位置
  useType: 150, // 用水类型
  meterAddr: 70, // 地址（编号）
  deptId: 200, // 部门
  guestNo: 100, // 户号
  tel: 110, // 电话
  takeTime: 150, // 最后上数时间
  createBy: 120, // 收费员*/
  charcter2: 80, charcter3: 90, charcter4: 100, charcterMore: 130
}
Vue.prototype.$echarts = echarts
Vue.prototype.DictDataWater = DictDataWater
Vue.prototype.$ = $

// 全局消息提示
Vue.prototype.$elMessage = {
  elMsgInstance: null, close() {
    if (this.elMsgInstance) {
      this.elMsgInstance.close()
    }
  }, warning(msg, duration = 3000) {
    this.close()
    this.elMsgInstance = Vue.prototype.$message.warning({
      showClose: true, message: msg, duration
    })
  }, error(msg, duration = 3000) {
    this.close()
    this.elMsgInstance = Vue.prototype.$message.error({
      showClose: true, message: msg, duration
    })
  }, success(msg, duration = 3000) {
    this.close()
    this.elMsgInstance = Vue.prototype.$message.success({
      showClose: true, message: msg, duration
    })
  }
}

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('cmsEditor', cmsEditor)

filterRenderer()

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import {filterRenderer} from '@/views/V5/toll/integratedView/renderJS/filter'

Vue.use(VXETable)

Vue.use(VCalendar, {
  componentPrefix: 'vc'
}) // 组件前缀，以防后期跟其他冲突
Vue.use(Print)
Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
DictData.install()
DictDataWater.install()

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.mixin({
  computed: {
    ...mapGetters(['isDark', 'themeType'])
  }
})

Vue.config.productionTip = false

new Vue({
  el: '#app', router, store, render: (h) => h(App)
})

Vue.use(VueQuillEditor)
