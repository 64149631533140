<template>
  <ul class="sub-list">
    <li v-for="(item, index) in data" :key=item.menuId class="sub-item">
      <span>{{ item.menuName }}</span>
      <ChildrenComponent v-if="!item['canFavorite']" :data=item.children @send-menuId-parent="handleMenuIdFromChild" />
    </li>
  </ul>
</template>

<script>
import ChildrenComponent from '@/layout/components/Favorites/children.vue'
import './styles.scss'

export default {
  components: {ChildrenComponent},

  name: 'RecursiveComponent',

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      hasCollectedIdList: [],
    }
  },

  watch: {

  },

  methods: {
    handleMenuIdFromChild({menuId, hasFavorite}) {
      this.hasCollectedIdList.push(menuId)
      // if(hasFavorite) {
      //
      // }
      //
      // if(this.hasCollectedIdList.includes(menuId)) {
      //   this.hasCollectedIdList.splice(this.hasCollectedIdList.indexOf(menuId), 1)
      // }else {
      //   this.hasCollectedIdList.push(menuId)
      // }

    },

  },
}

</script>

<style lang="scss" scoped>

</style>
