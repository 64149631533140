<template>
  <div class="syj-right">
    <SubTitle text="水质指标" style="padding: 10px 0; " light/>
    <AlarmTotal icon="warn" title="水质合格率" :total="this.wellInfoData.data5"/>
    <AlarmTotal icon="warn" title="数据上传成功率" :total="this.wellInfoData.data6"/>
    <TableData style="height: 320px; margin-top: 24px;" title="水质健康管理" search>
      <template v-slot:table>
        <el-table
          :data="wellHealthList"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column prop="id" label="ID" width="50"></el-table-column>
          <el-table-column prop="wellDepth" label="井深" width="45"></el-table-column>
          <el-table-column prop="ph" label="PH" width="45"></el-table-column>
          <el-table-column prop="conductivity" label="电导率" width="50"></el-table-column>
          <el-table-column prop="turbidity" label="浊度" width="50"></el-table-column>
          <el-table-column prop="dissolvedOxygen" label="溶解率" width="50"></el-table-column>
          <el-table-column prop="temperature" label="温度" width="50"></el-table-column>
          <el-table-column prop="alarmNum" label="历史报警" width="60"></el-table-column>
        </el-table>
      </template>
    </TableData>
    <TableData style="height: 320px; margin-top: 24px;" title="实时报警数据" search>
      <template v-slot:table>
        <el-table
          ref="autoScrollTable"
          :data="wellAlarmList"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column prop="devNo" label="设备编号" width="60"></el-table-column>
          <el-table-column prop="warnType" label="报警内容" width="60" :formatter="warnTypeDict"></el-table-column>
          <el-table-column prop="alarmType" label="类别" width="70" :formatter="alarmTypeDict"></el-table-column>
          <el-table-column prop="importType" label="级别" width="60" :formatter="importTypeDict"></el-table-column>
          <el-table-column prop="alarmTime" label="报警时间" width="60" :formatter="alarmTimeFormat"></el-table-column>
          <el-table-column prop="handleStatus" label="处理状态" width="60"
                           :formatter="statusTypeDict"></el-table-column>
        </el-table>
      </template>
    </TableData>
  </div>
</template>
<script>
import AlarmTotal from '@/components/survey/AlarmTotal.vue';
import TableData from '@/components/survey/TableData.vue';
import SubTitle from '@/components/survey/SubTitle.vue';
import {getWellInfoData, getWellAlarmList, getWellQualityList} from '@/api/survey/monitor/syjPage.js'
import {getImportTypeDict, getWarnTypeDict, getAlarmTypeDict, getHandleStatusDict} from '@/api/survey/monitor/homePage'
import autoScrollTable from '@/assets/survey/table_scroll.mixin.js'

export default {
  mixins: [autoScrollTable],
  components: {AlarmTotal, TableData, SubTitle},
  data() {
    return {
      //水源井数据相关信息
      wellInfoData: {},
      wellHealthList: [],
      wellAlarmList: [],
      warnTypeOptions: [],
      alarmTypeOptions: [],
      importTypeOptions: [],
      statusTypeOptions: []
    };
  },
  methods: {
    /*获取数值信息*/
    getWellInfoData() {
      getWellInfoData().then(res => {
        this.wellInfoData = res.data
      })
    },
    /*获取管网健康数据*/
    getWellHealthList() {
      getWellQualityList().then(res => {
        this.wellHealthList = res.rows;
      })
    },
    /*获取实时报警数据*/
    getWellAlarmList() {
      getWellAlarmList().then(res => {
        this.wellAlarmList = res.rows;
      })
    },
    getDict() {
      getWarnTypeDict().then(res => {
        this.warnTypeOptions = res.data
      })
      getAlarmTypeDict().then(res => {
        this.alarmTypeOptions = res.data
      })
      getImportTypeDict().then(res => {
        this.importTypeOptions = res.data
      })
      getHandleStatusDict().then(res => {
        this.statusTypeOptions = res.data
      })
    },
    /*报警内容字典翻译*/
    warnTypeDict(row) {
      let value = "";
      this.warnTypeOptions.forEach(item => {
        if (row.warnType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*报警类型字典翻译*/
    alarmTypeDict(row) {
      let value = "";
      this.alarmTypeOptions.forEach(item => {
        if (row.alarmType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*报警级别字典翻译*/
    importTypeDict(row) {
      let value = "";
      this.importTypeOptions.forEach(item => {
        if (row.importType == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },
    /*转换时间显示分和秒*/
    alarmTimeFormat(row) {
      return row.alarmTime.substring(11, 16)
    },
    /*处理状态字典翻译*/
    statusTypeDict(row) {
      let value = "";
      this.statusTypeOptions.forEach(item => {
        if (row.handleStatus == item.dictValue) {
          value = item.dictLabel;
        }
      })
      return value
    },

  },
  created() {
    this.getWellInfoData()
    this.getWellHealthList()
    this.getWellAlarmList()
    this.getDict()
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.syj-right {
  z-index: 11;
  backdrop-filter: blur(5px);
  background-color: #000c35a6;
  padding: 12px 25px 35px;
  width: 450px;
  margin-top: 108px !important;
  position: fixed;
  @include side-bar-top-border-small;
  @include side-bar-bottom-border-small;
}
</style>
