<template>
  <div class="container">
    <Map :markers="markers" @clickMarker="clickMarker" :initCenter="[115.89067497263319, 39.90486050356841]"/>
    <left :infoWindowData="infoWindowData"/>
    <img src="~@/assets/survey/xdsb.png" alt="" class="flow"/>
    <right/>
  </div>
</template>
<script>
import left from "./left.vue";
import right from "./right.vue";
import Map from "@/components/survey/BMap.vue";
import * as api from "@/api/survey/monitor/xdsb.js";

export default {
  components: {left, right, Map},
  data() {
    return {
      markers: [],
      infoWindowData: {}
    }
  },
  methods: {
    clickMarker(e) {
      this.infoWindowData = e;
    }
  },
  mounted() {
    api.disinfectionPointList().then(res => {
      this.markers = res.rows;
    });
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-color: #091220;
}

.flow {
  position: fixed;
  z-index: 5;
  width: 40%;
  min-width: 0px !important;
  top: 8%;
  left: 48%;
  transform: translateX(-50%);
}
</style>
