export default {
  methods: {
    // 转换坐标到百度，并发：每组10个
    translatePoint: async points => {
      // console.log('arguments', points);
      var result = [];
      // 实例化转换坐标对象
      var convertor = new BMapGL.Convertor();
      for (var i = 0, m = points.length; i < m; ) {
        // BMapGL.Point 对象数组
        var j = i;
        i = i + 10 < m ? i + 10 : m;
        var pointObjList = points.slice(j, i).map(point => {
          return new BMapGL.Point(...point);
        });
        // 转换坐标
        var res = await new Promise((resolve, reject) => {
          convertor.translate(
            pointObjList,
            COORDINATES_WGS84,
            COORDINATES_BD09,
            res => {
              if (res.status === 0) {
                resolve(res.points);
              } else {
                reject(res);
              }
            }
          );
        });
        res = res.map(item => {
          return [item.lng, item.lat];
        });
        result = result.concat(res);
      }
      return result;
    }

    
    // 测试代码
/*     var startTime = Date.now();
    var towns = [YanCun, XingCheng, ...FangShanRegion];
    for (var i = 0; i < towns.length; i++) {
      for (var j = 0; j < towns[i].points.length; j++) {
        var result = await translatePoint(towns[i].points[j]);
        towns[i].points[j] = result;
      }
    }
    console.log('执行时间：', Date.now() - startTime);
    console.log(towns); */
  }
};
