<template>
	<div :class="{ isV5: isV5 }" class="navbar">
		<hamburger id="hamburger-container" :fillColor="isV5 ? '#2A57A5' : '#333'" :is-active="sidebar.opened"
			class="hamburger-container" @toggleClick="toggleSideBar" />

		<favorites :currentMenuId="currentMenuId" class="favorites-container" />
		<breadcrumb v-if="!topNav" id="breadcrumb-container" class="breadcrumb-container" />
		<top-nav v-if="topNav" id="topmenu-container" class="topmenu-container" @send-top-menuId="sendTopMenuId" />

		<div class="right-menu">
			<template v-if="device !== 'mobile'">
				<!--        <search id="header-search" class="right-menu-item"/>-->

				<!--        <el-tooltip content="源码地址" effect="dark" placement="bottom">-->
				<!--          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />-->
				<!--        </el-tooltip>-->

				<el-tooltip v-show="isShowBannerSwitch" :content="isShowBanner ? '隐藏banner' : '显示banner'" effect="dark"
					placement="bottom">
					<div class="tooltip-turn-off" @click="toggleIsShwBanner"><i class="el-icon-turn-off"
							:style="{ color: isV5 ? '#e3eaf4' : '#5a5e66' }"></i></div>
				</el-tooltip>

				<el-tooltip content="文档地址" effect="dark" placement="bottom">
					<ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect"
						url="https://joyo-tj.yuque.com/staff-wgbecf/help" />
				</el-tooltip>

				<el-tooltip content="消息中心" effect="dark" placement="bottom">
					<router-link :to="{ path: '/revenue/noticeMessages' }" class="right-menu-item hover-effect">
						<el-badge :hidden="msgFlag" :max="99" :value="msgValue" class="badge-item">
							<em class="el-icon-bell badge-item-bell"></em>
						</el-badge>
					</router-link>
				</el-tooltip>
				<el-tooltip content="任务中心" effect="dark" placement="bottom">
					<router-link :to="{ path: '/V5/toll/system/missionCenter' }" class="right-menu-item hover-effect">
						<el-badge class="badge-item">
							<em class="el-icon-download badge-item-bell"></em>
						</el-badge>
					</router-link>
				</el-tooltip>
				<screenfull id="screenfull" class="right-menu-item hover-effect" />
				<el-tooltip content="布局大小" effect="dark" placement="bottom">
					<size-select id="size-select" class="right-menu-item hover-effect" />
				</el-tooltip>
			</template>

			<el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
				<div class="avatar-wrapper">
					<img :src="isV5 ? logo : avatar" alt="" class="user-avatar" />
					<i class="el-icon-caret-bottom" />
				</div>
				<el-dropdown-menu slot="dropdown">
					<router-link to="/user/profile">
						<el-dropdown-item>个人中心</el-dropdown-item>
					</router-link>
					<el-dropdown-item @click.native="setting = true">
						<span>布局设置</span>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="logout">
						<span>退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
import { isHaveNewNoticeMessages } from '@/api/system/noticeMessages/index.js'
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import Favorites from '@/layout/components/Favorites/index.vue'
import Cookies from 'js-cookie'

export default {
	mounted() {
		this.getNoticeCounts()
	},

	data() {
		return {
			notifyFlag: true,
			msgFlag: true,
			msgValue: 0,
			currentMenuId: undefined
		}
	},

	components: {
		Favorites,
		Breadcrumb,
		TopNav,
		Hamburger,
		Screenfull,
		SizeSelect,
		Search,
		RuoYiGit,
		RuoYiDoc
	},
	computed: {
		logo() {
			return JSON.parse(localStorage.getItem('pagesData'))?.companyLogo
		},

		...mapGetters(['sidebar', 'avatar', 'device']),
		setting: {
			get() {
				return this.$store.state.settings.showSettings
			},
			set(val) {
				this.$store.dispatch('settings/changeSetting', {
					key: 'showSettings',
					value: val
				})
			}
		},
		topNav: {
			get() {
				return this.$store.state.settings.topNav
			}
		},

		isV5() {
			return this.$route.path.indexOf('V5') > -1
		},

		isShowBanner: {
			get() {
				return this.$store.state.settings.bannerIsShow && JSON.parse(Cookies.get('isShowBanner'))
			}
		},

		isShowBannerSwitch() {
			return JSON.parse(Cookies.get('isShowBanner'))
		},
	},
	methods: {
		toggleIsShwBanner() {
			this.$store.dispatch('settings/changeSetting', {
				key: 'bannerIsShow',
				value: !this.isShowBanner
			})
		},

		sendTopMenuId(menuId) {
			this.currentMenuId = menuId
		},

		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar')
		},
		async logout() {
			this.$confirm('确定注销并退出系统吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$store.dispatch('LogOut').then(() => {
						location.href = '/index'
					})
				})
				.catch(() => { })
		},
		getNoticeCounts() {
			this.msgFlag = true
			const hasNoticed = JSON.parse(localStorage.getItem('hasNoticed'))

			isHaveNewNoticeMessages()
				.then((res) => {
					this.msgFlag = !res.data['unreadCount']
					this.msgValue = !this.msgFlag ? res.data['unreadCount'] : 0

					if (!this.msgFlag && !hasNoticed) {
						this.$notify({
							title: '未读消息',
							message: `您有<span style="color:red">${this.msgValue}</span>条未读消息，请点击右上角小铃铛查看详情`,
							dangerouslyUseHTMLString: true,
							type: 'info',
							customClass: `${this.notifyFlag
								? `notify-info-${this.themeType}`
								: `notify-info-${this.themeType}-none`
								}`,
							position: 'bottom-right',
							duration: 0,
							onClose: this.notifyClose
						})
						localStorage.setItem('hasNoticed', JSON.stringify(true))

						setTimeout(() => {
							document
								.querySelector('.el-notification')
								.classList.add(`notify-info-${this.themeType}-none`)
							this.notifyFlag = false
							localStorage.setItem('hasNoticed', JSON.stringify(false))
						}, 1000 * 60 * 10)
					}
				})
				.catch(() => {
					this.msgFlag = true
					this.msgValue = 0
				})
		},

		notifyClose() {
			document
				.querySelector('.el-notification')
				.classList.add(`notify-info-${this.themeType}-none`)
			this.notifyFlag = false
			localStorage.setItem('hasNoticed', JSON.stringify(false))
		}
	}
}
</script>

<style lang="scss" scoped>
.navbar {
	height: 50px;
	overflow: hidden;
	position: relative;
	background: #fff;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	.hamburger-container,
	.favorites-container {
		line-height: 50px;
		height: 50px;
		float: left;
		cursor: pointer;
		transition: background 0.3s;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}

	.breadcrumb-container {
		float: left;
	}

	.topmenu-container {
		position: absolute;
		left: 95px;
	}

	.errLog-container {
		display: inline-block;
		vertical-align: top;
	}

	.right-menu {
		float: right;
		height: 100%;
		line-height: 50px;

		.tooltip-turn-off {
			display: inline-block;
			padding: 0 8px;
			height: 100%;
			font-size: 25px;
			color: #5a5e66;
			vertical-align: text-bottom;
		}

		::v-deep .badge-item {
			&-bell {
				font-size: 24px;
				margin-top: 13px;
			}

			.el-badge__content {
				font-size: 10px;
				height: 15px;
				line-height: 13px;
				padding: 0 2px;
			}

			.el-badge__content.is-fixed {
				top: 10px;
			}
		}

		&:focus {
			outline: none;
		}

		&-item {
			display: inline-block;
			padding: 0 8px;
			height: 100%;
			font-size: 18px;
			color: #5a5e66;
			vertical-align: text-bottom;

			&.hover-effect {
				cursor: pointer;
				transition: background 0.3s;

				&:hover {
					background: rgba(0, 0, 0, 0.025);
				}
			}
		}

		.avatar-container {
			margin-right: 30px;

			.avatar-wrapper {
				margin-top: 5px;
				position: relative;

				.user-avatar {
					cursor: pointer;
					width: 40px;
					height: 40px;
					border-radius: 10px;
				}

				.el-icon-caret-bottom {
					cursor: pointer;
					position: absolute;
					right: -20px;
					top: 25px;
					font-size: 12px;
				}
			}
		}
	}
}

.navbar.isV5 {
	background: linear-gradient(270deg, #1049a2 0%, #00205d 100%);
	mix-blend-mode: multiply;
	height: 48px;

	.hamburger-container {
		padding: 14px 14px !important;
		line-height: 0;
	}

	.topmenu-container.el-menu--horizontal.el-menu {
		background: transparent;

		::v-deep .el-menu-item {
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			margin: 0 19px !important;

			.el-menu-item-icon {
				display: flex;
				padding: 3px;
				border: 1.5px solid #174391;
				border-radius: 4px;
				font-size: 13px;
				color: #fff;
				margin-right: 8px;
			}

			&:hover {
				background-color: #001640;
			}

			&.is-active {
				border-bottom: 4px solid #53c8df;
				background-color: transparent;
			}
		}
	}

	.right-menu {

		::v-deep .svg-icon,
		.badge-item,
		.avatar-wrapper {
			color: #e3eaf4;
		}

		.avatar-wrapper {
			.user-avatar {
				width: 25px;
				height: 25px;
				border-radius: 0;
			}
		}
	}
}
</style>

<style lang="scss">
.notify-info-light {
	width: 240px !important;
	opacity: 1 !important;
	right: -300px !important;
	bottom: -300px !important;
	background: rgba(131, 213, 134, 0.3) !important;
	border-radius: 5px !important;
	transform: translate(-340px, -340px);
	transition: all 0.5s;

	.el-notification__title {
		line-height: 24px;
	}
}

.notify-info-light-none {
	opacity: 0 !important;
	width: 240px !important;
	right: -300px !important;
	bottom: -300px !important;
	background: rgba(131, 213, 134, 0.3) !important;
	border-radius: 5px !important;
	transform: translate(340px, 320px);
	transition: all 1s;

	.el-notification__title {
		line-height: 24px;
	}
}

/*黑暗模式*/
.notify-info-dark {
	width: 240px !important;
	opacity: 1 !important;
	right: -300px !important;
	bottom: -300px !important;
	background: rgba(22, 45, 114, 0.8) !important;
	border-radius: 5px !important;
	transform: translate(-340px, -340px);
	transition: all 0.5s;

	.el-notification__title {
		line-height: 24px;
		color: #fff;
	}

	.el-notification__content {
		color: #7e93b2;
	}
}

.notify-info-dark-none {
	opacity: 0 !important;
	width: 240px !important;
	right: -300px !important;
	bottom: -300px !important;
	background: rgba(22, 45, 114, 0.8) !important;
	border-radius: 5px !important;
	transform: translate(340px, 320px);
	transition: all 1s;

	.el-notification__title {
		line-height: 24px;
	}
}
</style>
