var autoScrollTimer = 0;
export default {
  beforeDestroy() {
    clearInterval(autoScrollTimer);
  },
  mounted() {
    autoScrollTimer = setInterval(() => {
      var table = this.$refs.autoScrollTable
      if (table) {
        table = table.$el.querySelector(".el-table__body-wrapper");
        let scrollTop = table.scrollTop + 1;
        if (scrollTop >= table.scrollHeight - table.clientHeight) {
          scrollTop = 0;
        }
        table.scrollTop = scrollTop;
      }
    }, 100);
  },
};
