import Vue from 'vue'
import { mergeRecursive } from "@/utils/ruoyi";
import DictWaterMeta from './DictWaterMeta'
import DictWaterData from './DictWaterData'

const DEFAULT_DICT_OPTIONS = {
  types: [],
}

/**
 * @classdesc 字典
 * @property {Object} label 标签对象，内部属性名为字典类型名称
 * @property {Object} dictWater 字段数组，内部属性名为字典类型名称
 * @property {Array.<DictWaterMeta>} _dictWaterMetas 字典元数据数组
 */
export default class DictWater {
  constructor() {
    this.owner = null
    this.deptId = null
    this.label = {}
    this.type = {}
  }

  init(options) {
    if (options instanceof Array) {
      options = { types: options }
    }
    const opts = mergeRecursive(DEFAULT_DICT_OPTIONS, options)
    if (opts.types === undefined) {
      throw new Error('need  types')
    }
    const ps = []
    this._dictWaterMetas = opts.types.map(t => DictWaterMeta.parse(t))
    this._dictWaterMetas.forEach(dictWaterMeta => {
      const type = dictWaterMeta.type
      Vue.set(this.label, type, {})
      Vue.set(this.type, type, [])
      if (dictWaterMeta.lazy) {
        return
      }
      ps.push(loadDict(this, dictWaterMeta))
    })
    return Promise.all(ps)
  }

  /**
   * 重新加载字典
   * @param {String} type 字典类型
   */
  reloadDict(type) {
    const dictWaterMeta = this._dictWaterMetas.find(e => e.type === type)
    if (dictWaterMeta === undefined) {
      return Promise.reject(`the dict meta of ${type} was not found`)
    }
    return loadDict(this, dictWaterMeta)
  }
}

/**
 * 加载字典
 * @param {DictWater} dictWater 字典
 * @param {DictWaterMeta} dictWaterMeta 字典元数据
 * @returns {Promise}
 */
function loadDict(dictWater, dictWaterMeta) {
  return dictWaterMeta.request(dictWaterMeta)
    .then(response => {
      const type = dictWaterMeta.type
      let dictWaters = dictWaterMeta.responseConverter(response, dictWaterMeta)
      if (!(dictWaters instanceof Array)) {
        console.error('the return of responseConverter must be Array.<DictWaterData>')
        dictWaters = []
      } else if (dictWaters.filter(d => d instanceof DictWaterData).length !== dictWaters.length) {
        console.error('the type of elements in dictWaters must be DictData')
        dictWaters = []
      }
      dictWater.type[type].splice(0, Number.MAX_SAFE_INTEGER, ...dictWaters)
      dictWaters.forEach(d => {
        Vue.set(dictWater.label[type], d.value, d.label)
      })
      return dictWaters
    })
}
