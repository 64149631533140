<template>
  <div class="flex container">
    <div v-for="(item, index) in data" :key="index">
      <p :class="item.status">{{ item.value }}</p>
      <p>{{ item.label }}</p>
    </div>
    <div class="my-container" v-if="isShow === true">
      <div>
        <p>{{ this.waterInfo.waterQualificationRate || 0 }}%</p>
        <p>水质达标率</p>
      </div>
      <div>
        <p>{{ this.waterInfo.pressureQualificationRate || 0 }}%</p>
        <p>压力达标率</p>
      </div>
      <div>
        <p>{{ this.waterInfo.leakRate || 0 }}%</p>
        <p>漏损率</p>
      </div>
    </div>
  </div>
</template>
<script>
import {getWaterData} from "@/api/survey/monitor/homePage";

export default {
  props: ['data', 'isShow'],
  data() {
    return {
      waterInfo: {}
    }
  },
  created() {
    this.getWaterData()
  },
  methods: {
    getWaterData() {
      getWaterData().then(res => {
        this.waterInfo = res.data
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

div.container {
  padding: 0 10px 25px 10px;
  justify-content: space-between;
  @include border-color2;

  p {
    margin: 0;
    text-align: center;

    &:first-child {
      font-family: YouSheBiaoTiHei;
      font-size: 24px;

      &.normal {
        color: #3FC2FF;
      }

      &.danger {
        color: #FC4747;
      }

      &.info {
        color: #C3C7D2;
      }
    }

    &:last-child {
      font-size: 14px;
      color: #fff;
    }
  }
}

.my-container {
  width: 100%;
  padding: 0 10px 25px 10px;
  display: flex;
  justify-content: space-between;

  div {
    padding-right: 50px;

    &:last-child {
      padding-right: 10px;
    }
  }

  p {
    margin: 0;
    text-align: center;

    &:first-child {
      font-family: YouSheBiaoTiHei;
      font-size: 24px;
      color: #3FC2FF;
    }

    &:last-child {
      font-size: 14px;
      color: #fff;
    }
  }

}
</style>
