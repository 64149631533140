<template>
  <div class="container">
    <div class="flex">
      <div class="title-icon-trend"></div>
      <div class="legend flex-grow">
        <span class="chart-white">曲线图</span>
        <!-- <span class="chart-blue">报警量趋势</span> -->
        <!-- <span class="chart-red">故障量趋势</span> -->
      </div>
      <!-- <div class="range">
        <span @click="selected = 'day'" :class="{ active: selected === 'day' }"
          >日</span
        >
        <span
          @click="selected = 'month'"
          :class="{ active: selected === 'month' }"
          >月</span
        >
        <span
          @click="selected = 'year'"
          :class="{ active: selected === 'year' }"
          >年</span
        >
      </div> -->
    </div>
    <div class="switch-tab">
      <span v-for="(item, index) in data" :key="index" :class="{active: index === legend}"
            @click="legend = index">{{ item.name }}</span>
    </div>
    <div id="fault-alarm-chart"></div>
  </div>
</template>

<script>
var myChart = null;
// 时间范围
const range = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
};

export default {
  props: ['data'],
  data() {
    return {
      range,
      legend: 0,
      selected: range.DAY,
      chartData: {},
    };
  },

  watch: {
    data: {
      deep: true,
      handler() {
        let {xAxis, data} = this.data[this.legend];
        this.setOption(xAxis, data)
      }
    },

    legend(val) {
      let {xAxis, data} = this.data[val];
      this.setOption(xAxis, data)
    },

    selected: {
      handler(val) {
        // this.setOption();
      },
      deep: true,
    },
  },

  methods: {
    setOption(xAxis = [], data = []) {
      let option = {
        grid: {
          containLabel: true,
          top: 10,
          left: 0,
          bottom: 0,
          right: 0,
        },
        xAxis: {
          data: xAxis,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            textBorderColor: "#0A385E",
            textBorderWidth: 2,
            color: "#fff",
          },
        },
        series: [
          {
            data,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#FF997D",
            },
            itemStyle: {
              opacity: 0,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },

  mounted() {
    var dom = document.getElementById("fault-alarm-chart");
    myChart = this.$echarts.init(dom);
    this.setOption();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.container {
  @include border-color2;
  padding-bottom: 25px;
  width: 366px;
}

.switch-tab {
  font-size: 14px;
  color: #ffaa00;
  margin-top: 10px;

  & span {
    cursor: pointer;
    padding: 5px 7px;
    border: 1px solid rgba(0, 0, 0, 0);

    &.active {
      color: #fff;
      border-radius: 20px;
      border-color: #ff997d;
    }
  }
}

.legend > span {
  margin: 0 12px;
  font-size: 14px;

  $legend-type: (
    "white": #fff,
    "blue": $chart-blue,
    "red": $chart-red,
  );
  @each $legend, $color in $legend-type {
    &.chart-#{$legend} {
      color: $color;

      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: $color;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}

.range span {
  @include metal-font-color;
  display: inline-block;
  line-height: 1;
  margin-left: 15px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  padding: 2px 3px;

  &.active {
    border-color: #005192;
  }
}

#fault-alarm-chart {
  width: 100%;
  height: 120px;
  margin-top: 35px;
}
</style>
