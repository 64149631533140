<template>
	<div
		:class="{ 'has-logo': showLogo, V5: isV5 }"
		:style="{
			background:
				settings.sideTheme === 'theme-dark'
					? variables.menuBackground
					: variables.menuLightBackground
		}"
	>
		<logo v-if="showLogo" :collapse="isCollapse" />
		<el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
			<el-menu
				:active-text-color="isV5 ? variables.menuColor : settings.theme"
				:background-color="
					settings.sideTheme === 'theme-dark'
						? isV5
							? variables.menuTransparentBackground
							: variables.menuBackground
						: variables.menuLightBackground
				"
				:collapse="isCollapse"
				:collapse-transition="false"
				:default-active="activeMenu"
				:text-color="
					settings.sideTheme === 'theme-dark'
						? variables.menuColor
						: variables.menuLightColor
				"
				:unique-opened="true"
				mode="vertical"
			>
				<sidebar-item
					v-for="(route, index) in sidebarRoutersNew"
					:key="route.path + index"
					:base-path="route.path"
					:item="route"
				/>
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variablesV5 from '@/assets/styles/variables-V5.module.scss'
import variables from '@/assets/styles/variables.module.scss'

export default {
	components: { SidebarItem, Logo },
	computed: {
		...mapState(['settings']),
		...mapGetters(['sidebarRouters', 'sidebar', 'sidebarRoutersNew']),
		activeMenu() {
			const route = this.$route
			const { meta, path } = route
			// if set path, the sidebar will highlight the path you set
			if (meta.activeMenu) {
				return meta.activeMenu
			}
			return path
		},
		showLogo() {
			return this.$store.state.settings.sidebarLogo
		},

		isCollapse() {
			return !this.sidebar.opened
		},
		variables() {
			return this.$route.path.indexOf('V5') > -1 ? variablesV5 : variables
		},
		isV5() {
			return this.$route.path.indexOf('V5') > -1
		}
	},

	mounted() {

  }
}
</script>
