<template>
  <div class="wrapper">
    <div class="head">
      <div class="orange"></div>
      <div class="title" :class="icon">
        {{ title }}
        <div class="count-num">
          <!-- <slot name="count"></slot> -->
          {{ countNew }}
        </div>
      </div>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'icon', 'count'],
  components: {},
  watch: {
    count: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          setTimeout(() => {
            let increaseTimer = setInterval(() => {
              if (this.countNew >= val) {
                clearInterval(increaseTimer);
              } else {
                this.countNew += 1;
              }
            }, 10);
          }, 1000);
        } else {
          this.countNew = '';
        }
      }
    }
  },
  data() {
    return {
      countNew: 0,
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/survey/djlr/common.scss';
@import '~@/assets/survey/djlr/color.scss';

.count-num {
  font-size: $fs24;
}

.wrapper {
  width: 450px;
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
  padding: 5px 0 20px
}

.head {
  @include flex-row;
  align-items: center;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;


  .orange {
    background-color: #ffa46d;
    height: 100%;
    width: 8px;
  }

  .title {
    @include background-image;
    flex-grow: 1;
    color: $font-color-green;
    font-size: $fs18;
    font-weight: bold;
    background-size: 40px;
    box-sizing: border-box;
    height: 100%;
    padding: 0 20px 0 50px;
    margin-left: 20px;
    border-bottom: 1px solid line-color-green(0.65);
    display: flex;
    justify-content: space-between;

    &.bar-chart {
      background-image: url(~@/assets/survey/image/page-image/djlr/bar-chart.png);
    }

    &.bell {
      background-image: url(~@/assets/survey/image/page-image/djlr/bell.png);
    }

    &.pie-chart {
      background-image: url(~@/assets/survey/image/page-image/djlr/pie-chart.png);
    }
  }
}

.body {
  padding: 0 10px;
}
</style>
