import request from '@/utils/request'

// 根据菜单id获取子菜单
export function getMenuListByMenuId(menuId) {
  return request({
    url: `/system/menu/favorites/child/${menuId}`,
    method: 'get',
  })
}

// 获取当前用户收藏的菜单
export function getCurrentUserCollectedMenus() {
  return request({
    url: `/system/menu/favorites/cur/user`,
    method: 'get',
  })
}

// 搜索获取当前用户收藏的菜单
export function getCurrentUserCollectedMenusByKeyWord(params) {
  return request({
    url: `/system/menu/favorites/cur/user`,
    method: 'get',
    params
  })
}

//用户批量新增/取消收藏菜单
export function batchOperationMenus(menuIds) {
  return request({
    url: `/system/menu/favorites/batch/${menuIds}`,
    method: 'post',
  })
}
