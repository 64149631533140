import Vue from "vue";
import DataWaterDict from "@/utils/DictWater";
import { getDictsWater } from "@/api/system/dict/data";

function install(deptId = '') {

  Vue.use(DataWaterDict, {
    metas: {
      "*": {
        labelField: "dictLabel",
        valueField: "dictValue",
        request(dictWaterMeta) {
          return getDictsWater(dictWaterMeta.type, deptId).then(res => res.data);
        }
      }
    }
  });
}

export default {
  install
};
