<template>
  <div class="right">
    <SubTitle text="消毒设备指标"></SubTitle>
    <AlarmTotal icon="warn" title="水质达标率" :total="data1 + '%'" color="gold"/>
    <AlarmTotal
      icon="water"
      title="设备正常运行率"
      :total="data2 + '%'"
      color="blue"
    />
    <TableData
      title="消毒设备管理"
      style="width: 100%; height: 220px; margin: 20px 0"
      search
    >
      <template v-slot:table>
        <el-table
          style="width: 100%"
          :data="deviceData"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column prop="id" width="50" label="ID"></el-table-column>
          <el-table-column
            prop="name"
            width="100"
            label="名称"
          ></el-table-column>
          <el-table-column
            prop="chlorineSurplus"
            width="50"
            label="余氯"
          ></el-table-column>
          <el-table-column prop="moveType" width="50" label="运行">
            <template slot-scope="scope">
              {{ moveTypeDict[scope.row.moveType] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="securityStatus"
            width="100"
            label="设备/安防状态"
          >
            <template slot-scope="scope">
              {{ disinfectStatusDict[scope.row.securityStatus] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmNum"
            width="70"
            label="历史报警"
          ></el-table-column>
        </el-table>
      </template>
    </TableData>
    <TableData
      title="实时报警数据"
      style="width: 100%; height: 220px; margin: 20px 0"
      search
    >
      <template v-slot:table>
        <el-table
          style="width: 100%"
          ref="autoScrollTable"
          :data="alarmList"
          size="small"
          width="100%"
          height="calc(100% - 40px)"
          class="el-table"
          header-row-class-name="header-row-class-name"
          header-cell-class-name="header-cell-class-name header-cell-class-name2"
          cell-class-name="cell-class-name"
          row-class-name="row-class-name"
        >
          <el-table-column
            prop="devNo"
            width="80"
            label="设备编号"
          ></el-table-column>
          <el-table-column
            prop="warnType"
            width="70"
            label="报警内容"
          >
            <template slot-scope="scope">
              {{ warnTypeDict[scope.row.warnType] || scope.row.warnType }}
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmType"
            width="90"
            label="类别"
          >
            <template slot-scope="scope">
              {{ alarmTypeDict[scope.row.alarmType] || scope.row.alarmType }}
            </template>
          </el-table-column>
          <el-table-column
            prop="importType"
            width="60"
            label="级别"
          >
            <template slot-scope="scope">
              {{ importTypeDict[scope.row.importType] || scope.row.importType }}
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmTime"
            width="70"
            label="报警时间"
          >
            <template slot-scope="scope">
              {{ scope.row.alarmTime.slice(5, 10) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="handleStatus"
            width="70"
            label="处理状态"
          >
            <template slot-scope="scope">
              {{ handleStatusDict[scope.row.handleStatus] || scope.row.handleStatus }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </TableData>
    <TableData
      title="监控视频"
      class="flex flex-col"
      style="width: 100%; margin: 20px 0"
    >
      <template v-slot:head>
        <span style="color: #fff; font-size: 14px">全部视频 ></span>
      </template>
      <template v-slot:table>
        <video style="margin: 20px 0 0" src="~@/assets/survey/xdsb.mp4" width="90%" controls></video>
      </template>
    </TableData>
  </div>
</template>
<script>
import SubTitle from "@/components/survey/SubTitle.vue";
import AlarmTotal from "@/components/survey/AlarmTotal.vue";
import TableData from "@/components/survey/TableData.vue";
import * as api from "@/api/survey/monitor/xdsb.js";
import autoScrollTable from '@/assets/survey/table_scroll.mixin.js'

export default {
  mixins: [autoScrollTable],
  components: {SubTitle, AlarmTotal, TableData},
  data() {
    return {
      data1: 0,
      data2: 0,
      deviceData: [],
      alarmList: [],
      moveTypeDict: {},
      disinfectStatusDict: {},
      alarmTypeDict: {},
      handleStatusDict: {},
      warnTypeDict: {},
      importTypeDict: {}
    };
  },

  methods: {
    // 字典
    getDict() {
      api.moveTypeDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.moveTypeDict, item.dictValue, item.dictLabel)
        });
      });

      api.disinfectStatusDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.disinfectStatusDict, item.dictValue, item.dictLabel);
        });
      });

      api.alarmTypeDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.alarmTypeDict, item.dictValue, item.dictLabel);
        });
      });

      api.handleStatusDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.handleStatusDict, item.dictValue, item.dictLabel);
        });
      });

      api.warnTypeDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.warnTypeDict, item.dictValue, item.dictLabel);
        });
      });

      api.importTypeDict().then((res) => {
        res.data.forEach((item) => {
          this.$set(this.importTypeDict, item.dictValue, item.dictLabel);
        });
      });
    },
  },

  mounted() {
    this.getDict();

    api.getDisinfectionData().then((res) => {
      this.data1 = res.data.data1;
      this.data2 = res.data.data2;
    });

    api.disinfectList().then((res) => {
      this.deviceData = res.rows;
    });

    api.alarmList().then((res) => {
      this.alarmList = res.rows;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.right {
  background-color: #000c35a6;
  backdrop-filter: blur(10px);
  @include side-bar-top-border-small;
  @include side-bar-bottom-border-small;
  width: 490px;
  position: fixed;
  top: 9vh;
  padding: 21px 24px;
  right: 30px;
  z-index: 5;
}
</style>
