<template>
  <div class="container">
    <div class="flex" style="width: 100%;">
      <div class="title-icon-warn"></div>
      <span class="title flex-grow">{{ title }}</span>
      <template v-if="search">
        <div class="search flex">
          <input class="flex-grow" type="text" @keyup.enter="onSearch" />
          <button class="flex-shrink" @click="onSearch">搜索</button>
        </div>
      </template>
      <slot name="head" v-else>
        <span class="time">04.01 09:17</span>
      </slot>
    </div>
    <slot name="table"></slot>
    <div class="blur-border"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    search: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchValue: ''
    }
  },

  methods: {
    onSearch() {
      this.$emit('search', this.searchValue)
    }
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.container {
  @include border-color2;
  overflow-y: hidden;

  $table-alarm-icon: "important", "normal", "urgency";
  @each $icon in $table-alarm-icon {
    span.table-alarm-icon {
      vertical-align: middle;
      display: inline-block;
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      &.table-alarm-#{$icon} {
        background-image: url("~@/assets/survey/image/table-alarm-#{$icon}.png");
      }
    }
  }
}

.title {
  color: #fff;
  font-size: 14px;
  margin: 0 0 0 13px;
  text-align: left;
}

.time {
  @include metal-font-color;
}

.blur-border {
  height: 20px;
  position: absolute;
  bottom: -2px;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, #000c34 60%);
}

@mixin el-table-cell {
  padding: 4px 0;
  color: #acacac;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  > div {
    line-height: 1.5;
    padding-left: 0;
    padding-right: 0;
  }
}

::v-deep .el-table {
  margin-top: 18px;
  @include el-table-opacity;

  .el-table__body-wrapper {
    @include scrollbar;
  }

  th.header-cell-class-name,
  td.cell-class-name {
    @include el-table-cell;
  }
}

.search {
  overflow: hidden;
  width: 206px;
  height: 29px;
  border-radius: 25px;
  background: #1b2d7b;
  padding-left: 10px;
  input {
    color: #fff;
    width: 0px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      outline: none;
    }
  }
  button {
    cursor: pointer;
    width: 53px;
    height: 29px;
    border-radius: 25px;
    border: none;
    color: #fff;
    background: #3449a4;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
