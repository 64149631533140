<template>
  <div class="container">
    <div id="tag-cloud-pipe"></div>
  </div>
</template>

<script>
import {getTagCloudPipeAlarm} from '@/api/survey/monitor/gwztPage'

const TagCloudPipe = require('TagCloud');

export default {
  created() {
    this.getTagCloudPipeData()
  },
  data() {
    return {
      pipeData: []
    }
  },
  methods: {
    getTagCloudPipeData() {
      getTagCloudPipeAlarm().then(res => {
        this.pipeData = res.data.map(item => `${item['name']}：${item['value']}`)
      })
    },
    draw() {
      TagCloudPipe('#tag-cloud-pipe', this.pipeData, {
        useContainerInlineStyles: false,
        containerClass: 'tag-cloud-pipe-container',
        itemClass: 'tag-cloud-pipe',
        maxSpeed: 'slow'
      });
    }
  },
  watch: {
    pipeData(e) {
      this.$nextTick(() => {
        this.pipeData = e
        this.draw()
      })
    }
  },
  mounted() {

  }

};
</script>

<style scoped lang="scss">
@import "~@/assets/survey/style.scss";

::v-deep .tag-cloud-pipe {
  @include metal-font-color;
}

::v-deep .tag-cloud-pipe-container {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 135px;
  padding-bottom: 25px;
  @include border-color2;

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
