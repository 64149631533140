import request from '@/utils/request'

// 登录方法
export function login({tenantId, username, password, code, uuid} = {}) {
  const data = {
    tenantId,
    username: username.trim(),
    password,
    code,
    uuid
  }
  return request({
    url: '/system/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}


// 获取微信登录二维码
export function getQRCode(params) {
  return request({
    url: '/system/wxp/qrcode/generate',
    method: 'get',
    params
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/system/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/system/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 获取租户列表
export function getTenantList(params){
  return request({
    url: '/system/filter/tenants',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  });
}

// 根据租户ID获取租户详细信息
export function getTenantInfoByTenantId(tenantId){
  return request({
    url: `/system/tenant/tenantId/${tenantId}`,
    method: 'get'
  });
}


// 微信扫码登录
export function wxQrcodeLogin(data){
  return request({
    url: `/system/wxQrcodeLogin`,
    method: 'post',
    data
  });
}

// 短信验证码登录
export function msgLogin(data){
  return request({
    url: `/system/loginBySms`,
    method: 'post',
    data
  });
}

// 公众号端执行账户关联操作接口
export function PublicLogin(data, token){
  return request({
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `/system/user/account/wxp/relate`,
    method: 'post',
    data
  });
}

// 公众号端获取当前登陆微信账号的关联信息接口
export function showPublicLoginInfo(token){
  return request({
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `/system/user/account/wxp/info`,
    method: 'get',
  });
}
