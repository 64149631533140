import DictWaterOptions from './DictWaterOptions'
import DictWaterData from './DictWaterData'

export default function(dictWater, dictWaterMeta) {
  const label = determineDictField(dictWater, dictWaterMeta.labelField, ...DictWaterOptions.DEFAULT_LABEL_FIELDS)
  const value = determineDictField(dictWater, dictWaterMeta.valueField, ...DictWaterOptions.DEFAULT_VALUE_FIELDS)
  return new DictWaterData(dictWater[label], dictWater[value], dictWater)
}

/**
 * 确定字典字段
 * @param {DictWaterData} dictWater
 * @param  {...String} fields
 */
function determineDictField(dictWater, ...fields) {
  return fields.find(f => Object.prototype.hasOwnProperty.call(dictWater, f))
}
