<template>
  <div>
    <svg-icon icon-class="documentation" @click="goto"/>
  </div>
</template>

<script>
export default {
  name: 'JingyuanDoc',
  data() {
    return {}
  },
  props: {
    url: {
      type: String,
      default: '#'
    }
  },

  methods: {
    goto() {
      window.open(this.url)
    }
  }
}
</script>
