import request from '@/utils/request'

//数值查询
export function getWellInfoData() {
  return request({
    url: '/survey/monitor/well/getWellData',
    method: 'get',
  })
}

//水源井报警内容统计图
export function getWellAlarmStatics() {
  return request({
    url: '/survey/monitor/well/alarmStatics',
    method: 'get',
  })
}

//获取实时报警数据表格
export function getWellAlarmList(params) {
  return request({
    url: '/survey/monitor/well/alarmList',
    method: 'get',
    query: params
  })
}

//获取水源井健康数据表格
export function getWellQualityList(params) {
  return request({
    url: '/survey/monitor/well/wellQualityList',
    method: 'get',
    query: params
  })
}

//点位
export function sourceWellPointList() {
  return request({
    url: "/survey/monitor/mapPoint/sourceWellPointList",
    method: "get",
  });
}

//水源井趋势统计
export function sourceWellPointStatistic(sourceWellId) {
  return request({
    url: `/survey/monitor/mapPoint/sourceWellPointStatistic/${sourceWellId}`,
    method: "get",
  });
}





