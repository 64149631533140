<template>
  <div id="home-main">
    <!-- <div class="header">
      <div class="weather">
        <img src="~@/assets/image/page-image/djlr/cloud.png" alt="">
        {{city}}&nbsp;&nbsp;&nbsp; {{temperature}} ℃<span class="hb">&nbsp;&nbsp;&nbsp; {{weather}}</span>

      </div>
      <div class="calendar">
        <img src="~@/assets/image/page-image/djlr/calendar.png" alt="">
        {{date.y}}<span class="hb">&nbsp;年&nbsp;</span>{{date.m}}<span class="hb">&nbsp;月&nbsp;</span>{{date.d}}<span class="hb">&nbsp;日&nbsp;</span>
        &nbsp;&nbsp;&nbsp;{{date.w}}
      </div>
      <span>南安独居老人监控平台</span>
    </div> -->
    <div id="map_container"></div>
    <div class="select-user">
      <div class="su-icon"></div>
      <input :value="selectedDeptText" readonly type="text" placeholder="请选择用户">
      <div class="user-list">
        <el-select v-model="selectedDetpId" size="small" ref="deptSelect" @change="deptChange">
          <el-option key="-1" label="所有" value=""></el-option>
          <el-option
            v-for="(item, index) in deptData"
            :key="index"
            :label="item.deptName"
            :value="item.deptId">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="left">
      <Wrapper title="今日上传数据" icon="bell" :count="tableData.length">
        <Table :data="tableData" :columns="tableColumns"></Table>
      </Wrapper>
      <Wrapper title="异常告警统计" icon="bar-chart" :count="tableData2.length">
        <Table :data="tableData2" :columns="tableColumns2"></Table>
      </Wrapper>
    </div>
    <div class="center">
      <div>
        <div><span>{{ userCountNew }}</span>户</div>
        <p>用户总数</p>
      </div>
      <div>
        <div><span>{{ subCountNew }}</span>户</div>
        <p>订阅用户总数</p>
      </div>
    </div>
    <div class="right">
      <Wrapper title="异常统计" icon="pie-chart">
        <div class="pie-module">
          <div>
            <span class="title">异常处理统计</span>
            <div class="target">
              <div>处理中：<span>{{ chartData.ing }}</span>&nbsp;个</div>
              <div>已完成：<span>{{ chartData.done }}</span>&nbsp;个</div>
              <div>未处理：<span>{{ chartData.will }}</span>&nbsp;个</div>
            </div>
          </div>
          <div class="chart-wrapper" ref="pieChart"></div>
        </div>
        <div class="pie-module">
          <div>
            <span class="title">用水异常统计</span>
            <div class="target">
              <div>用水突增：<span>6</span>&nbsp;个</div>
              <div>用水突减：<span>9</span>&nbsp;个</div>
              <div>用水为零：<span>3</span>&nbsp;个</div>
            </div>
          </div>
          <div class="chart-wrapper" ref="pieChart2"></div>
        </div>
        <div class="pie-module">
          <div>
            <span class="title">异常短信发送统计</span>
            <div class="target">
              <div>今日已发送：<span>0</span>&nbsp;个</div>
              <div>本月已发送：<span>0</span>&nbsp;个</div>
              <div>本年已发送：<span>0</span>&nbsp;个</div>
            </div>
          </div>
          <div class="chart-wrapper" ref="pieChart3"></div>
        </div>
      </Wrapper>
    </div>
  </div>
</template>

<script>
import mapMixin from './map.mixin'
import Wrapper from '@/components/survey/wrapper.vue';
import Table from '@/components/survey/table.vue';
import {pieOption, pieOption2, pieOption3} from './chartOption'
import {
  guestNum,
  deptList,
  guestList,
  lowUseList,
  ingList,
  doneList,
  willList,
  lowUseNum,
  nbTakeList,
} from '@/api/survey/monitor/api.js'

export default {
  name: "Home",

  mixins: [mapMixin],

  components: {
    Wrapper,
    Table
  },

  watch: {},

  data() {
    return {
      selectedDetpId: '', // 选中的部门id
      deptData: [],
      userCount: 102,
      userCountNew: 0,
      subCount: 186,
      subCountNew: 0,
      userListShow: false,
      weather: '',
      temperature: '',
      city: '',
      tableColumns: ['用户名', '表地址', '指数', '上报时间', '信号'],
      tableColumns2: ['用户名', '表地址', '告警值', '用量', '上传日期'],
      tableData: [],
      tableData2: [],
      chartData: {
        ing: 0,
        done: 0,
        will: 0
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeData: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
        }, {
          label: '二级 2-2',
        }]
      }, {
        label: '一级 3',
        children: [{
          label: '二级 3-1',
        }, {
          label: '二级 3-2',
        }]
      }],
    }
  },

  async mounted() {
    this.getWeather();
    await this.initMap();
    // 异常处理统计
    var pieChart = this.$echarts.init(this.$refs.pieChart)
    var [ing, done, will] = await this.getExceptionHandling();
    this.chartData.ing = ing.data;
    this.chartData.done = done.data;
    this.chartData.will = will.data;
    pieOption.series[0].data[0].value = ing.data;
    pieOption.series[0].data[1].value = done.data;
    pieOption.series[0].data[2].value = will.data;
    pieChart.setOption(pieOption);

    // 用水异常统计
    var pieChart2 = this.$echarts.init(this.$refs.pieChart2)
    var lowUse = await this.getLowUseNum();
    pieOption2.series[0].data[2].value = lowUse.data;
    pieChart2.setOption(pieOption2);
    // 异常短信发送统计
    var pieChart3 = this.$echarts.init(this.$refs.pieChart3)
    pieChart3.setOption(pieOption3);

    this.getDeptList();
    this.getNbTake();
    this.getLowUse();
    // 获取用户数
    this.userCountNew = await guestNum().then(res => res.data)
  },
  methods: {
    // 低用水量
    getLowUseNum() {
      return lowUseNum();
    },

    // 获取异常处理
    async getExceptionHandling() {
      return Promise.all([
        ingList(),
        doneList(),
        willList()
      ]);
    },

    getLowUse() {
      lowUseList().then(res => {
        this.tableData2 = res.rows.map(item => {
          return [
            item.guestName,
            item.meterAddr,
            item.pushThreshold,
            item.amount,
            item.takeDate
          ];
        });
      });
    },

    getNbTake() {
      nbTakeList().then(res => {
        this.tableData = res.rows.map(item => {
          return [
            item.guestName,
            item.meterAddr,
            item.readNumber,
            item.workerTime,
            item.csq
          ];
        });
      });
    },

    deptChange(deptId) {
      this.getUsers(deptId);
    },

    // 获取部门列表
    getDeptList() {
      deptList().then(res => {
        this.deptData = res.data.filter(d => d != null);
        this.getUsers(this.selectedDetpId);
      })
    },

    // 根据部门id获取用户列表
    getUsers(deptId) {
      guestList({deptId}).then(res => {
        var points = res.data.map(item => {
          var [lng, lat] = item.point.split(',');
          return {
            abnormal: item.state === 0,
            params: item,
            point: {lng, lat}
          }
        });
        this.createMarker(points);
      })
    },

    numchange(key) {
      let increaseTimer = setInterval(() => {
        this[`${key}New`] += 1;
        if (this[`${key}New`] >= this[key]) {
          clearInterval(increaseTimer);
        }
      }, 10);
    },

    getTableData() {
      setInterval(() => {
        var newTr = [
          `10#-${(Math.random() * 10000).toFixed(0)}`,
          (Math.random() * 1000000000000).toFixed(0),
          (Math.random() * 10).toFixed(1),
          '2021-10-10 16:30',
          (Math.random() * 100).toFixed(0)
        ];
        this.tableData.splice(0, 0, newTr)
      }, 4000);

      setInterval(() => {
        var newTr = [
          `10#-${(Math.random() * 10000).toFixed(0)}`,
          (Math.random() * 1000000000000).toFixed(0),
          (Math.random() * 10).toFixed(1),
          '2021-10-10 16:30',
          (Math.random() * 100).toFixed(0)
        ];
        this.tableData2.splice(0, 0, newTr)
      }, 10000);
    },

    getWeather() {
      //加载天气查询插件
      AMap.plugin('AMap.Weather', () => {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('南安市', (err, data) => {
          this.city = data.city;
          this.temperature = data.temperature;
          this.weather = data.weather;
        });
      });
    }
  },
  computed: {
    selectedDeptText() {
      var item = this.deptData.find(item => item.deptId === this.selectedDetpId);
      return item ? item.deptName : '';
    },

    date() {
      var t = this.moment();
      return {
        y: t.format('YYYY'),
        m: t.format('M'),
        d: t.format('D'),
        w: t.format('dddd')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/djlr/color.scss";
@import "~@/assets/survey/djlr/common.scss";

.header {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 70px;
  line-height: 60px;
  background-color: rgba(7, 25, 46, 0.8);
  padding: 0 50px;
  box-sizing: border-box;

  > span {
    display: inline-block;
    line-height: 70px;
    z-index: -1;
    color: $font-color-th-blue;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: .2em;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }

  > div {
    display: flex;
    align-items: center;
    color: #fff;

    .hb {
      color: #87edff;
    }
  }

  .weather {
    img {
      margin-right: 10px;
      width: 34px;
    }
  }

  .calendar {
    img {
      margin-right: 10px;
      width: 24px;
    }

    .fs {
      cursor: pointer;
      width: 30px;
      margin-left: 30px;
    }
  }
}

#map_container {
  background-color: #000;
  background-image: none !important;
  flex-shrink: 0;
  width: 100vw;
  height: 100vh;
}

::v-deep .BMap_bubble_pop {
  border-color: line-color-green(0.3) !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
  border-radius: 0px !important;

  .head {
    background-size: 30px;
    background-position: left 20px;
    color: $font-color-th-blue;
    margin-left: 10px;
    border-bottom: 1px solid line-color-green(1);
    background-repeat: no-repeat;
    background-image: url(~@/assets/survey/image/page-image/djlr/building.png);
    background-size: 30px;
    height: 30px;
    padding: 20px 0 10px 40px;
    line-height: 30px;
  }

  p {
    margin-left: 15px;
    color: $font-color-th-blue;

    &.abnormal {
      color: #ff77a7;
    }
  }
}

::v-deep .BMap_bubble_top {
  display: none !important;;
}

::v-deep .BMap_bubble_bottom + img {
  display: none !important;;
}

::v-deep .BMap_bubble_content {
  height: auto !important;;
}

.left {
  position: fixed;
  z-index: 5;
  top: 150px;
  left: 30px;

  > div:not(:first-child) {
    margin-top: 10px;
  }
}

.right {
  position: fixed;
  z-index: 5;
  top: 150px;
  right: 30px;

  > div:not(:first-child) {
    margin-top: 20px;
  }
}

.pie-module {
  @include flex-row;
  margin: 0 20px;
  padding-bottom: 26px;

  &:not(:last-child) {
    border-bottom: 1px solid line-color-green(0.65);
    margin-bottom: 30px;
  }

  .title {
    display: inline-block;
    padding-bottom: 5px;
    margin: 0 0 25px 15px;
    font-size: $fs14;
    border-bottom: 1px solid line-color-green(0.65);
    color: $font-color-th-blue;
  }

  .chart-wrapper {
    flex-grow: 1;
  }
}

.target {
  > div {
    &::before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid rgba(0, 0, 0, 0);
      border-left: 7px solid #ffa46d;
      margin-right: 10px;
    }

    &:not(:first-child) {
      margin-top: 18px;
    }

    span {
      font-weight: bold;
      font-size: $fs18;
      color: $font-color-green;
    }

    white-space: nowrap;
    color: $font-color-th-blue;
    font-size: $fs14;
    line-height: 20px;
    @include background-image;
  }
}

.center {
  position: fixed;
  top: 100px;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  color: $font-color-th-blue;
  font-size: $fs18;

  > div {
    &:not(:first-child) {
      margin-left: 130px;
    }

    > p {
      text-align: justify;
      text-align-last: justify;
      margin-top: 10px;
    }

    > div {
      border-left: 2px solid $font-color-th-blue;
      border-right: 2px solid $font-color-th-blue;
      border-radius: 5px;
      text-align: center;
      line-height: 1;
      width: 160px;
      font-family: Bahnschrift;

      > span {
        margin-right: 2px;
        color: $font-color-green;
        font-size: $fs44;
      }
    }
  }
}

.select-user {
  position: fixed;
  top: 100px;
  left: 30px;
  z-index: 6;
  @include flex-row;

  .su-icon {
    width: 30px;
    height: 30px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(~@/assets/survey/image/page-image/djlr/su-icon.png);
    background-color: #0070c0;
  }

  input {
    background-color: #9deeff;
    border: none;
    border-radius: 0;
    margin-left: 10px;
    text-indent: 8px;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .user-list {
    width: 173px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }

  ::v-deep .el-input__inner {
    background-color: #9deeff;
  }

  ::v-deep .el-tree-node__content:hover {
  }
}
</style>
