
import request from '@/utils/request'

/*合同模板上传地址*/
export const templateContractsUrl =  `/revenue/api/contract/template/upload`

/*获取合同模板列表*/
export function getContractsTemplate(data) {

  const { pageSize, pageNum, ...rest } = data || {}

  return request({
    url: `/revenue/api/contract/template/list?pageSize=${pageSize}&pageNum=${pageNum}`,
    method: 'post',
    data: rest
  })
}

/*获取合同列表*/
export function getContractsList(data) {

  const { pageSize, pageNum, ...rest } = data || {}

  return request({
    url: `/revenue/api/contract/signs/page?pageSize=${pageSize}&pageNum=${pageNum}`,
    method: 'post',
    data: rest
  })
}

/*获取电子合同模板类型字典列表*/
export function getContractsDictType(isAll = false) {
  let url = isAll ? `/revenue/api/contract/dict/type?listType=0`: `/revenue/api/contract/dict/type`
  return request({
    url,
    method: 'get',
  })
}

/*获取电子合同模板状态字典列表*/
export function getContractsDictTempStatus(isAll = false) {
  let url = isAll ? `/revenue/api/contract/dict/temp/status?listType=0`: `/revenue/api/contract/dict/temp/status`
  return request({
    url,
    method: 'get',
  })
}

/*获取电子合同模板状态字典列表(选择)*/
export function getContractsDictTempStatusSelect() {
  return request({
    url: `/revenue/api/contract/dict/change/temp/status`,
    method: 'get',
  })
}

/*通过主键批量删除数据*/
export function deleteContractsByIds(data) {

  return request({
    url: `/revenue/api/contract/template`,
    method: 'delete',
    data
  })
}

/*通过主键查询模板基础信息*/
export function getTemplateContractsInfoByTemplateId(templateId) {

  return request({
    url: `/revenue/api/contract/template/info/${templateId}`,
    method: 'get',
  })
}

/*通过主合同id查询模板基础信息*/
export function getContractsInfoBySignedId(signedId) {

  return request({
    url: `/revenue/api/contract/signs/${signedId}`,
    method: 'get',
  })
}

/*新增或修改合同模板基础信息*/
export function addOrUpdateTemplateContractsInfo(data) {

  return request({
    url: `/revenue/api/contract/template/base`,
    method: 'post',
    data
  })
}

/*编辑模板文件参数信息*/
export function updateTemplateContractsParams(data) {

  return request({
    url: `/revenue/api/contract/template/file/param`,
    method: 'put',
    data
  })
}

/*变更模板文件状态信息*/
export function changeTemplateContractsStatus(data) {

  return request({
    url: `/revenue/api/contract/template/file/status`,
    method: 'put',
    data
  })
}

/*新增模板参数下拉字典*/
export function addParamsDicts() {

  return request({
    url: `/revenue/api/contract/dict/property/format`,
    method: 'get',
  })
}

/*获取合同签订状态字典*/
export function getContractsDictStatus() {

  return request({
    url: `/revenue/api/contract/dict/status?listType=0`,
    method: 'get',
  })
}

