import { render, staticRenderFns } from "./left.vue?vue&type=template&id=6fd6e6a9&scoped=true"
import script from "./left.vue?vue&type=script&lang=js"
export * from "./left.vue?vue&type=script&lang=js"
import style0 from "./left.vue?vue&type=style&index=0&id=6fd6e6a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd6e6a9",
  null
  
)

export default component.exports