<template>
  <div class="gwzt">
    <SyjLeft id="left"/>
    <div class="map">
      <Map
        :infoWindowImage="infoWindowImage"
        :infoWindowRect="{ width: 420, height: 0 }"
        :markers="markers"
        @clickMarker="clickMarker"
      >
        <template v-slot:infoWindow>
          <div class="info-window">
            <SubTitle text="良乡监测点" light></SubTitle>
            <div class="info-table">
              <table>
                <tr>
                  <td>
                    设备编号：2251021212
                  </td>
                  <td>
                    安装时间：{{ (infoWindowData.createTime || '').slice(0, 11) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    设备名称：{{ infoWindowData.name }}
                  </td>
                  <td>
                    设备状态：{{ securityStatus[infoWindowData.isAlarm] }}
                  </td>
                </tr>
              </table>
              <LineChart2 style="margin: 20px" :data="lineData"/>
            </div>
          </div>
        </template>
      </Map>
    </div>
    <SyjRight id="right"/>
  </div>
</template>
<script>
import Map from "@/components/survey/BMap.vue";
import SyjLeft from "./SyjLeft.vue";
import SyjRight from "./SyjRight.vue";
import infoWindowImage from "@/assets/survey/image/page-image/syj/window.png";
import * as api from "@/api/survey/monitor/syjPage.js";
import SubTitle from "@/components/survey/SubTitle.vue";
import LineChart2 from "@/components/survey/LineChart2.vue";

var lineData = [
  {
    field: 'ph',
    name: 'PH',
    xAxis: [],
    data: []
  },
  {
    field: 'conductivity',
    name: '电导率',
    xAxis: [],
    data: []
  },
  {
    field: 'turbidity',
    name: '浊度',
    xAxis: [],
    data: []
  },
  {
    field: 'dissolvedOxygen',
    name: '溶解氧',
    xAxis: [],
    data: []
  },
  {
    field: 'temperature',
    name: '温度',
    xAxis: [],
    data: []
  }
];

export default {
  data() {
    return {
      infoWindowImage,
      markers: [],
      infoWindowData: {},
      securityStatus: ['正常', '报警'],
      lineData
    };
  },
  components: {Map, SyjLeft, SyjRight, SubTitle, LineChart2},
  methods: {
    clickMarker(e) {
      this.infoWindowData = e;
      // 获取折线图数据
      api.sourceWellPointStatistic(e.id).then(res => {
        this.lineData.forEach((line, index) => {
          let xAxis = res.data.map(v => v.takeTime)
          let data = res.data.map(v => v[line.field])
          this.$set(this.lineData[index], 'xAxis', xAxis)
          this.$set(this.lineData[index], 'data', data)
        })
      })
    },
  },
  mounted() {
    api.sourceWellPointList().then((res) => {
      this.markers = res.rows;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/info_window.scss";

.map {
  z-index: 0;
  width: 100vw;
  height: 100vh;
}

#left {
  z-index: 11;
  position: fixed;
  left: 20px;
  top: 25px;
}

#right {
  z-index: 11;
  position: fixed;
  right: 20px;
  top: 0px;
}
</style>
