<template>
	<div
		:class="{ collapse: collapse }"
		:style="{
			background:
				sideTheme === 'theme-dark'
					? isV5
						? variables.menuTransparentBackground
						: variables.menuBackground
					: variables.menuLightBackground
		}"
		class="sidebar-logo-container"
	>
		<transition name="sidebarLogoFade">
			<router-link
				v-if="collapse"
				key="collapse"
				class="sidebar-logo-link"
				to="/"
			>
				<img v-if="logo" :src="logo" class="sidebar-logo" />
				<h1
					v-else
					:style="{
						color:
							sideTheme === 'theme-dark'
								? variables.logoTitleColor
								: variables.logoLightTitleColor
					}"
					class="sidebar-title"
				>
					{{ title }}
				</h1>
			</router-link>
			<router-link v-else key="expand" class="sidebar-logo-link" to="/">
				<img v-if="logo" :src="logo" class="sidebar-logo" />
				<h1
					:style="{
						color:
							sideTheme === 'theme-dark'
								? variables.logoTitleColor
								: variables.logoLightTitleColor
					}"
					class="sidebar-title"
				>
					{{ title }}
				</h1>
			</router-link>
		</transition>
	</div>
</template>

<script>
import variables from '@/assets/styles/variables.module.scss'
import variablesV5 from '@/assets/styles/variables-V5.module.scss'

export default {
	name: 'SidebarLogo',
	props: {
		collapse: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		variables() {
			return this.$route.path.indexOf('V5') > -1 ? variablesV5 : variables
		},
		isV5() {
			return this.$route.path.indexOf('V5') > -1
		},
		sideTheme() {
			return this.$store.state.settings.sideTheme
		},

		logo() {
			return JSON.parse(localStorage.getItem('pagesData'))?.companyLogo
		},

		title() {
			return JSON.parse(localStorage.getItem('pagesData'))?.homeTitle
		}
	},
	data() {
		return {}
	}
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
	transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
	opacity: 0;
}

.sidebar-logo-container {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	text-align: center;
	overflow: hidden;

	& .sidebar-logo-link {
		height: 100%;
		width: 100%;

		& .sidebar-logo {
			width: 32px;
			height: 32px;
			vertical-align: middle;
			margin-right: 12px;
		}

		& .sidebar-title {
			display: inline-block;
			margin: 0;
			color: #fff;
			font-weight: 600;
			line-height: 50px;
			font-size: 14px;
			font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
			vertical-align: middle;
		}
	}

	&.collapse {
		.sidebar-logo {
			margin-right: 0px;
		}
	}
}
</style>
