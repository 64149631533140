import DictWater from './DictWater'
import { mergeOptions } from './DictWaterOptions'

export default function(Vue, options) {
  mergeOptions(options)
  Vue.mixin({
    data() {
      if (this.$options.dictWaters === undefined || this.$options.dictWaters === null) {
        return {}
      }
      const dictWater = new DictWater()
      dictWater.owner = this
      return {
        dictWater
      }
    },
    created() {
      if (!(this.dictWater instanceof DictWater)) {
        return
      }
      options.onCreated && options.onCreated(this.dictWater)
      this.dictWater.init(this.$options.dictWaters).then(() => {
        options.onReady && options.onReady(this.dictWater)
        this.$nextTick(() => {
          this.$emit('dictReady', this.dictWater)
          if (this.$options.methods && this.$options.methods.onDictReady instanceof Function) {
            this.$options.methods.onDictReady.call(this, this.dictWater)
          }
        })
      })
    },
  })
}
