<template>
  <div class="my-filter-complex">
    <div>
      <span style="color: #909aaa; font-size: 14px; line-height: 22px; margin-bottom: 10px;">筛选</span>
    </div>

    <div class="my-fc-type">
      <vxe-select v-model="option.data.type">
        <vxe-option v-for="item in optionsList" :key="item.value" :value="item.value" :label="item.label" />
      </vxe-select>
    </div>
    <div class="my-fc-name">
      <el-input v-model="option.data.name" type="text" placeholder="请输入" @input="changeOptionEvent()" />
    </div>
    <div class="my-fc-footer">
      <vxe-button @click="resetEvent">清除筛选条件</vxe-button>
      <vxe-button status="primary" @click="confirmEvent">确认</vxe-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSelect',
  props: {
    params: Object
  },
  data () {
    return {
      column: null,
      option: null,
      optionsList: [
        {
          value: 'has',
          label: '包含'
        },
        {
          value: 'eq',
          label: '等于'
        }
      ]
    }
  },

  created () {
    this.load()
  },

  methods: {
    load () {
      const { column } = this.params
      const option = column.filters[0]
      this.column = column
      this.option = option
    },

    changeOptionEvent () {
      const { params, option } = this
      const { $panel } = params
      const checked = !!option.data.name
      $panel.changeOption(null, checked, option)
    },

    confirmEvent () {
      const { $panel } = this.params
      $panel.confirmFilter()
    },

    resetEvent () {
      const { $panel } = this.params
      $panel.resetFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-filter-complex {
  width: 300px;
  padding: 22px;

  .my-fc-type {
    padding: 8px 0;

    ::v-deep .vxe-input--inner {
      padding: 0 1em;
    }
  }

  .my-fc-footer {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
