<template>
  <ul class="children-list">
    <li v-for="(item, index) in data" :key=item.menuId class="children-item">
      <div class='children-item-title' @click="toggle(item)">
        <span>{{ item.menuName }}</span>
        <span v-if="item['canFavorite']" style="margin-left: 4px;">
        <svg-icon :fill-color="item['hasFavorite'] ? '#53c8df' : '#cccccc'"
                  :icon-class="item['hasFavorite'] ? 'f-collected':'f-unCollected'"/>
        </span>
      </div>
      <ChildrenComponent v-if="!item['canFavorite']" :data=item.children @send-menuId="sendMenuId"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ChildrenComponent',

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    this.$on('send-menuId', this.sendMenuId) // 监听从子组件传来的事件
  },
  beforeDestroy() {
    this.$off('send-menuId', this.sendMenuId) // 在销毁前移除监听器以避免内存泄漏
  },

  methods: {
    toggle(item) {
      const {menuId, canFavorite} = item || {}
      if (!canFavorite) return
      item.hasFavorite = !item.hasFavorite
      this.$emit('send-menuId', {
        menuId,
        hasFavorite: item.hasFavorite
      })
    },

    sendMenuId({menuId, hasFavorite}) {
      this.$emit('send-menuId-parent', {
        menuId,
        hasFavorite
      })
    },
  },
}
</script>


