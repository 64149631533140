import request from '@/utils/request'

// 消毒设备总数量，报警总数
export function getAlarmTotal() {
  return request({
    url: '/survey/monitor/disinfect/getAlarmTotal',
    method: 'get',
  })
}

// 消毒设备数量统计
export function disinfectCount() {
  return request({
    url: '/survey/monitor/disinfect/disinfectCount',
    method: 'get',
  })
}

// 报警内容统计
export function alarmStatics() {
  return request({
    url: '/survey/monitor/disinfect/alarmStatics',
    method: 'get',
  })
}

// 故障内容统计
export function faultStatics() {
  return request({
    url: '/survey/monitor/disinfect/faultStatics',
    method: 'get',
  })
}

// 数值查询
export function getDisinfectionData() {
  return request({
    url: '/survey/monitor/disinfect/getDisinfectionData',
    method: 'get',
  })
}

// 消毒设备列表查询
export function disinfectList() {
  return request({
    url: '/survey/monitor/disinfect/disinfectList',
    method: 'get',
  })
}

// 获取实时报警数据表格
export function alarmList() {
  return request({
    url: '/survey/monitor/disinfect/alarmList',
    method: 'get',
  })
}

// 数据字典：消毒设备运行方式
export function moveTypeDict() {
  return request({
    url: '/survey/dict/monitor/moveTypeDict',
    method: 'get',
  })
}

// 数据字典：消毒设备状态
export function disinfectStatusDict() {
  return request({
    url: '/survey/dict/monitor/disinfectStatusDict',
    method: 'get',
  })
}

// 数据字典：查询报警类型
export function alarmTypeDict() {
  return request({
    url: '/survey/dict/monitor/alarmTypeDict',
    method: 'get',
  })
}

// 数据字典：查询处理状态
export function handleStatusDict() {
  return request({
    url: '/survey/dict/monitor/handleStatusDict',
    method: 'get',
  })
}

// 数据字典：查询报警内容
export function warnTypeDict() {
  return request({
    url: '/survey/dict/monitor/warnTypeDict',
    method: 'get',
  })
}

// 数据字典：查询报警级别
export function importTypeDict() {
  return request({
    url: '/survey/dict/monitor/importTypeDict',
    method: 'get',
  })
}

// 获取点位
export function disinfectionPointList() {
  return request({
    url: "/survey/monitor/mapPoint/disinfectionPointList",
    method: "get",
  });
}

// 获取趋势折线图
export function disinfectionPointStatistic(id) {
  return request({
    url: `/survey/monitor/mapPoint/disinfectionPointStatistic/${id}`,
    method: "get",
  });
}
