import Vue from 'vue'
import VXETable from 'vxe-table'

import FilterSelect from '../components/renderer/FilterSelect.vue'

export const filterRenderer = () => {
  Vue.component(FilterSelect.name, FilterSelect)

// 创建一个条件的渲染器
  VXETable.renderer.add('FilterSelect', {
    className: 'filter-select',
    // 不显示底部按钮，使用自定义的按钮
    isFooter: false, // 筛选模板
    renderFilter(h, renderOpts, params) {
      return [<filter-select params={params}></filter-select>]
    }, // 重置数据方法
    filterResetMethod({options}) {
      options.forEach(option => {
        option.data = {type: 'has', name: ''}
      })
    }, // 筛选数据方法
    filterMethod({option, row, column}) {
      const cellValue = row[column.field]
      const {name} = option.data
      if (cellValue) {
        return cellValue.indexOf(name) > -1
      }
      return false
    }
  })
}
