<template>
  <div class="syj-left">
    <div>
      <SubTitle text="水源井数据" style="padding: 10px 0; " light/>
      <AlarmTotal icon="warn" title="水质分析仪总量" :total="this.wellInfoData.data1"/>
      <div class="well-container">
        <div>
          <span>{{ this.wellInfoData.data2 || 0 }}</span>
          <span>正常运行</span>
        </div>
        <div>
          <span>{{ this.wellInfoData.data3 || 0 }}</span>
          <span>调试</span>
        </div>
        <div>
          <span>{{ this.wellInfoData.data4 || 0 }}</span>
          <span>故障</span>
        </div>
      </div>
      <div class="half-container">
        <AlarmTotal icon="warn" title="报警分类占比"/>
        <div id="halfChart" style="height: 200px; margin-top: 24px;"></div>
      </div>
    </div>
    <div>
      <SubTitle text="水源井监控" style="padding: 10px 0; " light/>
      <template v-for="(item, index) in 5">
        <img v-show="currImg - 1 == index" class="monitor-img" :key="index" :src="getImg(`img${index||''}.png`)"
             style="right: 20px;" alt="">
      </template>
      <span class="monitor-span">报警水源井Id:{{ 555 }}</span>
      <el-pagination
        :current-page.sync="currImg"
        class="monitor-pagination"
        layout="prev, pager, next"
        :total="50"
        :background="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import AlarmTotal from '@/components/survey/AlarmTotal.vue'
import TagCloud from '@/components/survey/TagCloud.vue'
import SubTitle from '@/components/survey/SubTitle.vue'
import {getWellInfoData, getWellAlarmStatics} from '@/api/survey/monitor/syjPage.js'

export default {
  components: {AlarmTotal, TagCloud, SubTitle},
  data() {
    return {
      //水源井数据相关信息
      wellInfoData: {},
      wellHalfAlarmData: [],
      textNum: null,
      currImg: 1
    }
  },
  mounted() {
  },
  watch: {
    wellHalfAlarmData(e) {
      this.$nextTick(() => {
        this.wellHalfAlarmData = e
        this.drawHalfChart('halfChart')
      })
    }
  },
  methods: {
    getImg(fileName) {
      return require(`@/assets/survey/image/page-image/syj/${fileName}`);
    },
    /*获取数值信息*/
    getWellInfoData() {
      getWellInfoData().then(res => {
        this.wellInfoData = res.data
      })
    },
    getWellAlarmData() {
      getWellAlarmStatics().then(res => {
        this.wellHalfAlarmData = res.data
        this.textNum = res.data.reduce((arr, cur) => {
          return arr + Number(cur.value)
        }, 0)
        if (this.textNum !== 0) {
          this.wellHalfAlarmData.push(
            {
              value: this.textNum,
              itemStyle: {
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          )
        } else if (this.textNum == 0) {
          this.wellHalfAlarmData.push(
            {
              value: 180,
              itemStyle: {
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          )
        }
      })
    },
    /*绘制报警量半圆图*/
    drawHalfChart(id) {
      let dom = echarts.init(document.getElementById(id))
      dom.setOption({
        title: {
          text: this.textNum,
          subtext: '报警总量',
          left: 'center',
          top: '40%',
          textStyle: {
            fontSize: 30,
            color: '#ffcc3f',
            fontFamily: "YouSheBiaoTiHei"
          },
          subtextStyle: {
            fontSize: 20,
            color: "#fff"
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['90%', '120%'],
            center: ['49%', '70%'],
            startAngle: 180,
            width: 417,
            data: this.wellHalfAlarmData,
            label: {
              overflow: 'break',
              width: 100,
              show: true,
              color: "#fff",
              fontSize: 14,
            },
            labelLine: {
              lineStyle: {
                color: "#7A7D7D",
                type: 'dashed'
              },
            },
          }
        ]
      })
    }
  },
  created() {
    this.getWellInfoData()
    this.getWellAlarmData()
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.syj-left {
  z-index: 11;
  backdrop-filter: blur(5px);
  background-color: #000c35a6;
  width: 460px;
  margin-top: 75px !important;
  position: fixed;

  > div {
    &:first-child {
      padding: 0 25px 28px;
      @include side-bar-top-border-small;
      @include side-bar-bottom-border-small;
    }

    &:nth-child(2) {
      margin-top: 14px;
      padding: 21px 0;
      @include side-bar-top-border-small;
      @include side-bar-bottom-border-small;
    }
  }
}

.well-container {
  display: flex;
  color: #FFFFFF;
  font-size: 15px;
  justify-content: space-between;
  padding: 15px 50px 10px 50px;

  > div {
    display: flex;
    flex-direction: column;
    text-align: center;

    :first-child {
      color: #3FC2FF;
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      margin-bottom: 5px;
    }
  }

}

.half-container {
  height: 300px;
  margin-bottom: 20px;
}

.monitor-img {
  height: 250px;
  padding: 13px;
}

.monitor-span {
  color: #FFFFFF;
  padding: 13px;
}

.monitor-pagination {
  padding: 11px 93px 1px;
}
</style>
