export const gwData = [
  {
    location: ["116.205791,40.090437", "116.209746,40.091754"],
  },
  {
    location: [
      "116.203991,40.09347",
      "116.211152,40.079242",
      "116.21217,40.074206",
      "116.213136,40.066907",
      "116.219669,40.047424",
      "116.221833,40.047037",
      "116.221301,40.044116",
    ],
  },
  {
    location: [
      "116.19302,40.074571",
      "116.192982,40.049585",
      "116.19217,40.048181",
      "116.190033,40.045696",
      "116.189732,40.042685",
      "116.18704,40.040843",
      "116.186627,40.040197",
      "116.184221,40.039831",
      "116.181858,40.040079",
      "116.179511,40.040064",
      "116.176176,40.039715",
    ],
  },
  {
    location: [
      "116.193592,40.090789",
      "116.222236,40.098205",
      "116.225019,40.098673",
      "116.238846,40.099027",
      "116.24005,40.099282",
      "116.261285,40.106426",
    ],
  },
  {
    location: [
      "116.23306,40.085261",
      "116.25666,40.091103",
      "116.267829,40.09476",
    ],
  },
  {
    location: [
      "116.260715,40.08271",
      "116.266886,40.070574",
      "116.276265,40.07308",
    ],
  },
  {
    location: [
      "116.265247,40.09889",
      "116.272318,40.087579",
      "116.273599,40.087579",
      "116.280639,40.075187",
    ],
  },
  {
    location: ["116.161539,40.104157", "116.161638,40.108381"],
  },
  {
    location: [
      "116.161578,40.104129",
      "116.167184,40.104345",
      "116.168199,40.104309",
      "116.169002,40.104418",
      "116.170148,40.104759",
      "116.183288,40.110786",
      "116.204962,40.119275",
      "116.206188,40.119544",
      "116.207121,40.119544",
      "116.219376,40.119387",
    ],
  },
  {
    location: [
      "116.197259,40.132002",
      "116.20149,40.13189",
      "116.218791,40.128648",
      "116.231123,40.127123",
      "116.236124,40.126597",
      "116.241262,40.126439",
      "116.246811,40.12707",
    ],
  },
  {
    location: ["116.246725,40.127054", "116.249656,40.117022"],
  },
  {
    location: [
      "116.116927,40.089839",
      "116.142008,40.087292",
      "116.150102,40.087276",
      "116.164321,40.089005",
      "116.191307,40.090193",
    ],
  },
  {
    location: [
      "116.222456,40.098235",
      "116.223471,40.087945",
      "116.22298,40.085882",
      "116.225671,40.073873",
      "116.22659,40.059419",
      "116.228125,40.052369",
    ],
  },
  {
    location: ["116.196272,40.07365", "116.212251,40.073587"],
  },
  {
    location: [
      "116.197199,40.073532",
      "116.197974,40.066887",
      "116.195464,40.066779",
      "116.195836,40.062931",
    ],
  },
  {
    location: [
      "116.125835,40.073182",
      "116.144628,40.074341",
      "116.14761,40.074534",
      "116.167804,40.074907",
      "116.197089,40.07441",
      "116.212504,40.074079",
      "116.224272,40.073858",
      "116.225583,40.073913",
      "116.237728,40.075487",
      "116.265899,40.084292",
      "116.266761,40.084706",
      "116.271957,40.088156",
    ],
  },
  {
    location: [
      "116.184001,40.133151",
      "116.186612,40.132406",
      "116.218376,40.128876",
    ],
  },
  {
    location: [
      "116.148416,40.119497",
      "116.14935,40.110889",
      "116.147697,40.105592",
      "116.160848,40.104102",
      "116.172059,40.058613",
      "116.179174,40.052593",
      "116.190385,40.049555",
      "116.194768,40.047125",
      "116.20332,40.046076",
    ],
  },
  {
    location: ["116.141732,40.113262", "116.14935,40.110889"],
  },
  {
    location: ["116.232084,40.086895", "116.240133,40.070195"],
  },
  {
    location: [
      "116.238168,40.086525",
      "116.243187,40.076875",
      "116.24387,40.076047",
      "116.245199,40.075578",
      "116.246924,40.075633",
      "116.25102,40.076958",
    ],
  },
  {
    location: [
      "116.245739,40.0884",
      "116.250374,40.079285",
      "116.256422,40.067682",
      "116.25783,40.06333",
      "116.259374,40.061483",
      "116.263747,40.056947",
      "116.26465,40.053328",
      "116.265132,40.051088",
      "116.276326,40.042032",
    ],
  },
  {
    location: ["116.252575,40.090092", "116.260183,40.075274"],
  },
  {
    location: [
      "116.184687,40.074763",
      "116.184358,40.071197",
      "116.174183,40.070795",
    ],
  },
  {
    location: [
      "116.169137,40.070468",
      "116.17419,40.070843",
      "116.173946,40.067707",
      "116.170021,40.067835",
    ],
  },
  {
    location: [
      "116.170188,40.066213",
      "116.173799,40.066195",
      "116.17421,40.063842",
      "116.193018,40.063026",
    ],
  },
  {
    location: [
      "116.18587,40.070631",
      "116.189312,40.072668",
      "116.189961,40.072121",
      "116.186682,40.070109",
      "116.187364,40.069438",
      "116.187948,40.068668",
      "116.188208,40.068071",
      "116.188305,40.067748",
    ],
  },
  {
    location: [
      "116.193021,40.066905",
      "116.188328,40.067042",
      "116.188091,40.065928",
      "116.187586,40.064974",
      "116.186873,40.064064",
    ],
  },
  {
    location: [
      "116.186292,40.063257",
      "116.184706,40.061073",
      "116.184136,40.06028",
      "116.184093,40.059519",
    ],
  },
  {
    location: [
      "116.184657,40.074725",
      "116.184744,40.077204",
      "116.184525,40.078199",
      "116.18402,40.079194",
      "116.183208,40.080189",
      "116.182681,40.080695",
    ],
  },
  {
    location: ["116.184665,40.075765", "116.178673,40.07598"],
  },
  {
    location: [
      "116.184535,40.078864",
      "116.18909,40.078904",
      "116.189176,40.075014",
    ],
  },
  {
    location: ["116.185224,40.075802", "116.189144,40.075757"],
  },
  {
    location: ["116.185705,40.081478", "116.186411,40.078918"],
  },
  {
    location: [
      "116.193055,40.081213",
      "116.192969,40.080104",
      "116.192883,40.078902",
      "116.193004,40.075813",
      "116.192969,40.07465",
    ],
  },
  {
    location: [
      "116.18909,40.078904",
      "116.192859,40.078924",
      "116.194836,40.078924",
      "116.195495,40.079031",
    ],
  },
  {
    location: ["116.189378,40.075742", "116.192993,40.075786"],
  },
  {
    location: [
      "116.19554,40.079031",
      "116.196742,40.079423",
      "116.19763,40.080257",
      "116.198091,40.081154",
    ],
  },
  {
    location: [
      "116.193873,40.081179",
      "116.194297,40.081116",
      "116.198049,40.08117",
    ],
  },
  {
    location: ["116.19548,40.07903", "116.195461,40.075987"],
  },
  {
    location: ["116.195492,40.077662", "116.200614,40.077952"],
  },
  {
    location: ["116.195516,40.076023", "116.200858,40.076023"],
  },
  {
    location: ["116.199165,40.076032", "116.199165,40.081143"],
  },
  {
    location: ["116.200269,40.081135", "116.200822,40.076061"],
  },
  {
    location: [
      "116.198049,40.08117",
      "116.199165,40.081143",
      "116.200269,40.081135",
      "116.201653,40.081192",
      "116.20991,40.0818",
    ],
  },
  {
    location: [
      "116.204646,40.081426",
      "116.2047,40.074967",
      "116.204704,40.074218",
    ],
  },
  {
    location: ["116.208419,40.081675", "116.208293,40.075174"],
  },
  {
    location: [
      "116.19853,40.067018",
      "116.212464,40.066825",
      "116.213136,40.066907",
    ],
  },
  {
    location: [
      "116.213625,40.065447",
      "116.202661,40.062911",
      "116.201475,40.062814",
      "116.199158,40.062925",
      "116.194496,40.062933",
    ],
  },
  {
    location: [
      "116.21634,40.057132",
      "116.206451,40.054668",
      "116.207837,40.051662",
      "116.209032,40.050659",
    ],
  },
  {
    location: [
      "116.210061,40.055579",
      "116.211049,40.053024",
      "116.211373,40.051934",
      "116.211355,40.050235",
    ],
  },
  {
    location: ["116.207636,40.052155", "116.208534,40.052362"],
  },
  {
    location: ["116.209809,40.052721", "116.211013,40.052997"],
  },
  {
    location: [
      "116.204704,40.074218",
      "116.204733,40.068934",
      "116.204907,40.067663",
      "116.205255,40.066503",
      "116.209493,40.056665",
    ],
  },
  {
    location: [
      "116.193756,40.059749",
      "116.203181,40.05961",
      "116.204343,40.059669",
      "116.205556,40.059848",
      "116.214723,40.06215",
    ],
  },
  {
    location: [
      "116.1734,40.057505",
      "116.173816,40.058531",
      "116.17393,40.059272",
      "116.172279,40.059345",
    ],
  },
  {
    location: [
      "116.173705,40.057452",
      "116.174261,40.05836",
      "116.181065,40.056449",
      "116.182121,40.056007",
      "116.183048,40.055304",
    ],
  },
  {
    location: [
      "116.179584,40.052847",
      "116.181845,40.054022",
      "116.183086,40.055333",
      "116.186189,40.054499",
      "116.187807,40.054499",
    ],
  },
  {
    location: ["116.187741,40.054503", "116.187872,40.050674"],
  },
  {
    location: [
      "116.183106,40.053621",
      "116.185663,40.052955",
      "116.186204,40.052904",
      "116.18777,40.052885",
    ],
  },
  {
    location: ["116.18422,40.053299", "116.183086,40.052401"],
  },
  {
    location: [
      "116.187906,40.051347",
      "116.193085,40.051124",
      "116.196204,40.051001",
      "116.198975,40.050288",
    ],
  },
  {
    location: [
      "116.191385,40.047467",
      "116.186087,40.047918",
      "116.179993,40.048116",
      "116.176805,40.046603",
      "116.173494,40.043388",
    ],
  },
  {
    location: [
      "116.182517,40.047998",
      "116.182551,40.046712",
      "116.182107,40.045479",
      "116.181697,40.044416",
      "116.181612,40.042736",
    ],
  },
  {
    location: [
      "116.265569,40.099057",
      "116.260003,40.097129",
      "116.257935,40.09639",
      "116.25458,40.095221",
    ],
  },
  {
    location: [
      "116.254177,40.095053",
      "116.250384,40.094043",
      "116.243636,40.091864",
    ],
  },
  {
    location: ["116.250333,40.102689", "116.25534,40.092946"],
  },
  {
    location: [
      "116.240363,40.099355",
      "116.242027,40.096381",
      "116.241018,40.096067",
      "116.244596,40.089246",
    ],
  },
  {
    location: ["116.243563,40.09191", "116.240267,40.090675"],
  },
  {
    location: [
      "116.23882,40.090305",
      "116.236757,40.089481",
      "116.236141,40.088246",
      "116.235122,40.087835",
      "116.232175,40.08695",
    ],
  },
  {
    location: [
      "116.233488,40.098783",
      "116.230272,40.092836",
      "116.229951,40.091889",
      "116.229977,40.091045",
      "116.230219,40.090366",
      "116.231907,40.087217",
    ],
  },
  {
    location: ["116.239105,40.084742", "116.235045,40.083528"],
  },
  {
    location: ["116.241966,40.087441", "116.243363,40.084511"],
  },
  {
    location: ["116.242706,40.085713", "116.241085,40.085241"],
  },
  {
    location: [
      "116.245297,40.08831",
      "116.248645,40.081513",
      "116.241999,40.079266",
    ],
  },
  {
    location: ["116.239729,40.078601", "116.236617,40.077666"],
  },
  {
    location: ["116.247322,40.085112", "116.240208,40.082919"],
  },
  {
    location: ["116.238032,40.082218", "116.23499,40.081265"],
  },
  {
    location: ["116.243648,40.083944", "116.245005,40.081211"],
  },
  {
    location: ["116.244561,40.082164", "116.242829,40.081571"],
  },
  {
    location: ["116.259969,40.092196", "116.266249,40.087789"],
  },
  {
    location: [
      "116.259795,40.084903",
      "116.263141,40.086",
      "116.263986,40.086774",
      "116.265881,40.087523",
      "116.266249,40.087789",
    ],
  },
  {
    location: [
      "116.258528,40.088329",
      "116.260748,40.089036",
      "116.26278,40.090224",
    ],
  },
  {
    location: ["116.259546,40.08634", "116.264565,40.088968"],
  },
  {
    location: ["116.262709,40.093053", "116.265232,40.091074"],
  },
  {
    location: ["116.250005,40.089398", "116.251495,40.086405"],
  },
  {
    location: [
      "116.246252,40.088474",
      "116.24969,40.081696",
      "116.250922,40.082312",
      "116.252813,40.083457",
    ],
  },
  {
    location: ["116.247942,40.085261", "116.250206,40.085899"],
  },
  {
    location: ["116.251094,40.086207", "116.257797,40.088188"],
  },
  {
    location: [
      "116.253214,40.083523",
      "116.254875,40.083721",
      "116.255907,40.083765",
      "116.259459,40.084821",
    ],
  },
  {
    location: [
      "116.239064,40.074424",
      "116.242117,40.07532",
      "116.2429,40.075369",
      "116.24338,40.07521",
      "116.244211,40.074903",
      "116.245218,40.074559",
      "116.245857,40.07451",
      "116.247184,40.074534",
      "116.251692,40.075934",
    ],
  },
  {
    location: ["116.26176,40.080487", "116.25557,40.078434"],
  },
  {
    location: ["116.26356,40.076098", "116.257483,40.074675"],
  },
  {
    location: [
      "116.257483,40.074675",
      "116.259029,40.071311",
      "116.256982,40.070709",
    ],
  },
  {
    location: ["116.260118,40.075345", "116.261905,40.071947"],
  },
  {
    location: ["116.260206,40.071495", "116.264018,40.072516"],
  },
  {
    location: [
      "116.250572,40.07891",
      "116.251169,40.079216",
      "116.253467,40.075543",
    ],
  },
  {
    location: [
      "116.259367,40.061235",
      "116.248918,40.059681",
      "116.249111,40.057276",
    ],
  },
  {
    location: [
      "116.261726,40.058904",
      "116.260859,40.05846",
      "116.257441,40.058386",
      "116.255226,40.057461",
      "116.255611,40.054205",
    ],
  },
  {
    location: [
      "116.254648,40.06031",
      "116.254985,40.058201",
      "116.25407,40.05772",
      "116.254215,40.057165",
      "116.254744,40.053872",
    ],
  },
  {
    location: [
      "116.254118,40.057424",
      "116.249592,40.056795",
      "116.249785,40.05476",
    ],
  },
  {
    location: ["116.250652,40.053206", "116.254744,40.053872"],
  },
  {
    location: [
      "116.262191,40.058903",
      "116.275074,40.059752",
      "116.275534,40.058243",
    ],
  },
  {
    location: ["116.270374,40.05946", "116.270513,40.057324"],
  },
  {
    location: ["116.267623,40.059261", "116.267845,40.057324"],
  },
  {
    location: [
      "116.263982,40.056256",
      "116.275704,40.057363",
      "116.276002,40.055975",
    ],
  },
  {
    location: ["116.26805,40.056628", "116.268178,40.055223"],
  },
  {
    location: ["116.270538,40.056841", "116.270665,40.055615"],
  },
  {
    location: ["116.255421,40.056112", "116.257392,40.056063"],
  },
  {
    location: ["116.258346,40.056145", "116.263792,40.056243"],
  },
  {
    location: ["116.258982,40.058425", "116.25913,40.057041"],
  },
  {
    location: [
      "116.259215,40.056145",
      "116.25788,40.053132",
      "116.256078,40.0538",
    ],
  },
  {
    location: ["116.258579,40.052725", "116.262839,40.05134"],
  },
  {
    location: [
      "116.260847,40.052057",
      "116.264428,40.0538",
      "116.268177,40.054104",
      "116.299347,40.059428",
    ],
  },
  {
    location: [
      "116.299347,40.059381",
      "116.300257,40.056",
      "116.300348,40.054927",
      "116.300075,40.053994",
      "116.299377,40.053225",
      "116.298436,40.052665",
      "116.296616,40.052245",
    ],
  },
  {
    location: [
      "116.276189,40.055272",
      "116.278423,40.044942",
      "116.27444,40.044444",
    ],
  },
  {
    location: [
      "116.278715,40.044893",
      "116.281111,40.045166",
      "116.284868,40.045938",
      "116.293418,40.046834",
      "116.302616,40.048153",
      "116.307927,40.050642",
      "116.305563,40.054898",
      "116.304041,40.060299",
    ],
  },
  {
    location: ["116.291151,40.057885", "116.293289,40.048377"],
  },
  {
    location: [
      "116.30281,40.048377",
      "116.302292,40.049697",
      "116.295523,40.048626",
    ],
  },
  {
    location: ["116.29154,40.056218", "116.286779,40.055496"],
  },
  {
    location: ["116.280852,40.048303", "116.292932,40.04997"],
  },
  {
    location: ["116.286326,40.056989", "116.288172,40.049373"],
  },
  {
    location: [
      "116.276897,40.073381",
      "116.285344,40.0761",
      "116.286765,40.073236",
    ],
  },
  {
    location: ["116.281667,40.074873", "116.282732,40.072866"],
  },
  {
    location: ["116.281642,40.071249", "116.285446,40.072457"],
  },
  {
    location: [
      "116.279892,40.074288",
      "116.283838,40.066886",
      "116.284807,40.066038",
      "116.286212,40.065473",
      "116.287449,40.064985",
      "116.288552,40.064368",
      "116.289221,40.063366",
      "116.290993,40.058202",
    ],
  },
  {
    location: [
      "116.27639,40.073143",
      "116.276728,40.073296",
      "116.27705,40.068748",
      "116.280914,40.069891",
    ],
  },
  {
    location: [
      "116.276856,40.071493",
      "116.277694,40.071505",
      "116.279963,40.072212",
    ],
  },
  {
    location: ["116.278967,40.071835", "116.27958,40.070256"],
  },
  {
    location: [
      "116.199682,40.092263",
      "116.202245,40.089076",
      "116.202076,40.0869",
      "116.201334,40.086253",
      "116.198502,40.085735",
    ],
  },
  {
    location: ["116.202144,40.088843", "116.198367,40.088688"],
  },
  {
    location: [
      "116.195635,40.09133",
      "116.197186,40.088662",
      "116.197186,40.086771",
    ],
  },
  {
    location: [
      "116.19351,40.086771",
      "116.195972,40.086926",
      "116.197153,40.086615",
      "116.198198,40.085761",
    ],
  },
  {
    location: ["116.197119,40.088662", "116.193477,40.088558"],
  },
  {
    location: [
      "116.190557,40.09137",
      "116.191855,40.089704",
      "116.192785,40.087978",
      "116.193191,40.086624",
      "116.190402,40.086011",
    ],
  },
  {
    location: ["116.192384,40.088354", "116.188206,40.087944"],
  },
  {
    location: [
      "116.183498,40.08734",
      "116.184964,40.084418",
      "116.181607,40.083156",
    ],
  },
  {
    location: [
      "116.185342,40.084607",
      "116.187987,40.085512",
      "116.187652,40.087923",
      "116.185647,40.087744",
    ],
  },
];

export var points = [
  [
    "115.70572843202065,39.700374699814375",
    "115.80418259670908,39.70411554481044",
    "115.85361570932965,39.7026700355259",
    "115.87841969969149,39.702094192984354",
    "115.91417609996331,39.701425613759845",
    "115.93689864387545,39.701328795029454",
    "115.97713426419303,39.70063200122544",
    "116.00621870887602,39.704402330390195",
  ],
  [
    "115.66705693398077,39.74709057469673",
    "115.792984907983,39.74606257317378",
    "115.87385784065916,39.74952747594173",
  ],
  [
    "115.96654167118388,39.772848360874654",
    "115.9715732356623,39.7428176042661",
    "115.9791144261575,39.67422508610009",
    "115.98026125803767,39.657102910257215",
  ],
  [
    "115.88740529041044,39.752780041786394",
    "115.8986728970584,39.755611743905696",
    "115.91177476525372,39.75945458051386",
    "116.00034339425403,39.775025968562076",
    "116.05117864285182,39.78715705671475",
  ],
  [
    "115.91718290699852,39.75046992388701",
    "115.9291127186678,39.724138016053736",
    "115.9411390333217,39.675320787166065",
    "115.94894069944701,39.65008675508187",
    "115.95940034925931,39.62110142206489",
  ],
  [
    "115.74330640758187,39.79557062455042",
    "115.78258111516662,39.79095772570469",
    "115.81568539822537,39.654907323604064",
    "115.87687176119603,39.6240915248416",
  ],
  [
    "115.8787965128919,39.722637497656024",
    "115.87951677640574,39.67954789386965",
    "115.8791429710915,39.66814482152235",
    "115.87888023909764,39.63213607770845",
    "115.86925314386248,39.60678145578733",
  ],
];
