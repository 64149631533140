<template>
  <div class="flex container">
    <div v-if="icon" :class="`icon title-icon-${icon}`"></div>
    <span class="ti">{{ title }}</span>
    <span class="to flex-grow" :class="color">{{ total }}</span>
    <template v-if="untreated != null">
      <span>未处理</span>
      <div class="un">
        <span>{{ untreated }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'alarm-total',
  props: ['icon', 'title', 'total', 'untreated', 'color'],
  data() {
    return {
      style: {
        textAlign: this.untreated == null ? 'center' : 'left'
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/survey/style.scss";

.ti {
  font-size: 18px;
  color: #fff;
  margin: 0 8px 0 0px;
}

.icon {
  margin-right: 12px;
}

.to {
  font-size: 36px;
  color: #ffcc3f;
  font-family: YouSheBiaoTiHei;
  position: relative;
  top: 10px;
  align-self: flex-start;

  &.blue {
    color: #3FC2FF;
  }

  &.gold {
    color: #FFAA00;
  }
}

.to + span {
  color: #9e9e9e;
  margin-right: 10px;
}

.un {
  background-color: #df6500;
  font-size: 16px;
  padding: 0 5px;
  box-sizing: border-box;
  height: 21px;
  min-width: 21px;
  text-align: center;
  line-height: 19px;
  border-radius: 10px;

  > span {
    @include ys-title;
  }
}

.container {
  height: 70px;
  position: relative;
  @include border-color2;
}
</style>
