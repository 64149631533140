<template>
  <div class="flex flex-center container" :class="{ys: !light}">
    <div class="line-wrapper">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span class="text">{{ text }}</span>
    <div class="line-wrapper">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.container {
  position: relative;
  z-index: 1;
}

.line-wrapper {
  width: 20%;

  div {
    height: 0;
    margin-bottom: 3px;
    border-bottom: 1px dashed #fff;
  }
}

.text {
  margin: 0 7%;
  color: #fff;
  font-size: 18px;
}

.container.ys {
  .text {
    font-size: 26px;
    @include ys-title;
  }

  .line-wrapper div {
    border-color: #868686;
  }
}
</style>
