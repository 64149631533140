<template>
  <div class="container">
    <div class="chart"></div>
    <div>
      <p class="count">{{ count }}</p>
      <p class="title">{{ title }}</p>
    </div>
  </div>
</template>
<script>
// 预设配色
const defaultColor = {
  green: ['#33D3C4', '#00A596'],
  blue: ['#5BA6FF', '#0083E4'],
  orange: ['#FF8B58', '#D64216'],
  yellow: ['#FFDD8E', '#FDAF10'],
  red: ['#FF8157', '#DF1E00']
};
export default {
  props: ["data", "title"],
  data() {
    return {
      count: 0
    }
  },
  watch: {
    data() {

      this.initChart();
    }
  },
  methods: {
    initChart() {
      let chartName = this.title;
      let countValue = this.data.map(d => Number(d.value)).reduce((accu, curr) => accu + curr);
      let chartData = this.data.map((item, idx) => {
        let color = [
          {offset: 0, color: defaultColor[item.color][0]},
          {offset: 1, color: defaultColor[item.color][1]},
        ];
        item.itemStyle = {
          color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, color)
        };
        return item;
      });

      this.count = countValue;


      let dom = document.querySelector(".chart");
      let myChart = this.$echarts.init(dom);
      let option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: chartName,
            type: "pie",
            radius: ["65%", "90%"],
            center: ["50%", "80%"],
            startAngle: 180,
            silent: true,
            label: {
              overflow: 'break',
              width: 100,
              show: true,
              color: "#fff",
              fontSize: 14,
              formatter(param) {
                return param.name + param.value;
              },
            },
            labelLine: {
              lineStyle: {
                color: "#7A7D7D",
                type: 'dashed'
              },
            },
            labelLayout: {
              verticalAlign: 'bottom',
              align: 'center',
              dy: -5
            },
            data: [
              ...chartData,
              {
                // make an record to fill the bottom 50%
                value: countValue,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: "none",
                  decal: {
                    symbol: "none",
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    // this.initChart();
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.container {
  position: relative;
}

.chart {
  height: 192px;

  + div {
    position: absolute;
    bottom: 25px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }
}

p {
  color: #fff;
  margin: 0;

  &.title {
    font-size: 18px;
  }

  &.count {
    line-height: 1;
    font-size: 36px;
    color: #E29805;
    font-family: YouSheBiaoTiHei;
  }
}
</style>
