export const pieOption = {
  title: {
    text: '异常处理占比',
    left: 'center',
    textStyle: {
      fontWeight: 'normal',
      fontSize: 12,
      color: '#61ffef'
    }

  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    bottom: -5,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 3,
    icon: 'rect',
    textStyle: {
      fontSize: 12,
      color: '#61ffef'
    }
  },
  series: [
    {
      name: '异常处理占比',
      type: 'pie',
      radius: '50%',
      top: -15,
      bottom: -22,
      label: {
        position: 'inside',
        formatter: '{c}',
        color: '#fff'
      },
      data: [
        { value: 5, name: '处理中', itemStyle: { color: '#c46627' } },
        { value: 16, name: '已完成', itemStyle: { color: '#ed7d31' } },
        { value: 3, name: '未处理', itemStyle: { color: '#f4b9a4' } },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

export const pieOption2 = {
  title: {
    text: '用水异常占比',
    left: 'center',
    textStyle: {
      fontWeight: 'normal',
      fontSize: 12,
      color: '#61ffef'
    }

  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    bottom: -5,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 3,
    icon: 'rect',
    textStyle: {
      fontSize: 12,
      color: '#61ffef'
    }
  },
  series: [
    {
      name: '异常处理占比',
      type: 'pie',
      radius: '50%',
      top: -15,
      bottom: -22,
      animationDelay: 500,
      label: {
        position: 'inside',
        formatter: '{c}',
        color: '#fff'
      },
      data: [
        { value: 0, name: '用水突增', itemStyle: { color: '#70ad47' } },
        { value: 0, name: '用水突减', itemStyle: { color: '#5b9bd5' } },
        { value: 3, name: '低用水量', itemStyle: { color: '#ffc000' } },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

export const pieOption3 = {
  title: {
    text: '异常短信发送统计',
    left: 'center',
    textStyle: {
      fontWeight: 'normal',
      fontSize: 12,
      color: '#61ffef'
    }

  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    bottom: -5,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 3,
    icon: 'rect',
    textStyle: {
      fontSize: 12,
      color: '#61ffef'
    }
  },
  series: [
    {
      name: '异常处理占比',
      type: 'pie',
      radius: '50%',
      top: -15,
      bottom: -22,
      animationDelay: 1000,
      label: {
        position: 'inside',
        formatter: '{c}',
        color: '#fff'
      },
      data: [
        { value: 0, name: '今日发送', itemStyle: { color: '#375da1' } },
        { value: 0, name: '本月发送', itemStyle: { color: '#4472c4' } },
        { value: 0, name: '本年发送', itemStyle: { color: '#a7b5db' } },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};