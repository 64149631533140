<template>
  <div class="gwzt-left">
    <div>
      <SubTitle text="管网设备" style="padding: 10px 0; " light/>
      <AlarmTotal icon="warn" title="流量计接入总量" :total="this.pipeInfoData.data1"/>
      <div class="pipe-info-container">
        <AlarmTotal title="压力变送器" :total="this.pipeInfoData.data2"/>
        <AlarmTotal title="远传测控终端" :total="this.pipeInfoData.data3"/>
      </div>
      <div class="alarm-half-container">
        <div id="halfChart" style="height: 170px; margin-top: 24px;"></div>
      </div>
      <div class="fault-gauge-container">
        <div class="fault-flex-top">
          <div>
            <span>故障</span>
            <span>{{ this.pipeFaultOffTotal.faultNum }}</span>
          </div>
          <div>
            <span>故障率</span>
            <span>{{ this.pipeInfoData.data4 || 0 }}</span>
          </div>
        </div>
        <div class="fault-flex-container">
          <div class="fault-flex-left">
            <span>传感器：{{ this.pipeFaultData.sensor || 0 }}</span>
            <span>转换器：{{ this.pipeFaultData.transverter || 0 }}</span>
            <span>远传测控终端：{{ this.pipeFaultData.RTU || 0 }}</span>
          </div>
          <div style="width: 40%;margin-top: -68px">
            <div id="gaugeChart" style="height: 170px;"></div>
          </div>
          <div class="fault-flex-right">
            <span>阀门：{{ this.pipeFaultData.valve || 0 }}</span>
            <span>压力计：{{ this.pipeFaultData.pressureGage || 0 }}</span>
            <span>泵站：{{ this.pipeFaultData.pumpStation || 0 }}</span>
          </div>
        </div>
      </div>
      <div class="off-top" style="margin-bottom: 15px">
        <div>
          <span>离线</span>
          <span>{{ this.pipeFaultOffTotal.offNum }}</span>
        </div>
        <div>
          <span>离线率</span>
          <span>{{ this.pipeInfoData.data5 || 0 }}</span>
        </div>
      </div>
      <div class="off-container">
        <span>流量计：{{ this.pipeOffData.flowmeter || 0 }}</span>
        <span>压力计：{{ this.pipeOffData.pressureGage || 0 }}</span>
      </div>
      <div class="status-container">
        <div class="circle-orange">
          <div class="orange">
            <span>{{ this.statusData.untreated || 0 }}</span>
          </div>
          <div>
            <span>未处理</span>
          </div>
        </div>
        <div class="circle-green">
          <div class="green">
            <span>{{ this.statusData.processing || 10 }}</span>
          </div>
          <div>
            <span>正在处理</span>
          </div>
        </div>
        <div class="circle-blur">
          <div class="blur">
            <span>{{ this.statusData.completed || 8 }}</span>
          </div>
          <div>
            <span>已完成</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <SubTitle text="管网数据" style="padding: 10px 0; " light/>
      <AlarmTotal icon="warn" title="管网长度（>DN100）" :total="this.pipeInfoData.data6"/>
      <TagCloudPipe style="margin-top: 22px;"/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import AlarmTotal from '@/components/survey/AlarmTotal.vue'
import TagCloudPipe from '@/components/survey/TagCloudPipe.vue'
import TableData from '@/components/survey/TableData.vue'
import SubTitle from '@/components/survey/SubTitle.vue'
import {
  getPipeInfoData,
  getAlarmStatics,
  getPipeFault,
  getPipeOff,
  getPipeAlarmTotal,
  getAlarmHandleStatics
} from '@/api/survey/monitor/gwztPage'

export default {
  components: {AlarmTotal, TagCloudPipe, TableData, SubTitle},
  data() {
    return {
      //数值信息
      pipeInfoData: {},
      //半圆图data数据
      alarmHalfChartData: [],
      textNum: null,
      //故障数据
      pipeFaultData: {},
      //离线数据
      pipeOffData: {},
      //故障总计、离线总计对象
      pipeFaultOffTotal: {},
      statusData: {}
    }
  },
  created() {
    this.getPipeInfoData()
    this.getAlarmData()
    this.getPipeFault()
    this.getPipeOff()
    this.getPipeAlarmTotal()
    this.getAlarmHandleStatics()
  },
  watch: {
    alarmHalfChartData(e) {
      this.$nextTick(() => {
        this.alarmHalfChartData = e
        this.drawHalfChart('halfChart')
      })
    },
    'pipeFaultOffTotal.faultNum': {
      handler(e) {
        this.$nextTick(() => {
          this.pipeFaultOffTotal.faultNum = e
          this.drawGaugeChart('gaugeChart')
        })
      }
    }
  },
  methods: {
    /*获取数值信息*/
    getPipeInfoData() {
      getPipeInfoData().then(res => {
        this.pipeInfoData = res.data
      })
    },
    /*获取报警量半圆图数据*/
    getAlarmData() {
      getAlarmStatics().then(res => {
        this.alarmHalfChartData = res.data
        this.textNum = res.data.reduce((arr, cur) => {
          return arr + Number(cur.value)
        }, 0)
        this.alarmHalfChartData.push(
          {
            value: this.textNum,
            itemStyle: {
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }
        )
      })
    },
    /*绘制报警量半圆图*/
    drawHalfChart(id) {
      let dom = echarts.init(document.getElementById(id))
      dom.setOption({
        title: {
          text: this.textNum,
          subtext: '报警总量',
          left: 'center',
          top: '35%',
          textStyle: {
            fontSize: 30,
            color: '#ffcc3f',
            fontFamily: "YouSheBiaoTiHei"
          },
          subtextStyle: {
            fontSize: 20,
            color: "#fff"
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['90%', '120%'],
            center: ['49%', '70%'],
            startAngle: 180,
            width: 417,
            data: this.alarmHalfChartData,
            label: {
              overflow: 'break',
              width: 100,
              show: true,
              color: "#fff",
              fontSize: 14,
            },
            labelLine: {
              lineStyle: {
                color: "#7A7D7D",
                type: 'dashed'
              },
            },
          }
        ]
      })
    },
    /*获取故障数据*/
    getPipeFault() {
      getPipeFault().then(res => {
        let arr = res.data
        for (let item of arr) {
          if (item.name == "传感器") {
            this.pipeFaultData.sensor = item.value
          } else if (item.name == "转换器") {
            this.pipeFaultData.transverter = item.value
          } else if (item.name == "远传测控终端") {
            this.pipeFaultData.RTU = item.value
          } else if (item.name == "阀门") {
            this.pipeFaultData.valve = item.value
          } else if (item.name == "压力计") {
            this.pipeFaultData.pressureGage = item.value
          } else if (item.name == "泵站") {
            this.pipeFaultData.pumpStation = item.value
          }
        }
      })
    },
    /*绘制故障量仪表盘*/
    drawGaugeChart(id) {
      let dom = echarts.init(document.getElementById(id))
      dom.setOption({
        series: [
          {
            type: 'gauge',
            width: 500,
            progress: {
              show: true,
              width: 10,
              roundCap: true
            },
            axisLine: {
              show: true,
              roundCap: true,
              lineStyle: {
                width: 10,
                color: [
                  [1, "#204781"]
                ]
              }
            },
            pointer: {
              show: true,
              itemStyle: {
                width: 1,
                color: '#C0C0C0'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 2,
              itemStyle: {
                borderWidth: 8
              }
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: this.pipeFaultOffTotal.faultNum,
                itemStyle: {
                  color: '#da2e30',
                }
              }
            ]
          }
        ]
      })
    },
    /*获取离线数据*/
    getPipeOff() {
      getPipeOff().then(res => {
        let arr = res.data
        for (let item of arr) {
          if (item.name == "流量计") {
            this.pipeOffData.flowmeter = item.value
          } else if (item.name == "压力计") {
            this.pipeOffData.pressureGage = item.value
          }
        }
      })
    },
    /*状态处理数据*/
    getAlarmHandleStatics() {
      getAlarmHandleStatics().then(res => {
        let arr = res.data
        for (let item of arr) {
          if (item.name == "未处理") {
            this.statusData.untreated = item.value
          } else if (item.name == "正在处理") {
            this.statusData.processing = item.value
          } else if (item.name == "已处理") {
            this.statusData.completed = item.value
          }
        }
      })
    },
    /*查询故障总计，离线总计*/
    getPipeAlarmTotal() {
      getPipeAlarmTotal().then(res => {
        this.pipeFaultOffTotal = res.data
      })
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/survey/style.scss";

.gwzt-left {
  z-index: 11;
  backdrop-filter: blur(5px);
  background-color: #000c35a6;
  width: 450px;
  margin-top: 75px !important;
  position: fixed;

  > div {
    &:first-child {
      padding: 0 25px 28px;
      @include side-bar-top-border-small;
      @include side-bar-bottom-border-small;
    }

    &:nth-child(2) {
      margin-top: 14px;
      padding: 21px 25px;
      @include side-bar-top-border-small;
      @include side-bar-bottom-border-small;
    }
  }
}

.pipe-info-container {
  display: flex;

  :first-child {
    margin-right: 15px;
    padding-left: 3px;
    font-size: 10px;
  }
}

.alarm-half-container {
  height: 140px;
  margin-bottom: 20px;
  @include border-color2;
}

.fault-gauge-container {
  height: 170px;
  margin-bottom: 20px;
  @include border-color2;

  .fault-flex-top {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 22px;

    > div :last-child {
      @include metal-font-color;
      font-size: 35px;
      font-family: YouSheBiaoTiHei;
      color: #ffcc3f;
      margin-left: 8px;
    }
  }

  .fault-flex-container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .fault-flex-left {
      color: #ffffff;
      font-size: 15px;
      display: flex;
      flex-flow: column;
      width: 30%;

      span {
        margin-bottom: 10px;
      }
    }

    .fault-flex-right {
      color: #ffffff;
      font-size: 15px;
      display: flex;
      flex-flow: column;
      text-align: right;
      width: 30%;

      span {
        margin-bottom: 10px;
      }
    }
  }
}

.off-top {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 22px;

  > div :last-child {
    @include metal-font-color;
    font-size: 35px;
    font-family: YouSheBiaoTiHei;
    color: #ffcc3f;
    margin-left: 8px;
  }
}

.off-container {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.status-container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  color: #FFFFFF;

  .circle-orange {
    display: flex;

    .orange {
      background-image: url(~@/assets/survey/image/page-image/gwzt/circle-orange.svg);
      width: 32px;
      height: 32px;
      margin: -4px 5px 4px;

      span {
        padding-left: 8px;
        font-family: YouSheBiaoTiHei;
        color: #DF6500;
        font-size: 20px;
      }
    }
  }

  .circle-green {
    display: flex;

    .green {
      background-image: url(~@/assets/survey/image/page-image/gwzt/circle-green.svg);
      width: 32px;
      height: 32px;
      margin: -4px 5px 4px;

      span {
        padding-left: 7px;
        font-family: YouSheBiaoTiHei;
        color: #4AADA0;
        font-size: 20px;
      }
    }
  }

  .circle-blur {
    display: flex;

    .blur {
      background-image: url(~@/assets/survey/image/page-image/gwzt/circle-blur.svg);
      width: 32px;
      height: 32px;
      margin: -4px 5px 4px;

      span {
        padding-left: 7px;
        font-family: YouSheBiaoTiHei;
        color: #0073DF;
        font-size: 20px;
      }
    }
  }
}
</style>
