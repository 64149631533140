<template>
<div>
  <table>
    <tr class="not-hover">
      <th v-for="(th, thIdx) in columns" :key="thIdx"><div>{{th}}</div></th>
    </tr>
  </table>
  <div class="table-scroll">
    <transition-group name="list" tag="table">
      <tr v-for="(tr, trIdx) in data" :key="tr[1]">
        <td v-for="(td, tdIdx) in tr" :key="tdIdx"><div>{{td}}</div></td>
      </tr>
    </transition-group>
  </div>
</div>
</template>

<script>
export default {
  props: ["columns", "data"],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/survey/djlr/common.scss';
@import '~@/assets/survey/djlr/color.scss';
.table-scroll {
  @include scroll-style;
  height: 192px;
  overflow-x: hidden;
  overflow-y: auto;
}
table {
  table-layout: fixed;
  user-select: none;
  cursor: default;
  width: 100%;
  font-size: $fs14;
  text-align: center;
  border-collapse: collapse;
  th {
    color: $font-color-th-blue;
    background-color: line-color-green(0.5);
  }
  td {
    white-space: nowrap;
    color: $font-color-blue;
  }
  td, th {
    padding: 0;
    &:first-child div {
      border-left: 1px solid rgba(0, 0, 0, 0);
    }
    &:last-child div {
      border-right: 1px solid rgba(0, 0, 0, 0);
    }
    // 表地址
    &:nth-child(2) {
      width: 120px;
    }
    div {
      line-height: 1.5;
      padding: 7px 0;
      box-sizing: border-box;
      border-top: 1px solid rgba(0, 0, 0, 0);
      border-bottom: 1px solid rgba(0, 0, 0, 0);
    }
  }

  tr:not(.not-hover):hover {
    td, th {
      &:first-child div {
        border-left: 1px solid line-color-green(0.5);
      }
      &:last-child div {
        border-right: 1px solid line-color-green(0.5);
      }
    }
    div {
      border-top: 1px solid line-color-green(0.5);
      border-bottom: 1px solid line-color-green(0.5);
    }
  }
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
